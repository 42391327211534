<template>
  <v-container class="my-10 pa-0">
    <SignaturePanel
      v-if="hasPdpBeenUpdated && !showNotifySupervisor"
      :owner="formPdp?.owner"
      :users="formPdp?.pdp_user_associations"
      :section="planSection"
      @signed="handleSignedForm"
      class="pa-0 mt-10"
      :disableSign="!isSectionValid"
    />
    <v-card class="pa-10 mt-9">
      <v-form
        ref="planEditorForm"
        v-model="isFormValid"
        lazy-validation
      >
        <NTSGoals
          :section="planSection"
          :editable="true"
          :type="GOAL_TYPE.work"
          :userIsPrincipal="loggedInUser.isPrincipal"
          :readOnly="readOnly"
          validation
          @goalsAltered="resetForm"
        />
        <div class="mt-12">
          <h2 class="mb-3">Career Goals (optional)</h2>
          <NTSGoals
            :section="planSection"
            :editable="true"
            :type="GOAL_TYPE.career"
            validation
            :userIsPrincipal="loggedInUser.isPrincipal"
            :readOnly="readOnly"
            planEditor
            @goalsAltered="resetForm"
          />
        </div>
      </v-form>
    </v-card>
    <v-card class="mt-8 pa-10">
      <p class="body-1 ads-grey-01">
        Confirm you are ready to make your PDP available to:
      </p>
      <div
        v-for="pdpUser in pdpUsers"
        :key="pdpUser.id"
        class="d-flex mb-7"
      >
        <InitialsIcon
          :class="readOnly ? 'mr-4' : 'mr-md-4'"
          :givenName="pdpUser.displayedName"
          size="42px"
        ></InitialsIcon>
        <div class="ads-grey-01">
          <div>
            <strong>{{ pdpUser.displayedName }}</strong>
          </div>
          <div>{{ pdpUser.pdpRole }}</div>
        </div>
      </div>
      <p class="mb-8 body-1 ads-grey-01">
        You will still be able to make changes to your plan after it has been
        submitted. Once your PDP has been signed off by both you and your PDP
        supervisor, you can proceed to the
        <strong>Implement</strong>
        phase.
      </p>
      <AdsButton
        buttonText="Notify supervisor"
        icon="mdi-arrow-right"
        type="submit"
        @click="handleNotifySupervisor"
      />
    </v-card>
  </v-container>
</template>

<script>
import NTSGoals from '@/components/NTSGoals.vue';
import { mapGetters, mapActions } from 'vuex';
import { InitialsIcon, AdsButton } from '@nswdoe/doe-ui-core';
import SignaturePanel from '@/components/SignaturePanel.vue';
import { equals } from 'ramda';
import { getDisplayUsers } from '@/utils';
import { GOAL_TYPE, SECTION_TYPE, USER_TYPE } from '@/data/constants';
import { setNTSNotifySupervisor } from '@/lib/pdp';
import {
  isNtsGoalsValid,
  isLearningThemeAssociationsValid,
} from '@/lib/dataValidation';

export default {
  name: 'NTSPlanEditor',
  components: {
    SignaturePanel,
    NTSGoals,
    AdsButton,
    InitialsIcon,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GOAL_TYPE,
      isFormValid: true,
      isValidatedOnStartup: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp']),
    ...mapGetters('formValidation', ['allGoalsValid', 'validationGoals']),
    ...mapGetters('pdp', { planSection: 'formPdpPlanSection' }),
    pdpUsers() {
      return getDisplayUsers(this.pdp.pdp_user_associations);
    },
    showNotifySupervisor() {
      return !this.planSection?.notifySupervisor;
    },
    hasPdpBeenUpdated() {
      return equals(this.pdp, this.formPdp);
    },
    workGoals() {
      return this.planSection.goals.filter(
        (item) => item.type === GOAL_TYPE.work
      );
    },
    isSectionValid() {
      return (
        isNtsGoalsValid(this.planSection) &&
        isLearningThemeAssociationsValid(
          this.planSection?.section_learning_theme_associations
        )
      );
    },
  },
  watch: {
    isFormValid: {
      handler() {
        if (this.$refs.planEditorForm && !this.isValidatedOnStartup) {
          this.validateForm();
          this.isValidatedOnStartup = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pdp', ['savePdp']),
    async handleNotifySupervisor() {
      if (!this.readOnly) {
        if (this.planSection) {
          this.formPdp.sections = setNTSNotifySupervisor(
            SECTION_TYPE.plan,
            this.formPdp
          );
          await this.savePdp(this.formPdp);
          this.validateForm();
        }
      }
      this.$vuetify.goTo(0);
    },
    async handleSignedForm(sectionToBeUpdated, userType) {
      if (sectionToBeUpdated && userType === USER_TYPE.owner) {
        this.$emit('planSigned', sectionToBeUpdated);
      }
    },
    validateForm() {
      this.$refs.planEditorForm.validate();
    },
    async resetForm() {
      if (this.planSection) {
        this.formPdp.sections = setNTSNotifySupervisor(
          SECTION_TYPE.plan,
          this.formPdp,
          false
        );
        await this.savePdp(this.formPdp);
        this.validateForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 959px) {
  :deep .v-expansion-panel {
    &-header.panel {
      padding-left: 20px;
      padding-right: 20px;
    }

    .v-divider {
      max-width: none;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}
</style>
