var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showBanner)?_c('v-container',{staticClass:"mt-0 px-4 px-md-0"},[_c('Alert',{attrs:{"id":"orientation","border":_vm.isMobile ? 'top' : 'left',"icon":_vm.getAlertMessage.icon ? _vm.getAlertMessage.icon : 'mdi-check-circle',"type":_vm.getAlertMessage.isSigningBanner ? 'info' : 'success',"inPage":"","alertClass":_vm.getAlertMessage.isSigningBanner
        ? 'info-background-banner'
        : 'success-background-banner'},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.bannerContentWidth}},[_c('v-row',{staticClass:"flex-column flex-md-row",class:_vm.getAlertMessage.isActionBanner ? 'pb-4' : '',attrs:{"align":"end"}},[_c('v-col',{staticClass:"pt-3"},[(_vm.getAlertMessage.phase)?_c('p',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.getAlertMessage.phase)+" ")]):_vm._e(),_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.getAlertMessage.heading)+" ")]),_vm._l((_vm.getAlertMessage.text),function(para){return [_c('p',{key:para,staticClass:"title-2 mb-0 negative-margin-left",domProps:{"innerHTML":_vm._s(para)}})]}),_vm._l((_vm.getAlertMessage.links),function(link,index){return [_c('p',{key:link.text},[(_vm.getAlertMessage.linksNumbered)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.getLook(index))+" ")]):_vm._e(),_c('a',{attrs:{"href":link.href}},[_vm._v(_vm._s(link.text))])],1)]})],2),(_vm.getAlertMessage.isActionBanner)?_c('v-col',{staticClass:"d-flex flex-grow-0 justify-end d-md-block text-md-center",attrs:{"md":_vm.getAlertMessage.hasCommentBox && '4'}},[_c('AdsButton',{staticClass:"success-button",attrs:{"icon":"mdi-thumb-up-outline","buttonText":_vm.getAlertMessage.buttonText
                      ? _vm.getAlertMessage.buttonText
                      : 'Sign'},on:{"click":_vm.startSigning}})],1):_vm._e()],1),(_vm.getAlertMessage.isSigningBanner)?[_c('v-container',{staticClass:"px-0 px-md-3"},[_c('v-row',{staticClass:"flex-column flex-md-row flex-grow-0 align-md-end justify-md-space-between pb-4 negative-margin-left"},[_c('v-col',{staticClass:"d-flex flex-row flex-md-shrink-0 flex-md-grow-0 flex-md-column justify-space-between align-center align-md-start px-0 pr-3 pr-md-0 pt-6 pt-md-3",attrs:{"md":_vm.getAlertMessage.hasCommentBox && '3'}},[(!_vm.isSupervisor)?_c('div',[_c('p',{staticClass:"ma-0 mb-2"},[_vm._v(" Supervisee's name "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.userName))])])]):(_vm.isSupervisor)?_c('div',[_c('p',{staticClass:"ma-0"},[_c('strong',[_vm._v(_vm._s(_vm.supervisorName))])]),_c('p',{staticClass:"my-0 mb-sm-4"},[_vm._v("PDP Supervisor's name")])]):_vm._e(),_c('div',[_c('v-btn',{staticClass:"confirm-box d-flex column align-content-start text-left",attrs:{"outlined":"","background":"white"},on:{"click":_vm.confirm}},[_c('v-checkbox',{attrs:{"input-value":_vm.tickedConfirm,"data-test":"supervisee-checkbox"}}),_vm._v(" I confirm ")],1)],1)]),(_vm.getAlertMessage.hasCommentBox)?_c('v-col',{staticClass:"flex-row justify-center px-0 pr-3 px-md-3",attrs:{"md":_vm.getAlertMessage.hasCommentBox && '6'}},[_c('RichTextField',{staticClass:"mt-2",staticStyle:{"background-color":"#ffffff"},attrs:{"background-color":'#FFFFFF',"label":"Optional comments","lazy":"","placeholder":_vm.readOnly ? null : 'Type here',"disabled":_vm.readOnly},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1):_vm._e(),_c('v-col',{staticClass:"flex-md-grow-0 justify-end text-right pb-3",attrs:{"md":_vm.getAlertMessage.hasCommentBox && '3'}},[_c('AdsButton',{attrs:{"icon":_vm.getAlertIcon,"disabled":!_vm.tickedConfirm,"buttonText":_vm.getAlertMessage.buttonText
                          ? _vm.getAlertMessage.buttonText
                          : 'Submit',"data-test":"sign-pdp"},on:{"click":function($event){return _vm.$emit('signed', _vm.section, _vm.comment)}}})],1)],1)],1)]:_vm._e()],2)],1)],1)]},proxy:true}],null,false,344734305)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }