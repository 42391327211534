<template>
  <v-container class="px-0 pa-md-7">
    <v-row class="ma-0 px-5 px-md-0">
      <v-col
        class="px-0"
        md="11"
      >
        <v-container class="pa-0">
          <v-row class="mx-0">
            <v-col class="pa-0 mb-8">
              <p>
                Towards the end of the annual cycle, you will participate in a
                discussion with your PDP supervisor to review progress towards
                achieving your goals. This will include an agreed, written
                assessment and should inform the development of your next PDP.
              </p>
              <p>
                You should finalise your evidence and complete at least two
                observations of classroom/leadership practice before organising
                a meeting with your PDP supervisor.
              </p>
            </v-col>
          </v-row>
          <v-row
            class="mx-0 no-break-inside"
            align="start"
          >
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="ml-0 mr-2"
            >
              mdi-clipboard-outline
            </v-icon>
            <v-col
              v-if="isIncompleteArchivedPdp && !formPdp?.annualText"
              class="pa-0 no-break-inside"
            >
              <IncompleteFieldCard />
            </v-col>
            <v-col
              class="pa-0"
              v-else-if="!finalSection?.complete"
            >
              <RichTextField
                v-model="formPdp.annualText"
                label="Written assessment"
                :rules="[rules.stringFieldRequired]"
                lazy
                :disabled="readOnly"
              />
            </v-col>
            <v-col
              v-else
              class="pa-0 ml-2 mb-8"
            >
              <div
                class="pa-0"
                v-dompurify-html="formPdp.annualText ? formPdp.annualText : ''"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col
        v-if="finalSection.supervisorSignature"
        class="px-0 px-md-0 no-break-inside"
        md="11"
      >
        <p class="title px-5 px-md-0 mt-5">
          <v-icon
            class="mr-2"
            :color="ADS_Colors.Blue_2"
          >
            mdi-message-reply-text-outline
          </v-icon>
          Comments
        </p>
        <v-row class="mx-0">
          <v-col
            class="px-0 px-md-0 no-break-inside"
            cols="12"
          >
            <v-card
              class="elevation-2 mt-2 no-break-inside"
              v-if="finalSection.supervisorSignature"
            >
              <p
                class="px-5 pt-5 px-md-4 text-body-1"
                v-dompurify-html="
                  finalSection.supervisorComment
                    ? finalSection.supervisorComment
                    : 'No comments from PDP supervisor'
                "
              />
              <v-card-title
                class="px-5 px-md-4 comments text-md-h6"
                v-if="finalSection.supervisorSignTime"
              >
                PDP Supervisor
                {{ formatName(finalSupervisor) }}
                signed this section on
                {{ formatDateTime(finalSection.supervisorSignTime) }}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col
            class="px-0 px-md-0 no-break-inside"
            cols="12"
          >
            <v-card
              class="elevation-2 mt-2 no-break-inside"
              v-if="finalSection.userSignature"
            >
              <p
                class="px-5 pt-5 px-md-4 text-body-1"
                v-dompurify-html="
                  finalSection?.userComment
                    ? finalSection.userComment
                    : 'No comments from supervisee'
                "
              />
              <v-card-title
                class="px-5 px-md-4 comments text-md-h6"
                v-if="finalSection.userSignTime"
              >
                {{ formatName(loggedInUser) }} signed and archived this section
                on
                {{ formatDateTime(finalSection.userSignTime) }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        data-test="save-annual-review"
        class="pl-5 pl-md-0"
        cols="12"
        v-if="
          !finalSection?.complete &&
          !(isPrint && isIncompleteArchivedPdp && !formPdp?.annualText)
        "
      >
        <AdsButton
          v-if="!readOnly"
          secondary
          buttonText="Save"
          :disabled="!isAnnualTextValid"
          @click="save"
          data-test="save-button"
        />
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col
        class="px-0"
        md="11"
      >
        <v-card
          class="pa-5"
          v-if="showNotifyBanner"
        >
          <MarkComplete
            :section="finalSection"
            :showBanner="showNotifyBanner"
            :showEditGoalsInfo="false"
            data-test="mark-complete"
            @onNotifyUser="handleNotifyUser"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import validators from '@/lib/validators';
import { formatDateTime, formatName, getFinalSection } from '@/utils.ts';
import { AdsButton } from '@nswdoe/doe-ui-core';
import MarkComplete from './MarkComplete';
import { mapGetters } from 'vuex';
import { clone } from 'ramda';
import RichTextField from '@/components/RichTextField';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import { findSectionByType } from '@/lib/pdp';
import { SECTION_TYPE, USER_TYPE } from '@/data/constants';

export default {
  name: 'TSAnnualReview',
  components: {
    RichTextField,
    AdsButton,
    MarkComplete,
    IncompleteFieldCard,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      ADS_Colors,
      savedMessage: false,
      annualText: null,
      rules: {
        stringFieldRequired: validators.stringFieldRequired,
      },
    };
  },
  async mounted() {
    const { annualText } = this.$store.state.pdp.pdp;
    if (annualText) {
      this.annualText = annualText;
    }
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp', 'isPdpArchived']),
    ...mapGetters('pdp', {
      formPdpUser: 'formPdpUser',
    }),
    midYearSection() {
      return findSectionByType(this.formPdp, SECTION_TYPE.midYear);
    },
    finalSection() {
      return findSectionByType(this.formPdp, SECTION_TYPE.final);
    },
    finalSupervisor() {
      return this.formPdpUser(SECTION_TYPE.final, USER_TYPE.supervisor);
    },
    isAnnualTextValid() {
      return validators.stringFieldRequired(this.formPdp.midYearText) === true;
    },
    isAnnualTextSavedValid() {
      return validators.stringFieldRequired(this.pdp.annualText) === true;
    },
    showNotifyBanner() {
      return (
        this.midYearSection.complete &&
        !this.finalSection.notifySupervisor &&
        this.isAnnualTextSavedValid &&
        !this.finalSection.complete
      );
    },
    isIncompleteArchivedPdp() {
      const finalSection = getFinalSection(this.formPdp?.sections);
      return this.isPdpArchived && !finalSection?.complete;
    },
  },
  methods: {
    formatName,
    formatDateTime,
    async handleNotifyUser() {
      const pdp = clone(this.formPdp);
      pdp.sections = pdp.sections.map((s) => {
        if (s.sectionType === 'FINAL') {
          s.notifySupervisor = true;
          s.notifySupervisorTime = Date.now();
        }
        return s;
      });
      await this.$store.dispatch('pdp/savePdp', pdp);
      this.$vuetify.goTo(0);
    },
    async save() {
      const pdp = clone(this.formPdp);
      pdp.sections = pdp.sections.map((s) => {
        if (s.sectionType === 'FINAL') {
          s.notifySupervisor = false;
          s.supervisorSignTime = null;
          s.supervisorSignature = null;
        }
        return s;
      });
      this.$store.dispatch('pdp/savePdp', pdp);
    },
  },
  inject: {
    isPrint: {
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 959px) {
  .comments {
    font-size: 16px;
    word-break: break-word;
  }
}
.no-break-inside {
  break-inside: avoid;
}
</style>
