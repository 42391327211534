export const maxStandards = 10;
const standards = [
  {
    title: 'Know student and how they learn',
    index: 1,
    focus: [
      {
        title:
          'Physical, social and intellectual development and characteristics of students',
        index: 1,
        level: [
          {
            title:
              'Demonstrate knowledge and understanding of physical, social and intellectual development and characteristics of students and how these may affect learning.',
            index: 1,
          },
          {
            title:
              "Use teaching strategies based on knowledge of students' physical, social and intellectual development and characteristics to improve student learning.",
            index: 2,
          },
          {
            title:
              'Select from a flexible and effective repertoire of teaching strategies to suit the physical, social and intellectual development and characteristics of students.',
            index: 3,
          },
          {
            title:
              'Lead colleagues to select and develop teaching strategies to improve student learning using knowledge of the physical, social and intellectual development and characteristics of students.',
            index: 4,
          },
        ],
      },
      {
        title: 'Understand how students learn',
        index: 2,
        level: [
          {
            title:
              'Demonstrate knowledge and understanding of research into how students learn and the implications for teaching',
            index: 1,
          },
          {
            title:
              'Structure teaching programs using research and collegial advice about how students learn.',
            index: 2,
          },
          {
            title:
              'Expand understanding of how students learn using research and workplace knowledge.',
            index: 3,
          },
          {
            title:
              'Lead processes to evaluate the effectiveness of teaching programs using research and workplace knowledge about how students learn.',
            index: 4,
          },
        ],
      },
      {
        title:
          'Students with diverse linguistic, cultural, religious and socioeconomic backgrounds',
        index: 3,
        level: [
          {
            title:
              'Demonstrate knowledge of teaching strategies that are responsive to the learning strengths and needs of students from diverse linguistic, cultural, religious and socio-economic backgrounds.',
            index: 1,
          },
          {
            title:
              'Design and implement teaching strategies that are responsive to the learning strengths and needs of students from diverse linguistic, cultural, religious and socio-economic backgrounds.',
            index: 2,
          },
          {
            title:
              'Support colleagues to develop effective teaching strategies that address the learning strengths and needs of students from diverse linguistic, cultural, religious and socioeconomic backgrounds.',
            index: 3,
          },
          {
            title:
              'Evaluate and revise school learning and teaching programs, using expert and community knowledge and experience, to meet the needs of students with diverse linguistic, cultural, religious and socioeconomic backgrounds.',
            index: 4,
          },
        ],
      },
      {
        title:
          'Strategies for teaching Aboriginal and Torres Strait Islander students',
        index: 4,
        level: [
          {
            title:
              'Demonstrate broad knowledge and understanding of the impact of culture, cultural identity and linguistic background on the education of students from Aboriginal and Torres Strait Islander backgrounds',
            index: 1,
          },
          {
            title:
              'Design and implement effective teaching strategies that are responsive to the local community and cultural setting, linguistic background and histories of Aboriginal and Torres Strait Islander students.',
            index: 2,
          },
          {
            title:
              'Provide advice and support colleagues in the implementation of effective teaching strategies for Aboriginal and Torres Strait Islander students using knowledge of and support from community representatives.',
            index: 3,
          },
          {
            title:
              'Develop teaching programs that support equitable and ongoing participation of Aboriginal and Torres Strait Islander students by engaging in collaborative relationships with community representatives and parents/carers.',
            index: 4,
          },
        ],
      },
      {
        title:
          'Differentiate teaching to meet the specific learning needs of students across the full range of abilities',
        index: 5,
        level: [
          {
            title:
              'Demonstrate knowledge and understanding of strategies for differentiating teaching to meet the specific learning needs of students across the full range of abilities.',
            index: 1,
          },
          {
            title:
              'Develop teaching activities that incorporate differentiated strategies to meet the specific learning needs of students across the full range of abilities.',
            index: 2,
          },
          {
            title:
              'Evaluate learning and teaching programs, using student assessment data, that are differentiated for the specific learning needs of students across the full range of abilities.',
            index: 3,
          },
          {
            title:
              'Lead colleagues to evaluate the effectiveness of learning and teaching programs differentiated for the specific learning needs of students across the full range of abilities.',
            index: 4,
          },
        ],
      },
      {
        title:
          'Strategies to support full participation of students with disability',
        index: 6,
        level: [
          {
            title:
              'Demonstrate broad knowledge and understanding of legislative requirements and teaching strategies that support participation and learning of students with disability',
            index: 1,
          },
          {
            title:
              'Design and implement teaching activities that support the participation and learning of students with disability and address relevant policy and legislative requirements.',
            index: 2,
          },
          {
            title:
              'Work with colleagues to access specialist knowledge, and relevant policy and legislation, to develop teaching programs that support the participation and learning of students with disability',
            index: 3,
          },
          {
            title:
              'Initiate and lead the review of school policies to support the engagement and full participation of students with disability and ensure compliance with legislative and/or system policies.',
            index: 4,
          },
        ],
      },
    ],
  },
  {
    title: 'Know the content and how to teach it',
    index: 2,
    focus: [
      {
        title: 'Content and teaching strategies of the teaching area',
        index: 1,
        level: [
          {
            title:
              'Demonstrate knowledge and understanding of the concepts, substance and structure of the content and teaching strategies of the teaching area.',
            index: 1,
          },
          {
            title:
              'Apply knowledge of the content and teaching strategies of the teaching area to develop engaging teaching activities.',
            index: 2,
          },
          {
            title:
              'Support colleagues using current and comprehensive knowledge of content and teaching strategies to develop and implement engaging learning and teaching programs.',
            index: 3,
          },
          {
            title:
              'Lead initiatives within the school to evaluate and improve knowledge of content and teaching strategies, and demonstrate exemplary teaching of subjects using effective, research-based learning and teaching programs',
            index: 4,
          },
        ],
      },
      {
        title: 'Content selection and organisation',
        index: 2,
        level: [
          {
            title:
              'Organise content into an effective learning and teaching sequence.',
            index: 1,
          },
          {
            title:
              'Organise content into coherent, well-sequenced learning and teaching programs.',
            index: 2,
          },
          {
            title:
              'Exhibit innovative practice in the selection and organisation of content, and delivery of learning and teaching programs.',
            index: 3,
          },
          {
            title:
              'Lead initiatives that utilise comprehensive content knowledge to improve the selection and sequencing of content into coherently organised learning and teaching programs.',
            index: 4,
          },
        ],
      },
      {
        title: 'Curriculum, assessment and reporting',
        index: 3,
        level: [
          {
            title:
              'Use curriculum, assessment and reporting knowledge to design learning sequences and lesson plans.',
            index: 1,
          },
          {
            title:
              'Design and implement learning and teaching programs using knowledge of curriculum, assessment and reporting requirements.',
            index: 2,
          },
          {
            title:
              'Support colleagues to plan and implement learning and teaching programs using contemporary knowledge and understanding of curriculum, assessment and reporting requirements.',
            index: 3,
          },
          {
            title:
              'Lead colleagues to develop learning and teaching programs using comprehensive knowledge of curriculum, assessment and reporting requirements',
            index: 4,
          },
        ],
      },
      {
        title:
          'Understand and respect Aboriginal and Torres Strait Islander people to promote reconciliation between Indigenous and non-Indigenous Australians',
        index: 4,
        level: [
          {
            title:
              'Demonstrate broad knowledge of, understanding of and respect for Aboriginal and Torres Strait Islander histories, cultures and languages.',
            index: 1,
          },
          {
            title:
              'Provide opportunities for students to develop understanding of and respect for Aboriginal and Torres Strait Islander histories, cultures and languages.',
            index: 2,
          },
          {
            title:
              'Support colleagues with providing opportunities for students to develop understanding of and respect for Aboriginal and Torres Strait Islander histories, cultures and languages',
            index: 3,
          },
          {
            title:
              'Lead initiatives to assist colleagues with opportunities for students to develop understanding of and respect for Aboriginal and Torres Strait Islander histories, cultures and languages.',
            index: 4,
          },
        ],
      },
      {
        title: 'Literacy and numeracy strategies',
        index: 5,
        level: [
          {
            title:
              'Know and understand literacy and numeracy teaching strategies and their application in teaching areas.',
            index: 1,
          },
          {
            title:
              "Apply knowledge and understanding of effective teaching strategies to support students' literacy and numeracy achievement.",
            index: 2,
          },
          {
            title:
              "Support colleagues to implement effective teaching strategies to improve students' literacy and numeracy achievement.",
            index: 3,
          },
          {
            title:
              "Monitor and evaluate the implementation of teaching strategies within the school to improve students' achievement in literacy and numeracy using researchbased knowledge and student data.",
            index: 4,
          },
        ],
      },
      {
        title: 'Information and communication technology (ICT)',
        index: 6,
        level: [
          {
            title:
              'Implement teaching strategies for using ICT to expand curriculum learning opportunities for students',
            index: 1,
          },
          {
            title:
              'Use effective teaching strategies to integrate ICT into learning and teaching programs to make selected content relevant and meaningful.',
            index: 2,
          },
          {
            title:
              'Model high-level teaching knowledge and skills, and work with colleagues to use current ICT to improve their teaching practice and make content relevant and meaningful.',
            index: 3,
          },
          {
            title:
              'Lead and support colleagues within the school to select and use ICT with effective teaching strategies to expand learning opportunities and content knowledge for all students.',
            index: 4,
          },
        ],
      },
    ],
  },
  {
    title: 'Plan for and implement effective teaching and learning',
    index: 3,
    focus: [
      {
        title: 'Establish challenging learning goals',
        index: 1,
        level: [
          {
            title:
              'Set learning goals that provide achievable challenges for students of varying abilities and characteristics.',
            index: 1,
          },
          {
            title:
              'Set explicit, challenging and achievable learning goals for all students.',
            index: 2,
          },
          {
            title:
              'Develop a culture of high expectations for all students by modelling and setting challenging learning goals.',
            index: 3,
          },
          {
            title:
              'Demonstrate exemplary practice and high expectations, and lead colleagues to encourage students to pursue challenging goals in all aspects of their education.',
            index: 4,
          },
        ],
      },
      {
        title: 'Plan, structure and sequence learning programs',
        index: 2,
        level: [
          {
            title:
              'Plan lesson sequences using knowledge of student learning, content and effective teaching strategies.',
            index: 1,
          },
          {
            title:
              'Plan and implement well-structured learning and teaching programs or lesson sequences that engage students and promote learning.',
            index: 2,
          },
          {
            title:
              'Work with colleagues to plan, evaluate and modify learning and teaching programs to create productive learning environments that engage all students',
            index: 3,
          },
          {
            title:
              "Exhibit exemplary practice and lead colleagues to plan, implement and review the effectiveness of their learning and teaching programs to develop students' knowledge, understanding and skills",
            index: 4,
          },
        ],
      },
      {
        title: 'Use teaching strategies',
        index: 3,
        level: [
          {
            title: 'Include a range of teaching strategies in teaching.',
            index: 1,
          },
          {
            title:
              'Select and use relevant teaching strategies to develop knowledge, skills, problem-solving, and critical and creative thinking.',
            index: 2,
          },
          {
            title:
              'Support colleagues in selecting and applying effective teaching strategies to develop knowledge, skills, problem-solving, and critical and creative thinking.',
            index: 3,
          },
          {
            title:
              'Work with colleagues to review, modify and expand their repertoire of teaching strategies to enable students to use knowledge, skills, problem-solving, and critical and creative thinking.',
            index: 4,
          },
        ],
      },
      {
        title: 'Select and use resources',
        index: 4,
        level: [
          {
            title:
              'Demonstrate knowledge of a range of resources, including ICT, that engage students in their learning.',
            index: 1,
          },
          {
            title:
              'Select and/or create and use a range of resources, including ICT, to engage students in their learning.',
            index: 2,
          },
          {
            title:
              'Assist colleagues to create, select and use a wide range of resources, including ICT, to engage students in their learning.',
            index: 3,
          },
          {
            title:
              'Model exemplary skills and lead colleagues in selecting, creating and evaluating resources, including ICT, for application by teachers within or beyond the school.',
            index: 4,
          },
        ],
      },
      {
        title: 'Use effective classroom communication',
        index: 5,
        level: [
          {
            title:
              'Model exemplary skills and lead colleagues in selecting, creating and evaluating resources, including ICT, for application by teachers within or beyond the school.',
            index: 1,
          },
          {
            title:
              'Use effective verbal and non-verbal communication strategies to support student understanding, participation, engagement and achievement.',
            index: 2,
          },
          {
            title:
              "Assist colleagues in selecting a wide range of verbal and non-verbal communication strategies to support students' understanding, engagement and achievement.",
            index: 3,
          },
          {
            title:
              "Demonstrate and lead by example inclusive verbal and non-verbal communication using collaborative strategies and contextual knowledge to support students' understanding, engagement and achievement.",
            index: 4,
          },
        ],
      },
      {
        title: 'Evaluate and improve teaching programs',
        index: 6,
        level: [
          {
            title:
              'Demonstrate broad knowledge of strategies that can be used to evaluate teaching programs to improve student learning.',
            index: 1,
          },
          {
            title:
              'Evaluate personal teaching and learning programs using evidence, including feedback from students and student assessment data, to inform planning',
            index: 2,
          },
          {
            title:
              'Work with colleagues to review current teaching and learning programs using student feedback, student assessment data, knowledge of curriculum and workplace practices.',
            index: 3,
          },
          {
            title:
              'Conduct regular reviews of teaching and learning programs using multiple sources of evidence including student assessment data, curriculum documents, teaching practices and feedback from parents/ carers, students and colleagues.',
            index: 4,
          },
        ],
      },
      {
        title: 'Engage parents/ carers in the educative process',
        index: 7,
        level: [
          {
            title:
              'Describe a broad range of strategies for involving parents/carers in the educative process.',
            index: 1,
          },
          {
            title:
              "Plan for appropriate and contextually relevant opportunities for parents/ carers to be involved in their children's learning.",
            index: 2,
          },
          {
            title:
              "Work with colleagues to provide appropriate and contextually relevant opportunities for parents/ carers to be involved in their children's learning.",
            index: 3,
          },
          {
            title:
              'Initiate contextually relevant processes to establish programs that involve parents/carers in the education of their children and broader school priorities and activities.',
            index: 4,
          },
        ],
      },
    ],
  },
  {
    title: 'Create and maintain supportive and safe learning environments',
    index: 4,
    focus: [
      {
        title: 'Support student participation',
        index: 1,
        level: [
          {
            title:
              'Identify strategies to support inclusive student participation and engagement in classroom activities.',
            index: 1,
          },
          {
            title:
              'Establish and implement inclusive and positive interactions to engage and support all students in classroom activities',
            index: 2,
          },
          {
            title:
              'Model effective practice and support colleagues to implement inclusive strategies that engage and support all students.',
            index: 3,
          },
          {
            title:
              'Demonstrate and lead by example the development of productive and inclusive learning environments across the school by reviewing inclusive strategies and exploring new approaches to engage and support all students.',
            index: 4,
          },
        ],
      },
      {
        title: 'Manage classroom activities',
        index: 2,
        level: [
          {
            title:
              'Demonstrate the capacity to organise classroom activities and provide clear directions.',
            index: 1,
          },
          {
            title:
              'Establish and maintain orderly and workable routines to create an environment where student time is spent on learning tasks.',
            index: 2,
          },
          {
            title:
              'Model and share with colleagues a flexible repertoire of strategies for classroom management to ensure all students are engaged in purposeful activities',
            index: 3,
          },
          {
            title:
              'Initiate strategies and lead colleagues to implement effective classroom management and promote student responsibility for learning',
            index: 4,
          },
        ],
      },
      {
        title: 'Manage challenging behaviour',
        index: 3,
        level: [
          {
            title:
              'Demonstrate knowledge of practical approaches to manage challenging behaviour.',
            index: 1,
          },
          {
            title:
              'Manage challenging behaviour by establishing and negotiating clear expectations with students, and address discipline issues promptly, fairly and respectfully.',
            index: 2,
          },
          {
            title:
              'Develop and share with colleagues a flexible repertoire of behaviour management strategies using expert knowledge and workplace experience.',
            index: 3,
          },
          {
            title:
              'Lead and implement behaviour management initiatives to assist colleagues in broadening their range of strategies.',
            index: 4,
          },
        ],
      },
      {
        title: 'Maintain student safety',
        index: 4,
        level: [
          {
            title:
              "Describe strategies that support students' wellbeing and safety working within school and/or system, curriculum and legislative requirements.",
            index: 1,
          },
          {
            title:
              "Ensure students' wellbeing and safety within school by implementing school and/ or system, curriculum and legislative requirements.",
            index: 2,
          },
          {
            title:
              'Initiate and take responsibility for implementing current school and/or system, curriculum and legislative requirements to ensure student wellbeing and safety.',
            index: 3,
          },
          {
            title:
              'Evaluate the effectiveness of student wellbeing policies and safe working practices using current school and/ or system, curriculum and legislative requirements, and assist colleagues in updating their practices.',
            index: 4,
          },
        ],
      },
      {
        title: 'Use ICT safely, responsibly and ethically',
        index: 5,
        level: [
          {
            title:
              'Demonstrate an understanding of the relevant issues and the strategies available to support the safe, responsible and ethical use of ICT in learning and teaching.',
            index: 1,
          },
          {
            title:
              'Incorporate strategies to promote the safe, responsible and ethical use of ICT in learning and teaching',
            index: 2,
          },
          {
            title:
              'Model and support colleagues to develop strategies to promote the safe, responsible and ethical use of ICT in learning and teaching',
            index: 3,
          },
          {
            title:
              'Review or implement new policies and strategies to ensure the safe, responsible and ethical use of ICT in learning and teaching.',
            index: 4,
          },
        ],
      },
    ],
  },
  {
    title: 'Assess, provide feedback and report on student learning',
    index: 5,
    focus: [
      {
        title: 'Assess student learning',
        index: 1,
        level: [
          {
            title:
              'Demonstrate understanding of assessment strategies, including informal and formal, diagnostic, formative and summative approaches, to assess student learning.',
            index: 1,
          },
          {
            title:
              'Develop, select and use informal and formal, diagnostic, formative and summative assessment strategies to assess student learning.',
            index: 2,
          },
          {
            title:
              'Develop and apply a comprehensive range of assessment strategies to diagnose learning needs, comply with curriculum requirements and support colleagues to evaluate the effectiveness of their approaches to assessment.',
            index: 3,
          },
          {
            title:
              'Evaluate school assessment policies and strategies to support colleagues in using assessment data to diagnose learning needs, complying with curriculum, system and/or school assessment requirements and using a range of assessment strategies.',
            index: 4,
          },
        ],
      },
      {
        title: 'Provide feedback to students on their learning',
        index: 2,
        level: [
          {
            title:
              'Demonstrate an understanding of the purpose of providing timely and appropriate feedback to students about their learning.',
            index: 1,
          },
          {
            title:
              'Provide timely, effective and appropriate feedback to students about their achievement relative to their learning goals.',
            index: 2,
          },
          {
            title:
              "Select from an effective range of strategies to provide targeted feedback based on informed and timely judgements of each student's current needs in order to progress learning.",
            index: 3,
          },
          {
            title:
              'Model exemplary practice and initiate programs to support colleagues in applying a range of timely, effective and appropriate feedback strategies.',
            index: 4,
          },
        ],
      },
      {
        title: 'Make consistent and comparable judgements',
        index: 3,
        level: [
          {
            title:
              'Demonstrate understanding of assessment moderation and its application to support consistent and comparable judgements of student learning.',
            index: 1,
          },
          {
            title:
              'Understand and participate in assessment moderation activities to support consistent and comparable judgements of student learning.',
            index: 2,
          },
          {
            title:
              'Organise assessment moderation activities that support consistent and comparable judgements of student learning.',
            index: 3,
          },
          {
            title:
              'Lead and evaluate moderation activities that ensure consistent and comparable judgements of student learning to meet curriculum and school or system requirements.',
            index: 4,
          },
        ],
      },
      {
        title: 'Interpret student data',
        index: 4,
        level: [
          {
            title:
              'Demonstrate the capacity to interpret student assessment data to evaluate student learning and modify teaching practice.',
            index: 1,
          },
          {
            title:
              'Use student assessment data to analyse and evaluate student understanding of subject/content, identifying interventions and modifying teaching practice',
            index: 2,
          },
          {
            title:
              'Work with colleagues to use data from internal and external student assessments for evaluating learning and teaching, identifying interventions and modifying teaching practice.',
            index: 3,
          },
          {
            title:
              'Coordinate student performance and program evaluation using internal and external student assessment data to improve teaching practice.',
            index: 4,
          },
        ],
      },
      {
        title: 'Report on student achievement',
        index: 5,
        level: [
          {
            title:
              'Demonstrate understanding of a range of strategies for reporting to students and parents/carers, and the purpose of keeping accurate and reliable records of student achievement.',
            index: 1,
          },
          {
            title:
              'Report clearly, accurately and respectfully to students and parents/carers about student achievement, making use of accurate and reliable records.',
            index: 2,
          },
          {
            title:
              'Work with colleagues to construct accurate, informative and timely reports to students and parents/carers about student learning and achievement.',
            index: 3,
          },
          {
            title:
              'Evaluate and revise reporting and accountability mechanisms in the school to meet the needs of students, parents/carers and colleagues.',
            index: 4,
          },
        ],
      },
    ],
  },
  {
    title: 'Engage in professional learning',
    index: 6,
    focus: [
      {
        title: 'Identify and plan professional learning needs',
        index: 1,
        level: [
          {
            title:
              'Demonstrate an understanding of the role of the Australian Professional Standards for Teachers in identifying professional learning needs.',
            index: 1,
          },
          {
            title:
              'Use the Australian Professional Standards for Teachers and advice from colleagues to identify and plan professional learning needs.',
            index: 2,
          },
          {
            title:
              'Analyse the Australian Professional Standards for Teachers to plan personal professional development goals, support colleagues in identifying and achieving personal development goals, and pre-service teachers in improving classroom practice.',
            index: 3,
          },
          {
            title:
              'Use comprehensive knowledge of the Australian Professional Standards for Teachers to plan and lead the development of professional learning policies and programs that address the professional learning needs of colleagues and pre-service teachers.',
            index: 4,
          },
        ],
      },
      {
        title: 'Engage in professional learning and improve practice',
        index: 2,
        level: [
          {
            title:
              'Understand the relevant and appropriate sources of professional learning for teachers.',
            index: 1,
          },
          {
            title:
              'Participate in learning to update knowledge and practice targeted to professional needs and school and/or system priorities.',
            index: 2,
          },
          {
            title:
              'Plan for professional learning by accessing and critiquing relevant research, engage in high-quality targeted opportunities to improve practice, and offer quality placements for pre-service teachers where applicable.',
            index: 3,
          },
          {
            title:
              'Initiate collaborative relationships to expand professional learning opportunities, engage in research, and provide quality opportunities and placements for pre-service teachers.',
            index: 4,
          },
        ],
      },
      {
        title: 'Engage with colleagues and improve practice',
        index: 3,
        level: [
          {
            title:
              'Seek and apply constructive feedback from supervisors and teachers to improve teaching practices.',
            index: 1,
          },
          {
            title:
              'Contribute to collegial discussions and apply constructive feedback from colleagues to improve professional knowledge and practice.',
            index: 2,
          },
          {
            title:
              'Initiate and engage in professional discussions with colleagues in a range of forums to evaluate practice directed at improving professional knowledge and practice, and the educational outcomes of students.',
            index: 3,
          },
          {
            title:
              'Implement professional dialogue within the school or professional learning network(s) that is informed by feedback, analysis of current research and practice to improve the educational outcomes of students.',
            index: 4,
          },
        ],
      },
      {
        title: 'Apply professional learning and improve student learning',
        index: 4,
        level: [
          {
            title:
              'Demonstrate an understanding of the rationale for continued professional learning and the implications for improved student learning',
            index: 1,
          },
          {
            title:
              'Undertake professional learning programs designed to address identified student learning needs.',
            index: 2,
          },
          {
            title:
              'Engage with colleagues to evaluate the effectiveness of teacher professional learning activities to address student learning needs.',
            index: 3,
          },
          {
            title:
              'Advocate for, participate in and lead strategies to support high-quality professional learning opportunities for colleagues that focus on improved student learning.',
            index: 4,
          },
        ],
      },
    ],
  },
  {
    title:
      'Engage professionally with colleagues, parents/carers and the community',
    index: 7,
    focus: [
      {
        title: 'Meet professional ethics and responsibilities',
        index: 1,
        level: [
          {
            title:
              'Understand and apply the key principles described in codes of ethics and conduct for the teaching profession.',
            index: 1,
          },
          {
            title:
              'Meet codes of ethics and conduct established by regulatory authorities, systems and schools.',
            index: 2,
          },
          {
            title:
              'Maintain high ethical standards and support colleagues to interpret codes of ethics and exercise sound judgement in all schools and community contexts.',
            index: 3,
          },
          {
            title:
              'Model exemplary ethical behaviour and exercise informed judgements in all professional dealings with students, colleagues and the community.',
            index: 4,
          },
        ],
      },
      {
        title:
          'Comply with legislative, administrative and organisational requirements',
        index: 2,
        level: [
          {
            title:
              'Understand the relevant legislative, administrative and organisational policies and processes required for teachers according to school stage.',
            index: 1,
          },
          {
            title:
              'Understand the implications of and comply with relevant legislative, administrative, organisational and professional requirements, policies and processes.',
            index: 2,
          },
          {
            title:
              'Support colleagues to review and interpret legislative, administrative and organisational requirements, policies and processes.',
            index: 3,
          },
          {
            title:
              "Initiate, develop and implement relevant policies and processes to support colleagues' compliance with and understanding of existing and new legislative, administrative, organisational and professional responsibilities.",
            index: 4,
          },
        ],
      },
      {
        title: 'Engage with the parents/carers',
        index: 3,
        level: [
          {
            title:
              'Understand strategies for working effectively, sensitively and confidentially with parents/carers.',
            index: 1,
          },
          {
            title:
              "Establish and maintain respectful collaborative relationships with parents/ carers regarding their children's learning and wellbeing.",
            index: 2,
          },
          {
            title:
              "Demonstrate responsiveness in all communications with parents/carers about their children's learning and wellbeing.",
            index: 3,
          },
          {
            title:
              "Identify, initiate and build on opportunities that engage parents/carers in both the progress of their children's learning and the educational priorities of the school.",
            index: 4,
          },
        ],
      },
      {
        title:
          'Engage with professional teaching networks and broader communities',
        index: 4,
        level: [
          {
            title:
              "Understand the role of external professionals and community representatives in broadening teachers' professional knowledge and practice.",
            index: 1,
          },
          {
            title:
              'Participate in professional and community networks and forums to broaden knowledge and improve practice.',
            index: 2,
          },
          {
            title:
              'Contribute to professional networks and associations and build productive links with the wider community to improve teaching and learning.',
            index: 3,
          },
          {
            title:
              'Take a leadership role in professional and community networks and support the involvement of colleagues in external learning opportunities.',
            index: 4,
          },
        ],
      },
    ],
  },
];

export default standards;
