<template>
  <div>
    <v-row
      v-if="showBanner"
      id="complete"
      justify="space-between"
      align="center"
      class="mx-0 mx-md-n3"
    >
      <v-col
        class="px-0 px-sm-3"
        sm="10"
      >
        <h3
          v-if="!noTitle"
          class="headline mb-6"
        >
          Notify your PDP supervisor
        </h3>
        <div v-if="section?.sectionType === 'PLAN'">
          <p class="ma-0">
            Let your PDP supervisor know you're ready to discuss your PDP.
          </p>
          <p class="ma-0">
            After you meet with your PDP supervisor and discuss your goals, you
            will both sign off on the 'plan phase'.
          </p>
          <p
            v-if="showEditGoalsInfo"
            class="ma-0"
          >
            The PDP is a dynamic plan. You may adjust your goals throughout the
            performance and development cycle to suit emerging needs, in
            consultation with your PDP supervisor.
          </p>
        </div>
        <div v-if="section?.sectionType === 'MID_YEAR'">
          <p class="ma-0">
            Let your PDP supervisor know you've completed your self-assessment.
          </p>
          <p class="ma-0">
            You may also wish to arrange a discussion of your self-assessment
            with your PDP supervisor and use it as part of the annual review
            process.
          </p>
          <p class="ma-0">
            Remember the PDP is a dynamic plan. You may adjust your goals
            throughout the performance and development cycle to suit your needs,
            in consultation with your PDP supervisor.
          </p>
        </div>
        <div v-if="section?.sectionType === 'FINAL'">
          <p class="ma-0">
            Let your PDP supervisor know you're ready to discuss your annual
            review.
          </p>
          <p class="ma-0">
            This will conclude this PDP cycle, and will lead to the commencement
            of the next cycle.
          </p>
        </div>
        <AdsButton
          icon="mdi-send-outline"
          class="mt-5"
          @click="isPcModalOpen = true"
          data-test="notify-supervisor"
          buttonText="Notify PDP supervisor"
        />
      </v-col>
    </v-row>
    <MarkCompleteModal
      :section="section"
      :isModalOpen="isPcModalOpen"
      :showEditGoalsInfo="showEditGoalsInfo"
      @cancel="cancelMarkPhaseCompletePrompt"
      @success="markPhaseCompletePrompt"
    />
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import MarkCompleteModal from './MarkCompleteModal.vue';

export default {
  components: {
    AdsButton,
    MarkCompleteModal,
  },
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: null,
    },
    showEditGoalsInfo: Boolean,
    showBanner: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPcModalOpen: false,
    };
  },
  methods: {
    cancelMarkPhaseCompletePrompt() {
      this.isPcModalOpen = false;
      this.$emit('success');
    },
    markPhaseCompletePrompt() {
      this.isPcModalOpen = false;
      this.$emit('onNotifyUser');
    },
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.left-align-text {
  text-align: left;
}

//.overview {
//  //background-color: $ads-light-10;
//  padding: 1.5rem 2rem;
//
//  .overview-chip {
//    position: relative;
//    top: -0.5rem;
//  }
//
//  .overview-content {
//    width: 100%;
//    padding-top: 1rem;
//  }
//}
</style>
