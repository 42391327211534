<template>
  <v-card
    :outlined="outlined"
    :flat="flat"
    :class="`dark-border no-break-inside ${
      !flat ? 'px-5 px-md-10 py-md-5' : ''
    }`"
    v-if="show"
  >
    <v-row class="ma-0 flex-column flex-md-row">
      <v-col class="px-0 px-md-3">
        <v-card-title class="ma-0 pa-0">
          <p
            class="subtitle-1"
            v-dompurify-html:plaintext="standard?.standard"
          />
        </v-card-title>
        <v-card-subtitle class="ma-0 pa-0">
          <p
            class="subtitle-2"
            v-dompurify-html:plaintext="standard?.focus"
          />
        </v-card-subtitle>
        <v-card-text class="ma-0 pa-0">
          <p
            class="mb-0 mb-md-4 goal-text"
            v-dompurify-html:plaintext="
              `${standard?.standardIndex}.${standard?.focusIndex}.${standard?.levelIndex} - ${standard?.level}`
            "
          />
        </v-card-text>
      </v-col>
      <v-col
        v-if="canDelete"
        class="pt-0 px-0 pb-6 pt-md-3 px-md-3 pb-md-3"
        md="2"
      >
        <v-row
          justify="end"
          class="mx-0"
        >
          <AdsButton
            :ripple="false"
            buttonText="Delete"
            plain
            @click="$emit('deleteStandard')"
            class="pa-0"
            icon="mdi-delete-outline"
            color="error"
          ></AdsButton>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'TeachingStandard',
  components: {
    AdsButton,
  },
  props: {
    standard: {
      type: Object,
      default: () => ({}),
    },
    canDelete: Boolean,
    outlined: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showThis: true,
    };
  },
};
</script>

<style scoped lang="scss">
.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;
}

.delete-button {
  color: $ads-red;
}

.dark-border {
  border-color: $ads-dark-80;
}

.no-break-inside {
  break-inside: avoid;
}
</style>
