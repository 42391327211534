var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-0"},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"px-5 px-md-10 pt-5"},[_c('h1',{},[_vm._v("Professional Goals")]),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(
                  `Submitted: ${
                    _vm.formatDateTime(this.section?.notifySupervisorTime) ?? ''
                  }`
                ),expression:"\n                  `Submitted: ${\n                    formatDateTime(this.section?.notifySupervisorTime) ?? ''\n                  }`\n                ",arg:"plaintext"}]})])])],1),_vm._l((_vm.section.goals),function(goal,index){return _c('v-row',{key:`plan-read-only-goal-${goal.id}`},[_c('v-col',[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"goal-heading mb-10 px-5 px-md-10 py-5"},[_vm._v(" Goal "+_vm._s(index + 1)+": "+_vm._s(goal.title ?? '')+" ")]),_c('div',{staticClass:"pb-5 px-5 px-md-10"},[_c('div',{staticClass:"divider"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-star-outline ")]),_vm._v(" Description ")],1)]),(
                      !goal?.description?.length && _vm.isPdpIncompleteArchived
                    )?_c('IncompleteFieldCard'):_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(goal?.description ?? ''),expression:"goal?.description ?? ''"}],staticClass:"goal-text mb-10"})],1),_c('div',{staticClass:"divider"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-calendar-edit ")]),_vm._v(" Standard descriptors ")],1)]),_c('div',{class:`${goal.standards}mb-10`},[_vm._l((goal?.standards),function(standard){return _c('TeachingStandard',{key:`teachingStandard-${goal.id}${standard.id}${standard.focus}${standard.level}`,attrs:{"canDelete":false,"outlined":false,"flat":"","standard":standard}})}),(
                        !goal?.standards?.length && _vm.isPdpIncompleteArchived
                      )?_c('IncompleteFieldCard'):_vm._e()],2)]),(_vm.ownerIsPrincipal)?_c('div',{staticClass:"divider"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-comment-account-outline ")]),_vm._v(" Strategic Improvement Plan ")],1)]),(
                      (goal?.sipType === null ||
                        goal?.sipType === undefined) &&
                      _vm.isPdpIncompleteArchived
                    )?_c('IncompleteFieldCard'):_vm._e(),_c('div',[_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(
                        _vm.sipOptions[goal?.sipType]?.text
                      ),expression:"\n                        sipOptions[goal?.sipType]?.text\n                      ",arg:"plaintext"}],staticClass:"goal-text"}),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(goal?.sipOther ?? ''),expression:"goal?.sipOther ?? ''"}],staticClass:"goal-text mb-10"})])],1):_vm._e(),(_vm.ownerIsPrincipal)?_c('div',{staticClass:"divider"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-sitemap-outline ")]),_vm._v(" Key Accountabilities ")],1)]),_c('div',{class:`${goal?.accountabilities}mb-10`},[_vm._l((goal?.accountabilities),function(accountability){return _c('KeyAccountability',{key:`goalaccountability-${goal.id}.${accountability?.id}`,attrs:{"accountability":accountability,"canDelete":false,"outlined":false,"flat":""}})}),(
                        !goal?.accountabilities?.length &&
                        _vm.isPdpIncompleteArchived
                      )?_c('IncompleteFieldCard'):_vm._e()],2)]):_vm._e(),_c('div',{staticClass:"divider"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-format-list-bulleted ")]),_vm._v(" Professional learning ")],1)]),(
                      !goal?.professionalLearning?.length &&
                      _vm.isPdpIncompleteArchived
                    )?_c('IncompleteFieldCard'):_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(`${goal.professionalLearning ?? ''}`),expression:"`${goal.professionalLearning ?? ''}`"}],staticClass:"goal-text mb-10"})],1),_c('div',{staticClass:"mb-5"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-content-copy ")]),_vm._v(" Evidence ")],1)]),(!goal?.evidence?.length && _vm.isPdpIncompleteArchived)?_c('IncompleteFieldCard'):_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(`${goal.evidence ?? ''}`),expression:"`${goal.evidence ?? ''}`"}],staticClass:"goal-text"})],1)])])])],1)})],2)],1)],1),(_vm.isCurrentSectionArchival && _vm.isPdpIncompleteArchived)?_c('IncompleteArchiveCard',{attrs:{"pdp":_vm.pdp}}):_vm._e(),_c('EventLog',{staticClass:"my-10 px-0 px-md-10 py-8",attrs:{"pdp":this.pdp,"section":this.section,"owner":this.pdp?.owner,"supervisor":this.supervisor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }