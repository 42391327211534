import { get } from '../http';

const basePath = process.env.VUE_APP_IS_LAMBDA ? '/v2' : '';

const getConfig = async (): Promise<{ pdpRefreshRate: string } | undefined> => {
  const url = `${basePath}/config/`;
  const response = await get<{ pdpRefreshRate: string }>(url);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

export default {
  getConfig,
};
