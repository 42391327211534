import { formatDateTime, formatName } from '@/utils';
import { Section, User } from '../../../lib/schema';

export const getComments = (
  section: Section,
  owner: User,
  supervisor: User | undefined = undefined,
  manager: User | undefined = undefined,
  principal: User | undefined = undefined
) => {
  const comments: {
    role: string;
    name: string;
    action: string;
    time: string;
  }[] = [];
  if (section?.notifySupervisor && section?.notifySupervisorTime) {
    comments.push({
      role: 'User',
      name: formatName(owner),
      action: 'submitted',
      time: formatDateTime(section.notifySupervisorTime) ?? '',
    });
  }
  if (
    supervisor &&
    section?.supervisorSignature &&
    section?.supervisorSignTime
  ) {
    comments.push({
      role: 'PDP Supervisor',
      name: formatName(supervisor),
      action: 'signed',
      time: formatDateTime(section?.supervisorSignTime) ?? '',
    });
  }
  if (manager && section?.managerSignature && section?.managerSignTime) {
    comments.push({
      role: 'PDP Additional Supervisor',
      name: formatName(manager),
      action: 'signed',
      time: formatDateTime(section?.managerSignTime) ?? '',
    });
  }
  if (principal && section?.principalSignature && section?.principalSignTime) {
    comments.push({
      role: 'PDP Principal',
      name: formatName(principal),
      action: 'signed',
      time: formatDateTime(section?.principalSignTime) ?? '',
    });
  }
  if (section?.userSignature && section?.userSignTime) {
    comments.push({
      role: 'User',
      name: formatName(owner),
      action: 'signed',
      time: formatDateTime(section?.userSignTime) ?? '',
    });
  }
  return comments;
};
