<template>
  <v-container>
    <v-row
      v-if="editMode"
      class="mx-0"
    >
      <v-col class="ma-0 pa-0 mt-5">
        <v-form
          :ref="id"
          v-model="isFormValid"
          lazy-validation
          style="width: 100%"
          v-if="editMode"
        >
          <p class="d-flex flex-row text-md-h6">
            <strong class="title">
              <v-icon
                :color="ADS_Colors.Blue_2"
                class="mr-2"
              >
                mdi-star-outline
              </v-icon>
              <b>Goal</b>
            </strong>
          </p>
          <AdsTextField
            v-model.trim="currentGoal.title"
            :counter="false"
            :data-test="`goal-title-input-${currentGoal?.index}`"
            class="mx-md-8"
            label="Title (optional)"
          />
          <RichTextField
            v-model.trim="currentGoal.description"
            label="Description"
            placeholder="Please enter 100 characters or more"
            :rules="[
              rules.stringFieldRequired,
              rules.stringLengthRequired(TS_GOAL_DESCRIPTION_MIN_LENGTH),
            ]"
            class="mx-md-8"
            lazy
            @validated="validate"
          />
        </v-form>
      </v-col>
    </v-row>
    <v-row
      v-if="!editMode"
      class="mb-2 mx-0"
    >
      <v-col class="ma-0 pa-0 mt-5 no-break-inside">
        <p class="d-flex flex-row text-md-h6">
          <strong class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-star-outline
            </v-icon>
            <b>Description</b>
          </strong>
        </p>
        <IncompleteFieldCard
          v-if="isPrint && isIncompleteArchivedPdp && !currentGoal?.description"
          class="my-5 mx-md-8 max-width-none no-break-inside"
        />
        <Wrapper
          v-else-if="currentGoal?.description"
          class="pa-0"
          cardClass="mx-8 my-5"
          :isMobile="isMobile"
        >
          <div
            v-dompurify-html="currentGoal?.description"
            class="px-0 px-md-10 py-5 ma-0 goal-text"
          />
        </Wrapper>
      </v-col>
    </v-row>
    <v-row class="mb-2 mx-0 px-0">
      <v-col class="ma-0 pa-0">
        <v-divider class="mt-3 mx-n5 mx-md-n7 py-5 max-width-none" />
        <p class="d-flex flex-row text-md-h6 no-break-inside">
          <strong class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-calendar-edit
            </v-icon>
            <b>Standard descriptors</b>
          </strong>
        </p>
        <p
          v-if="!isSupervisor && editMode"
          class="ml-md-8 body-1 mr-md-0"
        >
          Select the Standard Descriptor/s used to inform this goal.
        </p>
        <IncompleteFieldCard
          v-if="
            isPrint &&
            isIncompleteArchivedPdp &&
            currentGoal?.standards?.length === 0
          "
          class="my-5 mx-md-8 max-width-none no-break-inside"
        />
        <div v-else>
          <TeachingStandard
            v-for="(standard, index) of currentGoal?.standards"
            :key="`teachingStandard-${currentGoal?.id}${standard?.focus}${standard?.level}`"
            :canDelete="editMode"
            :outlined="false"
            :standard="standard"
            :show="standard?.markForDelete !== true"
            class="my-5 mx-n5 mx-md-8 max-width-none"
            :class="
              !editMode
                ? 'mb-5 px-5 px-md-10 max-width-none no-break-inside'
                : ''
            "
            @deleteStandard="deleteStandard(index)"
          />
        </div>
        <CreateTeachingStandard
          v-if="editMode"
          :goal="currentGoal"
          :openCtsModal="ctsModalOpen"
          :section="section"
          @closeCtsModal="ctsModalOpen = false"
        />
        <AdsButton
          v-if="editMode"
          :data-test="`add-standard-${currentGoal?.index}`"
          class="ml-md-8 mt-4 mb-8 pl-2 body-2 text-md-body-1 font-weight-bold"
          secondary
          @click="handleAddStandard"
        >
          <v-icon class="mr-2">edit</v-icon>
          {{
            currentGoal?.standards?.length
              ? 'Modify Standard Descriptors'
              : 'Add Standard Descriptors'
          }}
        </AdsButton>
      </v-col>
    </v-row>
    <v-row
      v-if="loggedInUser?.isPrincipal && editMode"
      class="mb-2 mx-0"
    >
      <v-col class="ma-0 pa-0">
        <v-divider class="mt-3 mx-n5 mx-md-n7 py-5 max-width-none" />
        <p class="d-flex flex-row text-md-h6">
          <strong class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-comment-account-outline
            </v-icon>
            <b>Strategic Improvement Plan</b>
          </strong>
        </p>
        <p
          v-if="!isSupervisor && editMode"
          class="mx-md-8 body-1"
        >
          Link your goals to your school’s Strategic Improvement Plan
        </p>
        <v-combobox
          :items="sipOptions"
          :value="sipOptions[currentGoal?.sipType]"
          outlined
          placeholder="Select one"
          title="Select SIP"
          @input="updateSipType"
          class="mx-md-8"
        />
        <RichTextField
          v-if="currentGoal?.sipType === 1"
          v-model.trim="currentGoal.sipOther"
          label=""
          class="mx-md-8"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="ownerIsPrincipal && !editMode"
      class="mb-2 mx-0"
    >
      <v-col class="ma-0 pa-0 no-break-inside">
        <v-divider class="mt-3 mx-n5 mx-md-n7 py-5 max-width-none" />
        <p class="d-flex flex-row text-md-h6 no-break-inside">
          <strong class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-comment-account-outline
            </v-icon>
            <b>Strategic Improvement Plan</b>
          </strong>
        </p>
        <IncompleteFieldCard
          v-if="
            isPrint &&
            isIncompleteArchivedPdp &&
            !sipOptions[currentGoal?.sipType]?.text
          "
          class="my-5 mx-md-8 max-width-none no-break-inside"
        />
        <Wrapper
          v-else-if="sipOptions[currentGoal?.sipType]?.text"
          class="pa-0 no-break-inside"
          cardClass="mx-8 mb-5"
          :isMobile="isMobile"
        >
          <div class="px-0 px-md-10 py-5">
            <p
              v-dompurify-html:plaintext="
                sipOptions[currentGoal?.sipType]?.text
              "
              class="goal-text"
            />
            <p
              v-dompurify-html="currentGoal?.sipOther ?? ''"
              class="goal-text"
            />
          </div>
        </Wrapper>
      </v-col>
    </v-row>
    <v-row
      v-if="(loggedInUser?.isPrincipal && editMode) || ownerIsPrincipal"
      class="mb-2 mx-0"
    >
      <v-col class="ma-0 pa-0">
        <v-divider class="mt-3 mx-n5 mx-md-n7 py-5 max-width-none" />
        <p class="d-flex flex-row text-md-h6">
          <strong class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-sitemap-outline
            </v-icon>
            <b>Principal Role Description</b>
          </strong>
        </p>

        <p
          v-if="!isSupervisor && editMode"
          class="mx-md-8 body-1"
        >
          Select at least one Key Accountability for this goal.
        </p>
        <IncompleteFieldCard
          v-if="
            isPrint &&
            isIncompleteArchivedPdp &&
            currentGoal?.accountabilities?.length === 0
          "
          class="my-5 mx-md-8 max-width-none no-break-inside"
        />
        <div v-else>
          <KeyAccountability
            v-for="(accountability, index) of currentGoal?.accountabilities"
            :key="`goalaccountability-${currentGoal?.id}.${accountability?.areaOfPractice}${accountability?.accountability}`"
            :accountability="accountability"
            :show="accountability?.markForDelete !== true"
            :canDelete="editMode"
            :outlined="false"
            class="my-5 mx-n5 mx-md-8 max-width-none"
            :class="!editMode ? 'mb-5 px-5 px-md-10' : ''"
            @deleteAccountability="deleteAccountability(index)"
          />
        </div>
        <CreateKeyAccountability
          v-if="editMode"
          :goal="currentGoal"
          :openCkaModal="ckaModalOpen"
          :section="section"
          @closeCkaModal="ckaModalOpen = false"
        />
        <AdsButton
          v-if="editMode"
          :data-test="`add-key-accountability-${currentGoal?.index}`"
          class="ml-md-8 mt-4 mb-8 pl-2 body-2 text-md-body-1 font-weight-bold"
          secondary
          @click="handleAddAccountability"
        >
          <v-icon class="mr-2">add_circle_outline</v-icon>
          {{
            currentGoal?.keyAccountabilities?.length
              ? 'Modify Key Accountabilities'
              : 'Add Key Accountability'
          }}
        </AdsButton>
      </v-col>
    </v-row>
    <v-row class="mb-2 mx-0 px-0">
      <v-col
        v-if="editMode"
        class="ma-0 pa-0"
      >
        <v-divider class="mt-3 mx-n5 mx-md-n7 py-5 max-width-none" />
        <p class="d-flex flex-row text-md-h6">
          <strong class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-format-list-bulleted
            </v-icon>
            <b>Professional learning</b>
          </strong>
        </p>
        <p class="mx-md-8 body-1">
          Record the activities and resources needed to support the achievement
          of professional goals.
        </p>
        <RichTextField
          v-model.trim="currentGoal.professionalLearning"
          label=""
          class="mx-md-8"
        />
      </v-col>
      <v-col
        v-if="!editMode"
        class="ma-0 pa-0 no-break-inside"
      >
        <v-divider class="mt-5 mx-n5 mx-md-n7 py-5 max-width-none" />
        <p class="d-flex flex-row text-md-h6">
          <strong class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-format-list-bulleted
            </v-icon>
            <b>Professional learning</b>
          </strong>
        </p>
        <IncompleteFieldCard
          v-if="
            !currentGoal?.professionalLearning &&
            isPrint &&
            isIncompleteArchivedPdp
          "
          class="my-5 mx-md-8 max-width-none no-break-inside"
        />
        <Wrapper
          v-else-if="currentGoal?.professionalLearning"
          class="pa-0"
          cardClass="mx-8 mb-5"
          :isMobile="isMobile"
        >
          <div
            v-dompurify-html="currentGoal?.professionalLearning"
            class="goal-text px-0 px-md-10 py-5 mt-5"
          />
        </Wrapper>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mx-n5 mx-md-n7 py-5 max-width-none" />
    <v-row class="mb-0 mx-0">
      <v-col
        v-if="editMode"
        class="ma-0 pa-0"
      >
        <p class="d-flex flex-row text-md-h6">
          <strong class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-content-copy
            </v-icon>
            <b>Evidence</b>
          </strong>
        </p>
        <p class="mx-md-8 body-1">
          Record the types of evidence to be used to indicate progress towards
          professional goals.
        </p>
        <RichTextField
          v-model.trim="currentGoal.evidence"
          label=""
          class="mx-md-8"
        />
      </v-col>
      <v-col
        v-if="!editMode"
        class="px-0 no-break-inside"
      >
        <strong class="title">
          <v-icon
            :color="ADS_Colors.Blue_2"
            class="mr-2"
          >
            mdi-content-copy
          </v-icon>
          <b>Evidence</b>
        </strong>
        <IncompleteFieldCard
          v-if="isPrint && isIncompleteArchivedPdp && !currentGoal?.evidence"
          class="my-5 mx-md-8 max-width-none no-break-inside"
        />
        <Wrapper
          v-else-if="currentGoal?.evidence"
          class="pa-0"
          cardClass="mx-8 mb-5"
          :isMobile="isMobile"
        >
          <div
            v-dompurify-html="currentGoal?.evidence"
            class="goal-text px-0 px-md-10 py-5 mt-5"
          />
        </Wrapper>
      </v-col>
    </v-row>
    <v-row class="mb-2 mx-0">
      <v-col
        v-if="!isPlanPhase"
        class="ma-0 pa-0"
      >
        <FileCard
          v-for="file in goal?.evidenceFiles"
          :key="`goal${goal?.id}-evidencefile${file.id}`"
          :edit-mode="editMode"
          :file="file"
          class="mx-md-8 mb-8 no-break-inside"
          download
          @deleteFile="deleteEvidenceFile(file?.id)"
          @downloadFile="downloadEvidenceFile(file?.id, file?.fileName)"
        />
        <UploadEvidenceModal
          v-if="editMode"
          :goal="currentGoal"
          :observations="formPdp?.observations"
          :openModal="evidenceModalOpen"
          :section="section"
          @deleteFile="deleteEvidenceFile(file?.id)"
          @downloadFile="downloadEvidenceFile(file?.id, file?.fileName)"
          @onModalClose="handleEvidenceModalClose"
        />
        <AdsButton
          v-if="!isPlanPhase && editMode"
          id="uploader"
          buttonText="Upload Evidence"
          class="ml-md-8 mt-2 mb-6 pl-2 body-2 text-md-body-1 font-weight-bold"
          data-test="upload-evidence"
          icon="mdi-plus-circle-outline"
          secondary
          @click="evidenceModalOpen = true"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="editMode"
      class="mb-2 mx-0"
    >
      <v-col class="ma-0 pa-0 mb-10 d-flex flex-row justify-space-between">
        <AdsButton
          primary
          buttonText="Save"
          :disabled="isSaveButtonDisabled"
          data-test="save-goal"
          class="ml-0 ml-md-8"
          @click="saveGoal"
        />
        <AdsButton
          v-if="canDeleteGoal"
          buttonText="Remove this goal"
          class="delete-btn mr-1 mr-md-8"
          :color="ADS_Colors.PRIMARY_RED"
          primary
          icon="mdi-delete-outline"
          @click="handleDeleteGoal()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { AdsButton, AdsTextField } from '@nswdoe/doe-ui-core';
import { mdiInformationOutline } from '@mdi/js';
import { mapGetters } from 'vuex';
import TeachingStandard from './TeachingStandard';
import CreateTeachingStandard from './CreateTeachingStandard';
import KeyAccountability from './KeyAccountability';
import CreateKeyAccountability from './CreateKeyAccountability';
import UploadEvidenceModal from './UploadEvidenceModal';
import { sipOptions } from '@/data/sip';
import validators from '@/lib/validators';
import api from '@/api';
import FileCard from '@/components/FileCard';
import { clone, equals } from 'ramda';
import { areasOfPractice } from '@/data/areasOfPractice';
import Wrapper from '@/components/Wrapper';
import { SECTION_TYPE, TS_GOAL_DESCRIPTION_MIN_LENGTH } from '@/data/constants';
import {
  getIsPdpIncompleteArchived,
  resetNotification,
  resetSupervisorSignature,
} from '@/lib/pdp';
import RichTextField from '@/components/RichTextField';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import { isTsGoalValid } from '@/lib/dataValidation';

export default {
  name: 'TSPdpGoal',
  components: {
    RichTextField,
    FileCard,
    AdsTextField,
    AdsButton,
    CreateTeachingStandard,
    TeachingStandard,
    UploadEvidenceModal,
    KeyAccountability,
    CreateKeyAccountability,
    IncompleteFieldCard,
    Wrapper,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    section: {
      type: Object,
      default: null,
    },
    canDeleteGoal: {
      type: Boolean,
      default: false,
    },
    goal: {
      type: Object,
      required: true,
    },
    editMode: Boolean,
    isSupervisor: {
      default: false,
      type: Boolean,
    },
    goalIndex: {
      type: Number,
      default: 0,
    },
    owner: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      ADS_Colors,
      icons: {
        mdiInformationOutline,
      },
      ctsModalOpen: false,
      ckaModalOpen: false,
      sipOptions,
      areasOfPractice,
      accountabilities: [],
      isFormValid: true,
      rules: {
        stringFieldRequired: validators.stringFieldRequired,
        stringLengthRequired: validators.stringLengthRequired,
      },
      evidenceModalOpen: false,
      buttonEnabled: false,
      TS_GOAL_DESCRIPTION_MIN_LENGTH,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp', 'actionPdp']),
    isPlanPhase() {
      return this.section?.sectionType === SECTION_TYPE.plan;
    },
    ownerIsPrincipal() {
      return this.owner?.isPrincipal;
    },
    currentGoal() {
      return this.editMode
        ? this.formPdp?.sections
            .find((section) => section.id === this.section.id)
            .goals.find((goal) => goal.id === this.goal.id)
        : this.goal;
    },
    originalGoal() {
      return this.pdp?.sections
        .find((section) => section.id === this.section.id)
        .goals.find((goal) => goal.id === this.goal.id);
    },
    isGoalEqual() {
      if (this.currentGoal && this.originalGoal)
        return equals(this.currentGoal, this.originalGoal);
      else return true;
    },
    isGoalValid() {
      return isTsGoalValid(this.currentGoal);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isSaveButtonDisabled() {
      return !this.isGoalValid || this.isGoalEqual;
    },
    isIncompleteArchivedPdp() {
      const pdp = this.isSupervisor ? { ...this.actionPdp } : { ...this.pdp };
      return getIsPdpIncompleteArchived(pdp);
    },
  },
  watch: {
    isFormValid(newValue) {
      this.$store.commit('formValidation/updateGoalValidity', {
        id: this.id,
        description: this.currentGoal.description,
        isValid: this.currentGoal.description?.trim().length >= 100,
      });
      this.$emit('validated', newValue);
    },
  },
  methods: {
    handleEvidenceModalClose() {
      this.evidenceModalOpen = false;
    },
    async handleAddStandard() {
      this.ctsModalOpen = true;
    },
    async handleAddAccountability() {
      this.ckaModalOpen = true;
    },
    updateSipType(change) {
      this.currentGoal.sipType = change.id;
      if (this.currentGoal.sipType === 0 || this.currentGoal.sipType === 2) {
        this.currentGoal.sipOther = null;
      }
    },
    async downloadEvidenceFile(evidenceId, fileName) {
      const evidenceFile = await api.pdp.getEvidenceWithFile(evidenceId);
      if (evidenceFile) {
        const link = document.createElement('a');
        link.href = 'data:application/octet-stream;base64,' + evidenceFile.data;
        link.download = fileName;
        link.click();
      }
    },
    async deleteEvidenceFile(evidenceId) {
      let foundIndex = undefined;
      this.currentGoal.evidenceFiles?.find((item, index) => {
        const found = item.id === evidenceId;
        if (found) foundIndex = index;
      });
      this.currentGoal.evidenceFiles[foundIndex].markForDelete = true;
      this.formPdp.sections = this.formPdp.sections.map((section) => {
        if (section.id === this.section.id) {
          section.goals = section.goals.map((goal) => {
            if (goal.id === this.currentGoal.id) return this.currentGoal;
            return goal;
          });
        }
        return section;
      });
      await this.$store.dispatch('pdp/savePdp', this.formPdp);
    },
    async deleteStandard(index) {
      this.currentGoal.standards[index].markForDelete = true;
      this.formPdp.sections = this.formPdp.sections.map((section) => {
        if (section.id === this.section.id) {
          section.goals = section.goals.map((goal) => {
            if (goal.id === this.currentGoal.id) return this.currentGoal;
            return goal;
          });
        }
        return section;
      });
    },
    deleteAccountability(index) {
      if (this.currentGoal.accountabilities[index].created_at) {
        this.currentGoal.accountabilities[index].markForDelete = true;
      } else {
        this.currentGoal.accountabilities.splice(index, 1);
      }
      this.formPdp.sections = this.formPdp.sections.map((section) => {
        if (section.id === this.section.id) {
          section.goals = section.goals.map((goal) => {
            if (goal.id === this.currentGoal.id) return this.currentGoal;
            return goal;
          });
        }
        return section;
      });
    },
    async handleDeleteGoal() {
      this.currentGoal.markForDelete = true;
      const pdp = clone(this.formPdp);
      pdp.sections = pdp.sections.map((section) => {
        if (section.id === this.section.id) {
          section.goals = section.goals.map((goal) => {
            if (goal.id === this.currentGoal.id)
              return { ...goal, markForDelete: true };
            return goal;
          });
          if (section.sectionType === SECTION_TYPE.plan) {
            section = resetSupervisorSignature(section);
            section = resetNotification(section);
          }
        }
        return section;
      });
      await Promise.all([this.$store.dispatch('pdp/savePdp', pdp)]).then(() => {
        this.$store.commit('formValidation/deleteGoal', {
          id: this.id,
          isValid: this.isFormValid,
        });
        this.$emit('deleted');
      });
    },
    validate() {
      this.$emit('validated', this.isGoalValid);
    },
    async saveGoal() {
      this.isFormValid = this.$refs[this.id].validate();
      if (this.isFormValid && this.isGoalValid) {
        const pdp = clone(this.formPdp);
        pdp.sections = pdp.sections.map((section) => {
          if (section.id === this.section.id) {
            if (section.sectionType === SECTION_TYPE.plan) {
              section = resetSupervisorSignature(section);
              section = resetNotification(section);
            }
          }
          return section;
        });
        await Promise.all([this.$store.dispatch('pdp/savePdp', pdp)]).then(
          () => {
            this.$emit('saved');
          }
        );
      }
    },
  },
  inject: {
    isPrint: {
      default: false,
    },
  },
  emits: ['saved', 'validated', 'deleted'],
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;
}

.goal-tips.v-sheet {
  background-color: #cce7f080;
}

.delete-btn {
  //border-color: $ads-red;

  :deep .v-btn__content {
    color: white !important;
  }
}

.goal-divider {
  //display: block;
  //width: calc(100%);
  //border-bottom: 1px solid $ads-light-20;
  //margin: 2rem 0;
}

// @media screen and (max-width: 959px) {
//   .max-width-none {
//     max-width: none;
//   }
// }

.max-width-none {
  max-width: none;
}
</style>
