<template>
  <div>
    <UserBanner
      :user="loggedInUser"
      :pdp="pdp"
    />
    <!-- <UserBanner
      v-else
      class="mb-12"
      :showPdpInfo="false"
    /> -->
    <v-container class="ml-2 mt-6">
      <v-card
        class="pb-16 mb-16"
        v-for="item in pdpGuideContent"
        :key="item.title"
      >
        <v-row class="pl-12">
          <v-col>
            <h2 class="display-1 my-3">
              {{ item.title }}
            </h2>
            <p class="body-1">
              {{ item.subtitle }}
            </p>
          </v-col>
        </v-row>
        <v-container
          class="py-5 px-12"
          v-for="cardContent in item.cards"
          :key="cardContent.number"
        >
          <v-card class="pr-16 py-10">
            <v-container class="pl-8">
              <v-row>
                <v-col cols="1">
                  <FeatureIcon
                    size="40"
                    iconSize="18"
                    color="white"
                    backgroundColor="black"
                    icon="1"
                  >
                    <template #icon>
                      {{ cardContent.number }}
                    </template>
                  </FeatureIcon>
                </v-col>
                <v-col
                  cols="11"
                  class="pl-0"
                >
                  <h3 class="headline">
                    {{ cardContent.header }}
                  </h3>
                </v-col>
                <v-col cols="10">
                  <p
                    v-for="(subheader, index) in cardContent.subheaders"
                    :key="index"
                    class="body-1"
                  >
                    {{ subheader }}
                  </p>
                </v-col>
                <v-col cols="5">
                  <strong>
                    <p class="subitle-1">
                      {{ cardContent.keyActivities.title }}
                    </p>
                  </strong>
                  <ul
                    v-for="listItem in cardContent.keyActivities.list"
                    :key="listItem"
                  >
                    <li class="body-1">
                      {{ listItem }}
                    </li>
                  </ul>
                </v-col>
                <v-col
                  cols="5"
                  class="pl-10"
                >
                  <strong>
                    <p class="subitle-1">
                      {{ cardContent.phaseCheckpoint.title }}
                    </p>
                  </strong>
                  <ul
                    v-for="listItem in cardContent.phaseCheckpoint.list"
                    :key="listItem"
                  >
                    <li class="body-1">
                      {{ listItem }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </v-card>
      <v-container />
    </v-container>
  </div>
</template>

<script>
import UserBanner from '../../components/UserBanner.vue';

import FeatureIcon from '@nswdoe/doe-ui-core/src/FeatureIcon/FeatureIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DesktopPdpGuide',
  components: {
    UserBanner,
    FeatureIcon,
  },
  props: {
    pdpGuideContent: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'pdp']),
  },
};
</script>
