<template>
  <div>
    <SupervisorCard :isLoading="statsIsLoading" />
    <v-tabs
      v-model="tab"
      class="ma-0 pa-0"
      slider-color="red"
    >
      <v-tab
        v-for="(item, index) in availableTabs"
        :key="`management_tab${index + 1}`"
      >
        {{ item.tab }}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in availableTabs"
          :key="`tab-item-${item.id}`"
        >
          <SuperviseeTable
            :type="item.id"
            :isLoading="tableIsLoading"
            @action-click="handleActionClick"
            style="min-height: 500px"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SuperviseeTable from '@/components/SuperviseeTable.vue';
import SupervisorCard from '@/components/SupervisorCard.vue';
import { PDP_ROUTES, TAB_TYPES } from '@/data/constants';

export default {
  name: 'Management',
  components: {
    SupervisorCard,
    SuperviseeTable,
  },
  data() {
    return {
      TAB_TYPES,
      tab: 0,
      availableTabs: [],
      tabSet: false,
      tableIsLoading: false,
      statsIsLoading: false,
    };
  },
  props: {
    selectedTab: {
      type: String,
      default: '',
    },
  },
  watch: {
    loggedInUser: {
      async handler() {
        if (this.loggedInUser) {
          this.setAvailableTabs();
          if (this.availableTabs && this.selectedTab)
            await this.syncTabToRoute();
        }
      },
      immediate: true,
    },
    selectedTab: {
      async handler() {
        if (this.availableTabs && this.selectedTab) {
          await this.syncTabToRoute();
        }
      },
    },
    tab: {
      async handler() {
        await this.syncRouteToTab();
        this.loadStats();
        this.loadTableData();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions('pdp', ['getPdpManagement', 'getPdpStats', 'getPdpById']),
    async syncTabToRoute() {
      this.tab = this.availableTabs.findIndex(
        (item) => item.id === this.selectedTab
      );
    },
    async syncRouteToTab() {
      if (this.selectedTab) {
        const tab = this.availableTabs.findIndex(
          (item) => item.id === this.selectedTab
        );
        if (this.tab !== tab) {
          await this.$router.push(
            `${PDP_ROUTES.management.base.path}/${
              this.availableTabs[this.tab].id
            }`
          );
        }
      }
    },
    async loadTableData() {
      try {
        this.tableIsLoading = true;
        await this.getPdpManagement({
          query_type: this.selectedTab,
          // page_size: options.itemsPerPage,
          // page: options.page,
        });
        this.tableIsLoading = false;
      } catch (e) {
        // console.error('Error while loading table', e);
        this.tableIsLoading = false;
      }
    },
    async loadStats() {
      try {
        this.statsIsLoading = true;
        await this.getPdpStats();
        this.statsIsLoading = false;
      } catch (e) {
        // console.error('Error while loading stats', e);
        this.statsIsLoading = false;
      }
    },
    async handleActionClick(pdpId) {
      await this.$router.push(
        `${PDP_ROUTES.management.base.path}/${
          this.availableTabs[this.tab].id
        }/${pdpId}`
      );
    },
    setTabs(tabsSet) {
      this.tabSet = tabsSet;
    },
    setAvailableTabs() {
      if (!this.tabSet && this.loggedInUser) {
        const tabs = [
          TAB_TYPES.tasks,
          ...(this.loggedInUser?.isSupervisor ? [TAB_TYPES.team] : []),
          ...(this.loggedInUser?.isPrincipal ? [TAB_TYPES.school] : []),
          TAB_TYPES.archived,
        ];
        this.setTabs(true);
        this.availableTabs = tabs;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs .v-tab {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.03em;

  &.v-tab--active {
    font-weight: bold;
    color: $ads-grey-01;
  }

  &.v-tab:not(.v-tab--active) {
    color: $ads-grey-01;
  }
}
</style>
