var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isSupervisor)?_c('v-row',{staticClass:"mb-2",attrs:{"id":"goals","justify":"space-between"}},[_c('v-col',[_c('h4',{staticClass:"display-1 mx-5 mx-md-0"},[_vm._v("Professional goals")])])],1):_vm._e(),(!_vm.isSupervisor && _vm.allowEdit)?_c('v-row',{staticClass:"ma-0 py-0 px-5 px-md-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('p',{staticClass:"body-1"},[_vm._v(" The focus of the planning phase is to create an individual PDP to guide your work for the year ahead. You will set three to five professional goals, identify professional learning activities and determine the evidence that will show your progress towards your goals. ")]),_c('p',{staticClass:"body-1"},[_vm._v(" Aligning your goals to the "),_c('a',{attrs:{"href":"https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/teaching-standards","target":"_blank"}},[_vm._v(" Australian Professional Standards for Teachers ")]),_vm._v(" is key to developing your professional knowledge, practice and engagement. Your planned goals must take into account your accreditation requirements, where applicable. ")]),_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Use the SMART structure to write more effective goals: ")]),_c('ul',{staticClass:"pa-0 body-1 mb-4",staticStyle:{"list-style-type":"none"}},[_c('li',[_c('b',[_vm._v("S")]),_vm._v(" — Specific ")]),_c('li',[_c('b',[_vm._v("M")]),_vm._v(" — Measurable ")]),_c('li',[_c('b',[_vm._v("A")]),_vm._v(" — Achievable ")]),_c('li',[_c('b',[_vm._v("R")]),_vm._v(" — Relevant ")]),_c('li',[_c('b',[_vm._v("T")]),_vm._v(" — Time-bound ")])]),_c('p',{staticClass:"body-2 text-md-body-1"},[_c('a',{attrs:{"href":_vm.userIsPrincipal
              ? 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/school-leaders/goal-setting '
              : 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/classroom-teachers/goal-setting',"target":"”_blank”"}},[_vm._v(" Learn more about setting SMART goals ")])]),_c('Alert',{staticClass:"flex-grow-1 my-4",attrs:{"alertClass":"info-background-banner","inPage":"","text":"Your privacy is important","type":"info"},scopedSlots:_vm._u([{key:"optional",fn:function(){return [_c('p',{staticClass:"mt-2 mb-0 mb-md-4 negative-margin-left"},[_vm._v(" Only your PDP supervisor and principal can see your PDP. Nobody else can search or access your PDP. ")]),_c('p',{staticClass:"mt-2 mb-0 mb-md-4 negative-margin-left"},[_vm._v(" A "),_c('a',{attrs:{"href":"https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/Digtial_PDP_SDD_pack-data_security_fact_sheet.pdf","target":"_blank"}},[_vm._v(" fact sheet ")]),_vm._v(" is available for more information about the security, privacy and confidentiality of your data in the Digital PDP. ")])]},proxy:true}],null,false,1578620777)})],1)],1):_vm._e(),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-md-0 ma-0 pa-0"},[_c('AdsExpansionPanel',{attrs:{"accordion":false,"items":_vm.goals,"data-test":"goals-panel","multiple":""},scopedSlots:_vm._u([_vm._l((_vm.goals),function(goal,goalIndex){return {key:`header${goal.id}`,fn:function(){return [_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(
              goal.title
                ? `Goal ${goalIndex + 1}: ${goal.title}`
                : `Goal ${goalIndex + 1}`
            ),expression:"\n              goal.title\n                ? `Goal ${goalIndex + 1}: ${goal.title}`\n                : `Goal ${goalIndex + 1}`\n            ",arg:"plaintext"}],key:`expansion-header-${goalIndex}`,staticClass:"text-h6 font-weight-bold ma-0 pa-0",attrs:{"id":`expansion-header-${goalIndex}`}})]},proxy:true}}),_vm._l((_vm.goals),function(goal,goalIndex){return {key:`content${goal.id}`,fn:function(){return [_c('TSPdpGoal',{key:`pdpGoal-${goal.id}`,staticClass:"px-5 px-md-7",attrs:{"id":`pdpGoal-${goal.id}`,"canDeleteGoal":_vm.canDeleteGoal,"canUploadEvidence":_vm.canUploadEvidence,"editMode":_vm.allowEdit,"goal":goal,"goalIndex":goalIndex,"isSupervisor":_vm.isSupervisor,"owner":_vm.owner,"section":_vm.section},on:{"deleted":function($event){return _vm.updatePanels(goalIndex, 'delete')},"saved":function($event){return _vm.updatePanels(goalIndex, 'save')},"validated":_vm.goalChange}})]},proxy:true}})],null,true),model:{value:(_vm.goalPanelIndex),callback:function ($$v) {_vm.goalPanelIndex=$$v},expression:"goalPanelIndex"}})],1)],1),(_vm.goals && _vm.goals.length < _vm.MAX_TS_GOALS && _vm.allowEdit)?_c('AdsButton',{staticClass:"mt-8 pl-2 ml-5 ml-md-0",attrs:{"buttonText":"Add another goal","icon":"add_circle_outline","secondary":""},on:{"click":_vm.handleAddNewGoal}}):_vm._e(),(_vm.goals && _vm.allowEdit)?_c('p',{staticClass:"mt-6 mb-8 ml-4 ml-md-0"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("info_outline")]),_vm._v(" You added "+_vm._s(_vm.goalsInText)+" goals (maximum five). ")],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }