export const generateAppBarTitle = (
  routeName: string,
  isMobile: boolean
): string => {
  if (isMobile) {
    switch (routeName) {
      case 'My PDP-Tab':
        return 'My PDP';
      case 'Pdp Guide':
        return 'PDP Guide';
      case 'Management-Tab':
        return 'Management';
      default:
        return 'Performance & Development Plan';
    }
  } else return 'Performance & Development Plan';
};
