var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-0 pa-md-7"},[_c('v-row',{staticClass:"mx-0 flex-column"},[_c('v-col',{staticClass:"px-5 px-md-0 no-break-inside",attrs:{"md":"11"}},[_c('v-container',{staticClass:"px-0 pb-4"},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-0"},[_c('p',[_vm._v(" A self-assessment provides an opportunity to reflect on teaching and/or leadership practice, and on progress towards achievement of your PDP goals. ")])])],1)],1)],1),_c('v-col',{staticClass:"px-5 px-md-0",attrs:{"md":"11"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"align":"start"}},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-clipboard-outline ")]),(!_vm.midYearSection.complete)?_c('v-col',{staticClass:"pa-0 no-break-inside"},[(_vm.isIncompleteArchivedPdp && !_vm.formPdp?.midYearText)?_c('IncompleteFieldCard'):_c('RichTextField',{attrs:{"label":"Reflect on your progress towards achieving your professional goals.","rules":[_vm.rules.stringFieldRequired],"disabled":_vm.readOnly,"lazy":""},model:{value:(_vm.formPdp.midYearText),callback:function ($$v) {_vm.$set(_vm.formPdp, "midYearText", $$v)},expression:"formPdp.midYearText"}})],1):_vm._e(),(_vm.midYearSection.complete)?_c('v-col',{staticClass:"pa-0 ml-2 mb-8 no-break-inside"},[_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                _vm.formPdp.midYearText ? _vm.formPdp.midYearText : ''
              ),expression:"\n                formPdp.midYearText ? formPdp.midYearText : ''\n              "}],staticClass:"pa-0"})]):_vm._e(),(!_vm.midYearSection.complete)?_c('v-col',{staticClass:"text-center flex-shrink-1 flex-grow-0 flex-md-shrink-0",attrs:{"md":"1"}},[(!_vm.readOnly)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showTooltipMessage = !_vm.showTooltipMessage}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"primary","dark":""}},[_vm._v(" help_outline ")])],1)]}}],null,false,2478079068),model:{value:(_vm.showTooltipMessage),callback:function ($$v) {_vm.showTooltipMessage=$$v},expression:"showTooltipMessage"}},[_c('ul',{staticClass:"ma-0 pa-2"},[_c('li',[_vm._v("Reflect on your teaching and/or leadership practice")]),_c('li',[_vm._v("Evaluate the impact of professional learning")]),_c('li',[_vm._v(" Comment on evidence you have collected to demonstrate your progress ")]),_c('li',[_vm._v(" Identify any factors impacting your career development and strategies for addressing these. ")])])]):_vm._e()],1):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"mx-0 px-0 flex-column"},[(_vm.midYearSection.supervisorSignature)?_c('v-col',{staticClass:"px-0 px-md-0 no-break-inside"},[_c('p',{staticClass:"title px-5 px-md-0 mt-5"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-message-reply-text-outline ")]),_vm._v(" Comments ")],1),_c('v-row',{staticClass:"mx-0 flex-column"},[_c('v-col',{staticClass:"px-0 px-md-0 no-break-inside",attrs:{"md":"11"}},[_c('v-card',{staticClass:"elevation-2 mt-2"},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                _vm.midYearSection.supervisorComment
                  ? _vm.midYearSection.supervisorComment
                  : 'No comments from PDP supervisor'
              ),expression:"\n                midYearSection.supervisorComment\n                  ? midYearSection.supervisorComment\n                  : 'No comments from PDP supervisor'\n              "}],staticClass:"px-5 pt-5 px-md-4 text-body-1"}),(_vm.midYearSection.supervisorSignTime)?_c('v-card-title',{staticClass:"px-5 px-md-4 comments text-md-h6"},[_vm._v(" PDP Supervisor "+_vm._s(_vm.formatName(_vm.midYearSupervisor))+" signed this section on "+_vm._s(_vm.formatDateTime(_vm.midYearSection?.supervisorSignTime))+" ")]):_vm._e()],1)],1),_c('v-col',{staticClass:"px-0 px-md-0 no-break-inside",attrs:{"md":"11"}},[(_vm.midYearSection.userSignature)?_c('v-card',{staticClass:"elevation-2 mt-2 no-break-inside"},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                _vm.midYearSection?.userComment
                  ? _vm.midYearSection?.userComment
                  : 'No comments from supervisee'
              ),expression:"\n                midYearSection?.userComment\n                  ? midYearSection?.userComment\n                  : 'No comments from supervisee'\n              "}],staticClass:"px-5 pt-5 px-md-4 text-body-1"}),(_vm.midYearSection?.userSignTime)?_c('v-card-title',{staticClass:"px-5 px-md-4 comments text-md-h6"},[_vm._v(" "+_vm._s(_vm.formatName(_vm.loggedInUser))+" signed this section on "+_vm._s(_vm.formatDateTime(_vm.midYearSection?.userSignTime))+" ")]):_vm._e()],1):_vm._e()],1)],1)],1):_vm._e(),(
        !_vm.midYearSection?.complete &&
        !(_vm.isPrint && _vm.isIncompleteArchivedPdp && !_vm.formPdp?.midYearText)
      )?_c('v-col',{staticClass:"pl-5 pl-md-0",attrs:{"data-test":"save-self-assessment"}},[(!_vm.readOnly)?_c('AdsButton',{attrs:{"buttonText":"Save","disabled":!_vm.isMidYearTextValid,"secondary":""},on:{"click":_vm.save}}):_vm._e()],1):_vm._e()],1),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"px-0",attrs:{"md":"11"}},[(_vm.showNotifyBanner)?_c('v-card',{staticClass:"pa-5"},[_c('MarkComplete',{attrs:{"section":_vm.midYearSection,"showBanner":_vm.showNotifyBanner,"showEditGoalsInfo":false},on:{"onNotifyUser":_vm.handleNotifyUser}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }