<template>
  <v-container>
    <v-row>
      <v-col
        v-if="openDialog"
        class="ml-2"
        cols="auto"
      >
        <v-btn
          :readonly="true"
          :ripple="false"
          color="#cadaee"
          dense
          depressed
          max-width="51px"
          min-width="51px"
          rounded
        >
          <v-icon
            :color="'#122a61'"
            size="28px"
          >
            mdi-account-edit-outline
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        :sm="openDialog ? 0 : 8"
        class="px-0 px-sm-3"
      >
        <component
          :is="openDialog ? 'v-form' : 'div'"
          ref="dialogForm"
          v-model="isValid"
          lazy-validation
        >
          <div v-if="openDialog">
            <h3 class="title px-0 pb-3">
              Edit your PDP supervisor and your work location
            </h3>
          </div>
          <div
            class="mb-8"
            v-if="!openDialog"
          >
            <p>Add your PDP supervisor’s details here.</p>
            <p>
              Note: if your supervisor’s name does not appear when you search
              for them, ask them to access the Digital PDP using their own
              credentials, then try again.
            </p>
          </div>
          <v-combobox
            v-model="currentSupervisor.supervisor"
            :items="searchedUsersList"
            :loading="false"
            :rules="[rules.supervisorFieldRequired]"
            :search-input.sync="searchSupervisor"
            clearable
            item-text="staffDoEEmailAddress"
            item-value="id"
            label="PDP Supervisor's email"
            outlined
            placeholder="Start typing to search"
            return-object
            validate-on-blur
          />
          <v-combobox
            v-model="currentSupervisor.supervisorSchool"
            :items="searchedSchoolsList"
            :loading="false"
            :rules="[rules.schoolFieldRequired]"
            :search-input.sync="searchSupervisorSchool"
            clearable
            item-text="schoolFullName"
            item-value="id"
            label="PDP Supervisor's work location"
            outlined
            placeholder="Start typing to search"
            return-object
            validate-on-blur
          />
          <div class="mb-8">
            <p v-if="!openDialog">Add your school or work location.</p>
            <p v-if="openDialog">Edit your school or work location.</p>
            <p>
              If you don't work in a school, add 'Education office' as your work
              location.
            </p>
          </div>
          <v-combobox
            v-model="currentSupervisor.ownerSchool"
            :items="searchedSchoolsList"
            :loading="false"
            :rules="[rules.schoolFieldRequired]"
            :search-input.sync="searchOwnerSchool"
            clearable
            item-text="schoolFullName"
            item-value="id"
            label="Your school or work location"
            outlined
            placeholder="Start typing to search"
            return-object
            validate-on-blur
          />
          <div class="mb-8">
            <p>
              <b>Note:</b>
              As the school leader, your principal can view all the PDPs in the
              school
            </p>
          </div>
          <div class="d-flex flex-row-reverse">
            <AdsButton
              v-if="openDialog"
              buttonText="Save & Close"
              :disabled="disableSaveButton"
              class="ml-auto"
              type="submit"
              @click.prevent="validateAndSave"
            />
          </div>
        </component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { mapGetters, mapState, mapActions } from 'vuex';
import { debounce } from 'lodash';
import validators from '@/lib/validators';
import { AdsButton, AdsTextField } from '@nswdoe/doe-ui-core';
import { equals } from 'ramda';
import { USER_TYPE } from '@/data/constants';
import { VForm } from 'vuetify/lib/components';
import { setUserData } from '@/lib/pdp';

export default {
  name: 'TSPdpSupervisor',
  components: {
    AdsButton,
    AdsTextField,
    VForm,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ADS_Colors,
      searchSupervisor: null,
      searchSupervisorSchool: null,
      searchOwnerSchool: null,
      rules: {
        supervisorFieldRequired: validators.userFieldRequired,
        schoolFieldRequired: validators.schoolFieldRequired,
        stringFieldRequired: validators.stringFieldRequired,
      },
      currentSupervisor: {
        supervisor: {},
        supervisorSchool: {},
        ownerSchool: {},
      },
      isValid: true,
    };
  },
  methods: {
    ...mapActions('pdp', ['savePdp']),
    ...mapActions('user', ['searchUsersByEmail']),
    ...mapActions('school', ['searchSchoolsByFullName']),
    handleInput() {
      this.$emit('input', this.currentSupervisor);
    },
    async saveMappedPdp() {
      this.setPdpUserData();
      this.$emit('saved');
    },
    async validateAndSave() {
      const validated = this.$refs.dialogForm.validate();
      if (validated) {
        this.saveMappedPdp();
      }
    },
    async runUserSearch(newValue, oldValue) {
      if (
        newValue &&
        newValue.trim().length > 1 &&
        !equals(newValue, oldValue) &&
        oldValue
      ) {
        const debouncedSearch = debounce(async () => {
          await this.searchUsersByEmail(newValue);
        }, 150);
        await debouncedSearch();
      }
    },
    async runSchoolSearch(newValue, oldValue) {
      if (
        newValue &&
        newValue.trim().length > 1 &&
        !equals(newValue, oldValue) &&
        oldValue
      ) {
        const debouncedSearch = debounce(async () => {
          await this.searchSchoolsByFullName(newValue);
        }, 150);
        await debouncedSearch();
      }
    },
    setPdpUserData() {
      const pdp = setUserData(this.formPdp, this.pdpUsersToSet, {
        position: null,
        school: this.currentSupervisor.ownerSchool,
      });
      this.formPdp.owner_school_id = pdp.owner_school_id;
      this.formPdp.pdp_user_associations = pdp.pdp_user_associations;
      this.formPdp.supervisorComments = pdp.supervisorComments;
      this.formPdp.sections = pdp.sections;
    },
    findActivePdpUserByType(pdpUsers, pdpUserType) {
      if (pdpUsers.length === 0) {
        return null;
      }
      const activeUser = pdpUsers.find(
        (pdpUser) => pdpUser.type === pdpUserType && pdpUser.active
      );
      return activeUser || null;
    },
    setSearchData() {
      if (this.isPdpLoaded) {
        this.currentSupervisor = {
          supervisor: this.latestSupervisor?.user,
          ownerSchool: this.formPdp.owner_school,
          supervisorSchool: this.latestSupervisor?.school,
        };
      }
    },
  },
  watch: {
    isPdpLoaded: {
      handler() {
        this.setSearchData();
      },
      immediate: true,
    },
    openDialog: {
      handler() {
        if (this.openDialog) {
          this.setSearchData();
        }
      },
      immediate: true,
    },
    save: {
      handler() {
        if (this.save === true) {
          this.saveMappedPdp();
        }
      },
      immediate: true,
    },
    currentSupervisor: {
      handler() {
        if (this.currentSupervisor) {
          this.handleInput();
        }
      },
      immediate: true,
    },
    searchSupervisorSchool(newValue, oldValue) {
      this.runSchoolSearch(newValue, oldValue);
    },
    searchSupervisor(newValue, oldValue) {
      this.runUserSearch(newValue, oldValue);
    },
    searchOwnerSchool(newValue, oldValue) {
      this.runSchoolSearch(newValue, oldValue);
    },
  },
  computed: {
    ...mapGetters(['formPdp', 'isPdpLoaded']),
    ...mapState({
      searchedUsersList: (state) =>
        state.user.usersByIdentifier.filter(
          (user) => user.id !== state.loggedInUser.id
        ),
      searchedSchoolsList: (state) => state.school.schoolsByFullName,
    }),
    latestSupervisor() {
      return this.findActivePdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.supervisor
      );
    },
    disableSaveButton() {
      return !(
        this.currentSupervisor?.supervisor &&
        this.currentSupervisor?.supervisorSchool?.id &&
        this.currentSupervisor?.ownerSchool?.id
      );
    },
    pdpUsersToSet() {
      return [
        {
          userType: USER_TYPE.supervisor,
          userId: this.currentSupervisor?.supervisor?.id,
          school_id: this.currentSupervisor?.supervisorSchool?.id,
          position: null,
        },
      ];
    },
  },
  emits: ['saved', 'input'],
};
</script>
