var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"mb-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"px-5 px-md-10 pt-5"},[_c('h1',{class:{ 'mb-5': !_vm.section?.notifySupervisorTime }},[_vm._v(" Professional Goals ")]),(_vm.section?.notifySupervisorTime)?_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(
                  `Submitted: ${_vm.formatDateTime(
                    this.section.notifySupervisorTime
                  )}`
                ),expression:"\n                  `Submitted: ${formatDateTime(\n                    this.section.notifySupervisorTime\n                  )}`\n                ",arg:"plaintext"}]}):_vm._e()])])],1),_vm._l((_vm.section.goals),function(goal,index){return _c('v-row',{key:`plan-read-only-goal-${goal.id}`,attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"goal-heading mb-10 px-5 px-md-10 py-5"},[_vm._v(" Goal "+_vm._s(index + 1)+": "+_vm._s(goal.title)+" ")]),_c('div',{staticClass:"px-5 px-md-10 no-break-inside"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-star-outline ")]),_vm._v(" Description ")],1)]),(_vm.isIncompleteArchivedPdp && !goal?.description)?_c('IncompleteFieldCard'):_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(goal?.description ?? ''),expression:"goal?.description ?? ''"}],staticClass:"goal-text mb-10"})],1),_c('hr'),_c('div',{staticClass:"px-5 px-md-10 no-break-inside"},[_c('p',{staticClass:"no-break-inside"},[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-calendar-edit ")]),_vm._v(" Standard descriptors ")],1)]),(
                    _vm.isIncompleteArchivedPdp && goal?.standards?.length === 0
                  )?_c('IncompleteFieldCard'):_c('div',{class:`${goal.standards}mb-10`},_vm._l((goal?.standards),function(standard){return _c('TeachingStandardPrint',{key:`teachingStandard-${goal.id}${standard.id}${standard.focus}${standard.level}`,attrs:{"canDelete":false,"outlined":false,"flat":"","standard":standard}})}),1)],1),_c('hr'),(_vm.ownerIsPrincipal)?_c('div',{staticClass:"px-5 px-md-10 no-break-inside"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-comment-account-outline ")]),_vm._v(" Strategic Improvement Plan ")],1)]),(
                    _vm.isIncompleteArchivedPdp &&
                    !goal?.sipType &&
                    !goal?.sipOther
                  )?_c('IncompleteFieldCard'):_c('div',[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(
                      _vm.sipOptions[goal?.sipType]?.text
                    ),expression:"\n                      sipOptions[goal?.sipType]?.text\n                    ",arg:"plaintext"}],staticClass:"goal-text"}),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(goal?.sipOther ?? ''),expression:"goal?.sipOther ?? ''"}],staticClass:"goal-text mb-10"})])],1):_vm._e(),(_vm.ownerIsPrincipal)?_c('hr'):_vm._e(),(_vm.ownerIsPrincipal)?_c('div',{staticClass:"px-5 px-md-10 no-break-inside"},[_c('p',{staticClass:"no-break-inside"},[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-sitemap-outline ")]),_vm._v(" Key Accountabilities ")],1)]),(
                    _vm.isIncompleteArchivedPdp &&
                    goal?.accountabilities?.length === 0
                  )?_c('IncompleteFieldCard'):_vm._e(),_c('div',{class:`${goal?.accountabilities}mb-10 no-break-inside`},_vm._l((goal?.accountabilities),function(accountability){return _c('KeyAccountabilityPrint',{key:`goalaccountability-${goal.id}.${accountability?.id}`,attrs:{"accountability":accountability,"canDelete":false,"outlined":false,"flat":""}})}),1)],1):_vm._e(),(_vm.ownerIsPrincipal)?_c('hr'):_vm._e(),_c('div',{staticClass:"px-5 px-md-10 no-break-inside"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-format-list-bulleted ")]),_vm._v(" Professional learning ")],1)]),(
                    _vm.isIncompleteArchivedPdp && !goal?.professionalLearning
                  )?_c('IncompleteFieldCard'):_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(`${goal.professionalLearning ?? ''}`),expression:"`${goal.professionalLearning ?? ''}`"}],staticClass:"goal-text mb-10"})],1),_c('hr'),_c('div',{staticClass:"mt-4 mb-10 px-5 px-md-10 no-break-inside"},[_c('p',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.ADS_Colors.Blue_2}},[_vm._v(" mdi-content-copy ")]),_vm._v(" Evidence ")],1)]),(_vm.isIncompleteArchivedPdp && !goal?.evidence)?_c('IncompleteFieldCard'):_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(`${goal.evidence ?? ''}`),expression:"`${goal.evidence ?? ''}`"}],staticClass:"goal-text"})],1)])])],1)})],2)],1)],1),_c('EventLog',{staticClass:"my-10 px-0 px-md-10 py-8",attrs:{"pdp":this.pdp,"section":this.section,"owner":this.pdp?.owner,"supervisor":this.supervisor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }