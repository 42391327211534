<template>
  <v-container class="px-0 px-md-8">
    <OrientationBanner
      :isSupervisor="false"
      :pdp="formPdp"
      :section="planSection"
      :supervisorName="supervisorName"
      class="px-5 px-md-0 pb-0 pb-md-3 mt-3"
      @signed="handleSignedForm"
    />
    <component
      :is="isMobile ? 'v-container' : 'v-card'"
      class="mt-0 mt-md-5 px-0 py-5 px-md-5"
    >
      <TSPdpGoals
        :section="planSection"
        :userIsPrincipal="loggedInUser.isPrincipal"
      />
    </component>
    <div v-if="showNotifyUserInPlanEditor">
      <v-card class="my-10">
        <MarkComplete
          :disabled="planComplete || !allGoalsValid"
          :disabledMessage="planIncompleteMessage"
          :section="planSection"
          :showBanner="showNotifyUserInPlanEditor"
          :showEditGoalsInfo="true"
          class="pa-5 mt-3"
          @onNotifyUser="handleNotifyUser"
        />
      </v-card>
    </div>
  </v-container>
</template>

<script>
import TSPdpGoals from '@/components/TSPdpGoals.vue';
import MarkComplete from '@/components/MarkComplete.vue';
import OrientationBanner from '@/components/OrientationBanner.vue';
import { VCard, VContainer } from 'vuetify/lib';
import { mapGetters } from 'vuex';
import { planIncompleteMessage } from '@/views/MyPdp/index.lib';
import { SECTION_TYPE, USER_TYPE } from '@/data/constants';
import { formatName } from '@/utils';
import { findPdpUserByType } from '@/lib/pdp';

export default {
  name: 'TSPlanEditor',
  components: {
    OrientationBanner,
    MarkComplete,
    TSPdpGoals,
    VCard,
    VContainer,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp']),
    ...mapGetters('formValidation', ['allGoalsValid', 'validationGoals']),
    planSection() {
      return this.formPdp?.sections
        ? this.formPdp?.sections.find(
            (s) => s.sectionType === SECTION_TYPE.plan
          )
        : undefined;
    },
    planIncompleteMessage() {
      if (this.loggedInUser?.isPrincipal) {
        return planIncompleteMessage(this.planSection);
      } else {
        return '';
      }
    },
    supervisor() {
      return findPdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.supervisor
      );
    },
    planComplete() {
      return this.planSection?.complete;
    },
    supervisorName() {
      return formatName(this.supervisor?.user);
    },
    showNotifyUserInPlanEditor() {
      return !this.planSection?.notifySupervisor;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    async handleNotifyUser() {
      if (this.planSection) {
        this.planSection.notifySupervisor = true;
        this.planSection.notifySupervisorTime = Date.now();
        this.formPdp.sections = this.formPdp.sections.map((s) => {
          if (s.id === this.planSection?.id) {
            return this.planSection;
          }
          return s;
        });
        await this.$store.dispatch('pdp/savePdp', this.formPdp);
        this.$vuetify.goTo(0);
      }
    },
    async handleSignedForm(sectionToBeUpdated, comment) {
      this.$emit('planSigned', sectionToBeUpdated, comment);
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 959px) {
  :deep .v-expansion-panel {
    &-header.panel {
      padding-left: 20px;
      padding-right: 20px;
    }
    .v-divider {
      max-width: none;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}
</style>
