<template>
  <v-container class="mt-5 px-0">
    <v-row no-gutters>
      <v-col>
        <v-row
          no-gutters
          class="px-md-7 pr-md-16 goal-content"
        >
          <v-col
            v-if="editMode"
            class="px-0"
          >
            <p class="goal-section-title">
              <v-icon
                color="success"
                class="mr-2"
              >
                mdi-star-outline
              </v-icon>
              Goal
            </p>
            <AdsTextField
              v-model.trim="currentGoal.title"
              :counter="false"
              :data-test="`goal-title-input-${currentGoal?.index}`"
              class="ml-9 no-break-inside"
              label="Title (optional)"
              persistent-placeholder
              placeholder="Title (optional)"
              :disabled="readOnly"
            />
            <RichTextField
              v-model.trim="currentGoal.description"
              class="ml-9 no-break-inside"
              label="Description"
              :placeholder="
                type === GOAL_TYPE.work && !readOnly
                  ? 'Please enter between 50-500 characters, approx. 10-100 words, for each goal.'
                  : ''
              "
              :rules="
                validation
                  ? [
                      rules.stringLengthRequired(
                        WORK_GOAL_DESCRIPTION_MIN_LENGTH
                      ),
                      rules.maxStringLengthRule(
                        WORK_GOAL_DESCRIPTION_MAX_LENGTH
                      ),
                    ]
                  : []
              "
              :disabled="readOnly"
            />
          </v-col>
          <v-col v-if="!editMode">
            <strong class="title">
              <v-icon
                color="success"
                class="mr-2"
              >
                mdi-star-outline
              </v-icon>
              Description
            </strong>
            <v-card
              v-if="currentGoal?.description"
              class="mx-10 my-5 no-break-inside"
            >
              <v-card-text
                v-dompurify-html:plaintext="currentGoal?.description"
                class="px-10 py-5 ma-0 goal-text"
              ></v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mb-6"></v-divider>
        <v-row
          class="px-md-7 pr-md-16 goal-content"
          no-gutters
        >
          <v-col class="mt-0 px-0 no-break-inside">
            <p class="goal-section-title no-break-inside">
              <v-icon
                color="success"
                class="mr-2"
              >
                mdi-calendar-edit
              </v-icon>
              Strategies to achieve goals
            </p>
            <p
              v-if="!isSupervisor && editMode"
              class="ml-9 ads-dark no-break-inside"
            >
              What needs to happen for these goals to be met – What actions, by
              when and who is responsible?
            </p>
            <RichTextField
              v-model.trim="currentGoal.strategies"
              class="ml-9 no-break-inside"
              label="Add details"
              :rules="
                validation
                  ? [rules.maxStringLengthRule(GOAL_STRATEGIES_MAX_LENGTH)]
                  : []
              "
              :disabled="readOnly"
            />
          </v-col>
          <v-col
            v-if="!editMode"
            class="mt-5 no-break-inside"
          >
            <strong class="title no-break-inside">
              <v-icon
                color="success"
                class="mr-2"
              >
                mdi-star-outline
              </v-icon>
              Strategies to achieve goals
            </strong>
            <v-card
              v-if="currentGoal?.strategies"
              class="mx-10 my-5 no-break-inside"
            >
              <v-card-text class="px-10 py-5 ma-0">
                <p
                  v-dompurify-html:plaintext="currentGoal?.strategies"
                  class="goal-text"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="editMode"
          class="goal-buttons"
          no-gutters
        >
          <v-col class="mt-2 pr-0 pl-7 mb-10">
            <AdsButton
              :disabled="isSaveButtonDisabled"
              buttonText="Save"
              class="mr-8"
              data-test="save-goal"
              primary
              depressed
              @click="saveGoal"
            />
            <AdsButton
              v-if="canDeleteGoal"
              color="primary-red"
              buttonText="Remove this goal"
              class="delete-btn"
              icon="mdi-delete-outline"
              primary
              depressed
              @click="handleDeleteGoal()"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdsButton from '@nswdoe/doe-ui-core/src/AdsButton/AdsButton.vue';
import { AdsTextField } from '@nswdoe/doe-ui-core';
import { mapGetters, mapMutations } from 'vuex';
import validators from '@/lib/validators';
import { equals } from 'ramda';
import {
  GOAL_STRATEGIES_MAX_LENGTH,
  GOAL_TYPE,
  MIN_CAREER_GOALS,
  MIN_WORK_GOALS,
  WORK_GOAL_DESCRIPTION_MAX_LENGTH,
  WORK_GOAL_DESCRIPTION_MIN_LENGTH,
} from '@/data/constants';
import { handleDeleteGoal } from '@/lib/pdp';
import RichTextField from '@/components/RichTextField.vue';

export default {
  name: 'NTSGoal',
  components: {
    RichTextField,
    AdsTextField,
    AdsButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    section: {
      type: Object,
      default: null,
    },
    goal: {
      type: Object,
      required: true,
    },
    editMode: Boolean,
    isSupervisor: {
      default: false,
      type: Boolean,
    },
    owner: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => '',
      required: true,
    },
  },
  data() {
    return {
      GOAL_TYPE,
      isFormValid: true,
      rules: {
        stringLengthRequired: validators.stringLengthRequired,
        maxStringLength: validators.maxStringLength,
        maxStringLengthRule: validators.maxStringLengthRule,
      },
      WORK_GOAL_DESCRIPTION_MIN_LENGTH,
      WORK_GOAL_DESCRIPTION_MAX_LENGTH,
      GOAL_STRATEGIES_MAX_LENGTH,
    };
  },
  computed: {
    ...mapGetters(['formPdp', 'pdp']),
    canDeleteGoal() {
      const workGoals = this.section.goals.filter(
        (goal) => goal.type === GOAL_TYPE.work
      );
      const careerGoals = this.section.goals.filter(
        (goal) => goal.type === GOAL_TYPE.career
      );
      if (this.type === GOAL_TYPE.work) {
        return workGoals.length > MIN_WORK_GOALS;
      } else if (this.type === GOAL_TYPE.career) {
        return careerGoals.length > MIN_CAREER_GOALS;
      } else return false;
    },
    currentGoal() {
      return this.editMode
        ? this.formPdp?.sections
            .find((section) => section.id === this.section.id)
            .goals.find((goal) => goal.id === this.goal.id)
        : this.goal;
    },
    originalGoal() {
      return this.pdp?.sections
        .find((section) => section.id === this.section.id)
        .goals.find((goal) => goal.id === this.goal.id);
    },
    isGoalEqual() {
      if (this.currentGoal && this.originalGoal)
        return equals(this.currentGoal, this.originalGoal);
      else return true;
    },
    isSaveButtonDisabled() {
      return this.isGoalEqual;
    },
  },
  watch: {
    isFormValid: {
      handler(newValue) {
        this.updateGoalValidity({
          id: this.id,
          description: this.currentGoal.description,
          isValid: newValue,
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('formValidation', ['updateGoalValidity']),
    async handleDeleteGoal() {
      this.formPdp.sections = handleDeleteGoal(
        this.section.sectionType,
        this.currentGoal,
        this.formPdp
      );
      this.$emit('deleted');
    },
    async saveGoal() {
      this.$emit('saved');
    },
  },
  emits: ['saved', 'deleted'],
};
</script>

<style scoped lang="scss">
.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;

  span {
    display: inline-block;
  }
}

.goal-section-title {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.goal-tips.v-sheet {
  background-color: #cce7f080;
}

.delete-btn {
  :deep .v-btn__content {
    color: white;
  }
}

:deep .v-divider {
  width: calc(100% + 28px);
}

:deep .v-messages:not(.error--text) {
  color: $ads-navy;
  font-size: 16px;
}

@media print {
  .goal-content {
    padding-left: 28px;
    padding-right: 64px;
  }
}
</style>
