import Vue, { VueConstructor } from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import { oauth, vuetify, vuegtm } from '@/plugins';
import { IeBlocker } from '@nswdoe/doe-ui-core';
import api from '@/api';
import VueDOMPurifyHTML from 'vue-dompurify-html';

// Enhance the event system on Vue.prototype
import {
  useDownwardBroadcast,
  useEventHub,
  useUpwardBroadcast,
} from './hooks/events';
/**
 * The mixin for implementing dynamic <title> in SPA according to different 'view/page' the user views.
 * It's to support better accessibility.
 * The mixin is from the official doc https://ssr.vuejs.org/guide/head.html
 */
import titleMixin from './mixins/titleMixin';

Vue.use(VueDOMPurifyHTML, {
  namedConfigurations: {
    plaintext: {
      USE_PROFILES: { html: false },
    },
  },
});

useEventHub(Vue);
useUpwardBroadcast(Vue);
useDownwardBroadcast(Vue);

// import VueCompositionAPI from '@vue/composition-api';
// Vue.use(VueCompositionAPI);

Vue.mixin(titleMixin);

let renderComponent: VueConstructor,
  renderProps: { props: { [key: string]: unknown } } | Record<string, never>;

if (IeBlocker.isIe()) {
  // render IeBlocker
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: 'Performance & Development Plan',
    },
  };
} else {
  // render App, config the App root instance (oAuth plugin, etc.
  renderComponent = App as unknown as VueConstructor;
  renderProps = {};

  Vue.config.productionTip = false;

  /**
   * Unified interface for API requests
   * Added onto Vue.prototype object, so all Vue component instances can access it via `this.$api`
   */
  Vue.prototype.$api = api;
}

oauth(Vue as unknown as Vue);
vuegtm(Vue);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(renderComponent, renderProps),
}).$mount('#app');
