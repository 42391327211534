var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('v-container',{staticClass:"pa-0 nts-user-banner--print"},[_c('UserBanner',{attrs:{"user":_vm.actionPdp?.owner,"pdp":_vm.actionPdp,"isPrint":"","backgroundColour":"white"}})],1),_c('v-container',{staticClass:"pa-0"},[_c('v-container',{staticClass:"mb-10 pa-0"},[_c('PrintTabs',{attrs:{"tabs":_vm.tabs,"selectedTab":_vm.getTabIndexById(_vm.TAB_TYPES.plan.id)}})],1),_c('div',{staticClass:"page-break"},[(_vm.isCurrentRole || _vm.isPlanRole || _vm.userIsSchoolPrincipal)?_c('div',[(
            _vm.planSection?.notifySupervisor &&
            !_vm.planSection?.complete &&
            (_vm.isCurrentSupervisor || _vm.isCurrentPdpPrincipal)
          )?_c('SignaturePanel',{staticClass:"my-10 mb-7",attrs:{"owner":_vm.actionPdp?.owner,"users":_vm.actionPdp?.pdp_user_associations,"section":_vm.planSection,"disableSign":!_vm.isSectionValid}}):_vm._e(),_c('NTSSectionReadOnlyPrint',{attrs:{"pdp":_vm.actionPdp,"section":_vm.planSection}})],1):_c('div',{staticClass:"my-10"},[_c('NoPermissionCard')],1)]),_c('v-container',{staticClass:"my-10 pa-0"},[_c('PrintTabs',{attrs:{"tabs":_vm.tabs,"selectedTab":_vm.getTabIndexById(_vm.TAB_TYPES.implement.id)}})],1),_c('div',{staticClass:"page-break"},[(_vm.isCurrentRole || _vm.isMidYearRole || _vm.userIsSchoolPrincipal)?_c('div',[(
            _vm.midYearSection?.notifySupervisor &&
            ((!_vm.midYearSection?.complete && _vm.isCurrentSupervisor) ||
              (_vm.isCurrentPdpPrincipal &&
                !_vm.hasPdpPrincipalSignedMidYear &&
                !_vm.actionPdp?.finalCommentLocked))
          )?_c('SignaturePanel',{staticClass:"pa-0 my-10",attrs:{"owner":_vm.actionPdp?.owner,"users":_vm.actionPdp?.pdp_user_associations,"section":_vm.midYearSection,"disableSign":!_vm.isSectionValid && !_vm.midYearSection?.complete}}):_vm._e(),_c('NTSSectionReadOnlyPrint',{attrs:{"pdp":_vm.actionPdp,"section":_vm.midYearSection}})],1):_c('div',{staticClass:"my-10"},[_c('NoPermissionCard')],1)]),_c('v-container',{staticClass:"my-10 pa-0"},[_c('PrintTabs',{attrs:{"tabs":_vm.tabs,"selectedTab":_vm.getTabIndexById(_vm.TAB_TYPES.review.id)}})],1),(_vm.isCurrentRole || _vm.userIsSchoolPrincipal)?_c('div',{staticClass:"page-break"},[(_vm.showAnnualReviewSupervisorSignaturePanel)?_c('SignaturePanel',{staticClass:"pa-0 my-10",attrs:{"owner":_vm.actionPdp?.owner,"users":_vm.actionPdp?.pdp_user_associations,"section":_vm.finalSection,"disableSign":!_vm.isSectionValid || !_vm.isFormValid}}):_vm._e(),_c('NTSReviewReadOnly',{staticClass:"my-5",attrs:{"pdp":_vm.actionPdp,"section":_vm.finalSection}}),(
          _vm.midYearSection?.complete &&
          !_vm.finalSection?.complete &&
          _vm.isCurrentSupervisor &&
          _vm.finalSection.notifySupervisor
        )?_c('NTSSupervisorAnnualReviewComment',{staticClass:"my-10",attrs:{"isPanelOn":_vm.readyToSignAnnualReview,"readOnly":""}}):_vm._e(),(_vm.finalSection?.notifySupervisor)?_c('EventLog',{staticClass:"my-10 px-0 px-md-10 py-8",attrs:{"section":_vm.finalSection,"owner":_vm.actionPdp?.owner,"supervisor":_vm.supervisor,"principal":_vm.principal}}):_vm._e()],1):_c('div',{staticClass:"my-10 page-break"},[_c('NoPermissionCard')],1),(!_vm.isCurrentAdditionalSupervisor)?_c('PersonalNotesCard',{staticClass:"my-10 py-0",attrs:{"comments":_vm.pdpUserNotes}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }