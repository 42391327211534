<template>
  <v-tabs
    :value="selectedTab"
    slide-color="red"
  >
    <v-tab
      v-for="tab in tabs"
      :key="tab.id"
      tabindex="-1"
    >
      {{ tab.tab }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'PrintTabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.v-tab {
  pointer-events: none;
  user-select: none;
}
</style>
