import { UserState, RootState } from '../types';
import { User } from '../../../../lib/schema';
import api from '../../api';
import { ActionContext } from 'vuex';

export default {
  namespaced: true,
  state: (): UserState => ({
    users: [],
    usersByIdentifier: [],
  }),
  mutations: {
    UPDATE_USERS_BY_EMAIL(state: UserState, users: User[]) {
      state.usersByIdentifier = users;
    },
  },
  actions: {
    async searchUsersByEmail(
      { commit, rootState }: ActionContext<RootState, RootState>,
      email: string
    ) {
      const users = await api.user.getUsersByEmail(email);
      const filteredUsers = users?.filter(
        (user) => user.id !== rootState.loggedInUser?.id
      );
      commit('UPDATE_USERS_BY_EMAIL', filteredUsers);
    },
  },
};
