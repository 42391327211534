<template>
  <div>
    <apexchart
      :type="type"
      :width="width"
      :height="height"
      :series="series"
      :options="options"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'AdsChart',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: 'ADS chart component',
    },
    type: {
      type: String,
      default: 'bar',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '400',
    },
    series: {
      type: Array,
      default: () => [],
    },
    stacked: {
      type: Boolean,
      default: true,
    },
    horizontalBar: {
      type: Boolean,
      default: true,
    },
    reversedY: {
      type: Boolean,
      default: true,
    },
    segTypes: {
      type: Array,
      default: () => ['solid'],
    },
    segColours: {
      type: Array,
      default: () => [
        '#555555',
        '#E4E4E6',
        '#F3B8B6',
        '#407EC9',
        '#041E42',
        '#00A908',
      ],
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    adsForcedOptions: {
      type: Boolean,
      default: true,
    },
    segLabels: {
      type: Boolean,
      default: true,
    },
    segLabelColours: {
      type: Array,
      default: () => ['#fff'],
    },
    xlabelShow: {
      type: Boolean,
    },
    xLabelCat: {
      type: Array,
      default: () => [],
    },
    yaxisLabel: {
      type: Boolean,
      default: false,
    },
    yaxisBorder: {
      type: Boolean,
      default: false,
    },
    yaxisTicks: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Boolean,
      default: false,
    },
    axisBorder: {
      type: Boolean,
      default: false,
    },
    axisTicks: {
      type: Boolean,
      default: false,
    },
    targetText: {
      type: String,
      default: null,
    },
    targetPosition: {
      type: Number,
      default: undefined,
    },
    labelOffsetX: {
      type: Number,
      default: 5,
    },
    targetOffsetX: {
      type: Number,
      default: null,
    },
    legendPosition: {
      type: String,
      default: 'left',
    },
    inverseLegend: {
      type: Boolean,
      default: false,
    },
    breakpoint: {
      type: Number,
      default: 740,
    },
  },
  computed: {
    options() {
      let computedOptions = {
        chart: {
          id: 'ads-chart',
          stacked: this.stacked,
          // disable toolbar
          toolbar: {
            show: false,
          },
        },
        // decide if bar chart is horizontal or vertical
        plotOptions: {
          bar: {
            horizontal: this.horizontalBar,
          },
        },
        // colours of bar chart segments
        colors: this.segColours,
        fill: {
          opacity: 1,
          type: this.segTypes,
        },
        // white line seperating segments
        stroke: {
          curve: 'straight',
          width: 3,
          colors: ['#fff'],
        },
        // disable default grid settings on y and x axis
        grid: {
          show: this.grid,
        },
        // style the numbers displayed inside the bar segments
        dataLabels: {
          enabled: this.segLabels,
          style: {
            fontSize: '16px',
            fontWeight: '400',
            colors: this.segLabelColours,
          },
        },
        legend: {
          position: this.legendPosition,
          fontSize: '16px',
          inverseOrder: this.inverseLegend,
          markers: {
            width: 25,
            height: 25,
            radius: 3,
            offsetY: 5,
            offsetX: -5,
          },
          itemMargin: {
            vertical: 5,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
        tooltip: {
          enabled: false,
          onDatasetHover: {
            highlightDataSeries: false,
          },
        },
        // disable hover, active states
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        // disable xaxis labels, borders and ticks
        xaxis: {
          categories: this.xLabelCat,
          labels: {
            show: this.xlabelShow,
            style: {
              colors: ['#000'],
              fontSize: '16px',
              fontWeight: 800,
              cssClass: 'subtext',
            },
          },
          axisBorder: {
            show: this.axisBorder,
          },
          axisTicks: {
            show: this.axisTicks,
          },
        },
        // bar chart displays from the right + disables labels and borders
        yaxis: {
          reversed: this.reversedY,
          labels: {
            show: this.yaxisLabel,
          },
          axisBorder: {
            show: this.yaxisBorder,
          },
          axisTicks: {
            show: this.yaxisTicks,
          },
        },
        // target marker settings
        annotations: {
          position: 'front',
          xaxis: [
            {
              x: this.targetPosition,
              strokeDashArray: 0,
              borderColor: '#B81237',
              borderWidth: 5,
              opacity: 1,
              offsetY: 55,
              offsetX: this.targetOffsetX,
              label: {
                textAnchor: 'end',
                offsetX: this.labelOffsetX,
                offsetY: -25,
                borderColor: '#fff',
                orientation: 'horizontal',
                position: 'bottom',
                style: {
                  cssClass: 'annotation',
                  color: '#B81237',
                  background: '#fff',
                  fontFamily: 'Arial',
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  padding: {
                    top: 13,
                    bottom: 50,
                    right: 20,
                  },
                },
                text: this.targetText,
              },
            },
          ],
        },
        responsive: [
          {
            breakpoint: this.breakpoint,
            options: {
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
      let chartOptions = this.chartOptions;
      return !this.adsForcedOptions
        ? chartOptions
        : { ...computedOptions, ...chartOptions };
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .subtext tspan:nth-child(2) {
  font-weight: 400;
}

::v-deep .apexcharts-legend-marker {
  cursor: auto !important;
}

::v-deep .apexcharts-xaxis-annotations line {
  outline: 1.5px solid white;
}
</style>
