<template>
  <div>
    <v-row
      v-if="isPlanSection && !isOwner && pdp.active"
      class="mb-7"
      no-gutters
    >
      <v-col class="pa-0">
        <v-card
          class="px-6 pt-5 pb-0 pb-md-0"
          color="primary-teal"
          elevation="0"
          style="border-radius: 12px"
        >
          <v-row
            class="d-flex ma-0 pa-0"
            no-gutters
          >
            <v-col class="pa-0">
              <v-card-title class="pa-0 pb-5 primary-blue--text headline">
                Helpful links for supervisors
              </v-card-title>
              <v-card-text class="px-0 pb-0 ads-primary-blue">
                <p>
                  <a
                    class="ads-primary-blue"
                    href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/supervising-performance-and-development/supporting-meaningful-conversations"
                    target="_blank"
                  >
                    <b>Supporting Meaningful Conversations</b>
                    <v-icon class="ml-2 ads-primary-blue">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </p>
                <p>
                  <a
                    class="ads-primary-blue"
                    href="https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/non-teaching-staff-performance-and-development/Meaningful-conversations-useful-approaches.docx"
                    target="_blank"
                  >
                    <b>
                      Meaningful conversations and feedback - useful approaches
                    </b>
                    <v-icon class="ml-2 ads-primary-blue">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </p>
                <p>
                  <a
                    class="ads-primary-blue"
                    href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/supervising-performance-and-development/supporting-non-teaching-staff-to-develop-their-goals"
                    target="_blank"
                  >
                    <b>Supporting staff to develop their goals</b>
                    <v-icon class="ml-2 ads-primary-blue">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </p>
              </v-card-text>
            </v-col>
            <v-col
              v-if="!isMobile"
              class="d-flex justify-end align-end image-col"
              cols="auto"
            >
              <v-img
                class="fill-height image-content"
                src="../../../public/gender-neutral-clipboard.svg"
                max-width="116px"
                max-height="115px"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="isMidYearSection"
      no-gutters
    >
      <v-col>
        <NTSReviewReadOnly
          class="mb-5"
          :pdp="pdp"
          :section="section"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="workGoals?.length || careerGoals?.length"
      class="py-0 my-0"
      no-gutters
    >
      <v-col class="py-0">
        <v-card class="mb-5">
          <v-row
            class="mb-6"
            no-gutters
          >
            <v-col cols="auto">
              <div class="px-5 px-md-10 pt-9">
                <h1
                  class="display-1 ads-grey-01"
                  v-dompurify-html:plaintext="
                    `${formatName(pdp?.owner)}'s Plan`
                  "
                />
                <p
                  class="mt-1 mb-6 body-1 ads-grey-02"
                  v-dompurify-html:plaintext="`Created: ${createdDate}`"
                />
                <h1 class="headline">Work Goals</h1>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-for="(goal, index) of workGoals"
            :key="`plan-read-only-goal-${goal?.id}`"
            no-gutters
          >
            <v-col class="my-0 pb-0">
              <div class="mb-0">
                <div
                  class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
                >
                  Goal {{ index + 1 }}: {{ goal.title }}
                </div>
                <div class="px-5 px-md-10 no-break-inside">
                  <p class="goal-section-title no-break-inside">
                    <v-icon class="mr-2 ads-blue-2">mdi-star-outline</v-icon>
                    Description
                  </p>
                  <div
                    v-if="!isIncompleteArchivedPdp || goal?.description"
                    class="goal-text mb-10 body-1 no-break-inside"
                    v-dompurify-html="goal?.description ?? ''"
                  />
                  <IncompleteFieldCard v-else />
                </div>
                <hr class="no-break-inside" />
                <div class="pb-5 px-5 px-md-10 no-break-inside">
                  <p class="goal-section-title no-break-inside">
                    <v-icon class="mr-2 ads-blue-2">mdi-calendar-edit</v-icon>
                    Strategies to achieve goals
                  </p>
                  <div
                    v-if="!isIncompleteArchivedPdp || goal?.strategies"
                    class="goal-text mb-5 body-1 no-break-inside"
                    v-dompurify-html="`${goal.strategies ?? ''}`"
                  />
                  <IncompleteFieldCard v-else />
                </div>
              </div>
            </v-col>
          </v-row>
          <hr class="py-5 no-break-inside" />
          <v-row no-gutters>
            <v-col class="my-0 pb-0">
              <div class="mb-0">
                <div class="pb-5 px-5 px-md-10 no-break-inside">
                  <p class="d-flex flex-row goal-section-title no-break-inside">
                    <v-icon class="mr-2 ads-blue-2">mdi-school-outline</v-icon>
                    Learning and development
                  </p>
                  <div
                    v-if="!isIncompleteArchivedPdp || section?.workLearningText"
                    class="goal-text body-1 no-break-inside"
                    v-dompurify-html="
                      section?.workLearningText ? section?.workLearningText : ''
                    "
                  />
                  <IncompleteFieldCard v-else />
                </div>
                <div class="px-5 px-md-10 no-break-inside">
                  <p class="mb-0 goal-section-title">
                    <v-icon class="mr-2 ads-blue-2">
                      mdi-notification-clear-all
                    </v-icon>
                    Professional Learning Themes
                  </p>
                  <div
                    v-if="
                      !isIncompleteArchivedPdp ||
                      currentWorkLearningThemes?.length > 0
                    "
                  >
                    <div
                      v-for="workLearningTheme in currentWorkLearningThemes"
                      :key="`workLearningTheme.${workLearningTheme.id}`"
                    >
                      <v-card
                        :outlined="false"
                        :flat="true"
                        class="dark-border px-5 px-md-0 pt-md-5"
                        v-if="workLearningTheme.markForDelete !== true"
                      >
                        <v-row
                          class="ma-0 flex-column flex-md-row"
                          no-gutters
                        >
                          <v-col class="px-0 px-md-3 no-break-inside">
                            <v-card-title class="ma-0 pa-0">
                              <p
                                class="subtitle-1"
                                v-dompurify-html:plaintext="
                                  workLearningTheme?.name
                                    ? workLearningTheme?.name
                                    : ''
                                "
                              />
                            </v-card-title>
                            <v-card-text class="ma-0 pa-0">
                              <p
                                class="mb-0 goal-text"
                                v-dompurify-html="
                                  workLearningTheme?.description
                                    ? workLearningTheme?.description
                                    : ''
                                "
                              />
                            </v-card-text>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                  </div>
                  <IncompleteFieldCard
                    v-else
                    class="mt-4"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            class="mb-6"
            no-gutters
          >
            <v-col
              class="pt-10"
              cols="auto"
            >
              <div class="px-5 px-md-10">
                <h1 class="headline">Career Goals (optional)</h1>
              </div>
            </v-col>
          </v-row>
          <div v-if="!isIncompleteArchivedPdp || careerGoals?.length">
            <v-row
              v-for="(goal, index) of careerGoals"
              :key="`plan-read-only-goal-${goal?.id}`"
              class="no-break-inside"
              no-gutters
            >
              <v-col class="my-0 pb-0 no-break-inside">
                <div class="mb-0">
                  <div
                    class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
                  >
                    Goal {{ index + 1 }}: {{ goal.title }}
                  </div>
                  <div class="px-5 px-md-10 no-break-inside">
                    <p class="goal-section-title">
                      <v-icon class="mr-2 ads-blue-2">mdi-star-outline</v-icon>
                      Description
                    </p>
                    <div
                      v-if="!isIncompleteArchivedPdp || goal?.description"
                      class="goal-text mb-10 body-1"
                      v-dompurify-html="
                        goal?.description ? goal?.description : ''
                      "
                    />
                    <IncompleteFieldCard v-else />
                  </div>
                  <hr class="no-break-inside" />
                  <div class="pb-5 px-5 px-md-10 no-break-inside">
                    <p class="goal-section-title">
                      <v-icon class="mr-2 ads-blue-2">mdi-calendar-edit</v-icon>
                      Strategies to achieve goals
                    </p>
                    <div
                      v-if="!isIncompleteArchivedPdp || goal?.strategies"
                      class="goal-text mb-5 body-1"
                      v-dompurify-html="goal.strategies ? goal.strategies : ''"
                    />
                    <IncompleteFieldCard v-else />
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <IncompleteFieldCard
            v-else
            class="mb-4 mx-5 mx-md-10 no-break-inside"
          />
          <hr
            v-if="careerGoals.length"
            class="py-5 no-break-inside"
          />
          <v-row
            class="no-break-inside"
            no-gutters
          >
            <v-col class="my-0 pb-0">
              <div class="mb-0 mb-5">
                <div class="pb-5 px-5 px-md-10 no-break-inside">
                  <p class="goal-section-title">
                    <v-icon class="mr-2 ads-blue-2">mdi-school-outline</v-icon>
                    Learning and development
                  </p>
                  <div
                    v-if="
                      !isIncompleteArchivedPdp || section?.careerLearningText
                    "
                    class="goal-text body-1"
                    v-dompurify-html="
                      section.careerLearningText
                        ? section.careerLearningText
                        : ''
                    "
                  />
                  <IncompleteFieldCard v-else />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="section?.notifySupervisor || isPdpArchived"
      class="mt-10"
      no-gutters
    >
      <v-col class="py-0">
        <EventLog
          class="px-5 px-md-10 py-8"
          :section="section"
          :owner="pdp?.owner"
          :supervisor="supervisor"
          :manager="manager"
          :principal="principal"
          :pdp="pdp"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDateTime, formatName } from '@/utils';
import { mapGetters } from 'vuex';
import NTSReviewReadOnly from '@/components/NTSReviewReadOnly';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import EventLog from '@/components/EventLog';
import { GOAL_TYPE, SECTION_TYPE, USER_TYPE } from '@/data/constants';

export default {
  name: 'NTSSectionReadOnlyPrint',
  components: { EventLog, NTSReviewReadOnly, IncompleteFieldCard },
  props: {
    pdp: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('lookups', ['learningThemes']),
    ...mapGetters(['loggedInUser']),
    isOwner() {
      return this.loggedInUser?.id === this.pdp?.owner?.id;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isPlanSection() {
      return this.section?.sectionType === SECTION_TYPE.plan;
    },
    isMidYearSection() {
      return this.section?.sectionType === SECTION_TYPE.midYear;
    },
    currentWorkLearningThemes() {
      return this.section?.section_learning_theme_associations?.map(
        (workLearningTheme) =>
          this.learningThemes?.find(
            (theme) => theme.id === workLearningTheme?.learningtheme_id
          )
      );
    },
    workGoals() {
      return this.section?.goals
        ?.filter((goal) => goal.type === GOAL_TYPE.work)
        .sort((a, b) => a.id - b.id);
    },
    careerGoals() {
      return this.section?.goals
        ?.filter((goal) => goal.type === GOAL_TYPE.career)
        .sort((a, b) => a.id - b.id);
    },
    createdDate() {
      return this.formatDateTime(this.section?.created_at);
    },
    supervisor() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.supervisor &&
          user.user_id === this.section?.supervisor_id
      )?.user;
    },
    manager() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.additionalSupervisor &&
          user.user_id === this.section?.manager_id
      )?.user;
    },
    principal() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.pdpPrincipal &&
          user.user_id === this.section?.principal_id
      )?.user;
    },
    isIncompleteArchivedPdp() {
      return !this.pdp?.active && !this.pdp?.finalCommentLocked;
    },
    isPdpArchived() {
      return !this.pdp?.active;
    },
  },
  methods: {
    formatName,
    formatDateTime,
  },
};
</script>

<style lang="scss" scoped>
.goal-heading {
  background-color: rgba(108, 172, 228, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;

  span {
    display: inline-block;
  }
}

hr {
  border: solid $ads-light-20;
  border-width: thin 0 0 0;
  margin: 16px 0 32px;
}

.dark-border {
  border-color: $ads-dark-80;
}

.fill-height {
  height: 100%;
}
.image-col {
  // overflow: hidden; /* Hides overflow */
  position: relative; /* Enable positioning of absolutely positioned image */
}
.image-content {
  position: absolute;
  width: auto; /* Adjust width as necessary */
}
:deep .v-card {
  &__title {
    font-size: 24px;
    line-height: 30px;
    word-break: break-word;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
  }
  .v-image {
    bottom: -14px;
  }
}
.goal-section-title {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
</style>
