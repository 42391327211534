<template>
  <div>
    <v-form
      ref="updateSchoolForm"
      v-model="isValid"
      lazy-validation
    >
      <div>
        <h3 class="title px-0 mb-5">Add your school or work location</h3>
      </div>
      <Alert
        class="mb-10"
        type="warning"
        inPage
        text="Action required"
        :subtext="subtext"
      ></Alert>
      <v-combobox
        v-model="ownerSchool"
        :items="searchedSchoolsList"
        :loading="false"
        :rules="[rules.schoolFieldRequired]"
        :search-input.sync="searchOwnerSchool"
        clearable
        item-text="schoolFullName"
        item-value="id"
        label="Your school or work location"
        outlined
        placeholder="Type here"
        return-object
        persistent-placeholder
      />
      <div class="mb-8">
        <p>
          <b>Note:</b>
          As the school leader, your principal can view all the PDPs in the
          school
        </p>
      </div>
      <div class="d-flex flex-row-reverse">
        <AdsButton
          buttonText="Proceed"
          :disabled="!ownerSchool?.id"
          class="ml-auto"
          type="submit"
          icon="mdi-arrow-right"
          @click.prevent="proceed"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Alert, AdsButton } from '@nswdoe/doe-ui-core';
import validators from '@/lib/validators';
import { equals } from 'ramda';
import { debounce } from 'lodash';
import { PDP_TYPE } from '@/data/constants';

export default {
  name: 'UpdateSchoolModal',
  components: { AdsButton, Alert },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    closeDialog: false,
    isValid: true,
    ownerSchool: null,
    searchOwnerSchool: null,
    rules: {
      schoolFieldRequired: validators.schoolFieldRequired,
    },
    subtext:
      'To comply with policy requirements and prepare your 2023 Digital PDP for archiving, add your school. If your work location is not a school, type "education office". If you have started your 2024 PDP, add your details in preparation for archiving at the end of 2024.',
  }),
  watch: {
    searchOwnerSchool(newValue, oldValue) {
      if (
        newValue &&
        newValue.trim().length > 1 &&
        !equals(newValue, oldValue) &&
        oldValue
      ) {
        this.getSchools(newValue);
      }
    },
    isPdpLoaded: {
      handler() {
        if (this.isPdpLoaded) {
          this.ownerSchool = this.formPdp?.owner_school;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['formPdp', 'isPdpLoaded']),
    ...mapGetters('pdp', ['formPdpFinalSection']),
    ...mapState({
      searchedSchoolsList: (state) => state.school.schoolsByFullName,
    }),
  },
  methods: {
    ...mapActions('school', ['searchSchoolsByFullName']),
    async getSchools(value) {
      const debouncedSearch = debounce(async () => {
        await this.searchSchoolsByFullName(value);
      }, 150);
      await debouncedSearch();
    },
    proceed() {
      const validated = this.$refs.updateSchoolForm.validate();
      if (validated) {
        this.formPdp.owner_school_id = this.ownerSchool.id;
        if (
          this.formPdpFinalSection.complete &&
          (this.formPdp.type === PDP_TYPE.ts ||
            (this.formPdp.type === PDP_TYPE.nts &&
              this.formPdp.finalCommentLocked))
        ) {
          this.formPdp.active = false;
          this.$emit('archive');
        } else {
          this.$emit('save');
        }
      }
    },
  },
};
</script>
