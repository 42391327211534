import { School, User } from '../../../lib/schema';
import { extractStringFromHtml } from '../../../lib/utils';

const learningThemeRequired = (
  value: [{ id: number; text: string; title: string } | []]
) => {
  return value.length > 0 || 'One professional theme must be selected at least';
};

const userFieldRequired = (
  value: User | null,
  errorMessage = 'Please select from the list'
): boolean | string => {
  return (
    (!!value &&
      !!value.id &&
      !!value.staffDoEEmailAddress &&
      !!value.staffDoEEmailAddress.trim()) ||
    errorMessage
  );
};

const schoolFieldRequired = (
  value: School | null,
  errorMessage = 'Please select from the list'
): boolean | string => {
  return (
    (!!value && !!value.id && !!value.schoolCode && !!value.schoolFullName) ||
    errorMessage
  );
};

const emptyOrUserFromListRequired = (
  user: User | null,
  errorMessage = 'Please select from the list'
): boolean | string => {
  return (
    !user ||
    (!!user &&
      !!user.id &&
      !!user.staffDoEEmailAddress &&
      !!user.staffDoEEmailAddress.trim()) ||
    errorMessage
  );
};

const stringFieldRequired = (value: string | null): boolean | string => {
  const extractedValue = extractStringFromHtml(value);
  if (!extractedValue || extractedValue?.trim().length < 1) {
    return 'This field is required';
  } else return true;
};

const stringLengthRequired = (minLength: number) => {
  return (value: string | null): boolean | string => {
    const extractedValue = extractStringFromHtml(value);
    if (!extractedValue || extractedValue.trim().length < minLength) {
      const s = minLength === 1 ? '' : 's';
      return `Please enter ${minLength} character${s} or more (${
        extractedValue ? extractedValue.trim().length : 0
      })`;
    } else return true;
  };
};

const maxStringLength = (max: number, text?: string): string | undefined => {
  let displayMessage;
  if (text?.length === max) {
    displayMessage = `The maximum of ${max} characters has been reached. Please refine your response.`;
  }
  return displayMessage;
};

const maxStringLengthRule = (max: number) => {
  return (value: string | null): boolean | string => {
    const extractedValue = extractStringFromHtml(value);
    if (extractedValue) {
      if (extractedValue.trim().length <= max) {
        return true;
      } else {
        return `The maximum is ${max} characters. Please refine your response`;
      }
    } else return true;
  };
};

export default {
  userFieldRequired,
  stringFieldRequired,
  stringLengthRequired,
  emptyOrUserFromListRequired,
  learningThemeRequired,
  maxStringLength,
  maxStringLengthRule,
  schoolFieldRequired,
};
