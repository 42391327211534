<template>
  <Wrapper
    class=""
    cardClass="my-8 mx-md-10 pa-0 pt-md-9 pb-md-7"
    :isMobile="isMobile"
  >
    <h1 class="pl-6 display-1 ads-grey-01">Start a new PDP</h1>
    <p class="pl-6 body-1 mt-4 ads-grey-01">
      Complete the steps below to begin your new PDP
    </p>
    <v-row
      v-if="currentStep === 1"
      class="px-6"
      no-gutters
    >
      <v-col cols="7">
        <v-card
          class="px-6 rounded-12"
          color="primary-teal"
          elevation="0"
        >
          <v-card-text class="px-0 body-1 grey-01--text">
            If your
            <b>PDP Supervisor/Additional Supervisor/PDP Principal</b>
            is not appearing in the list below, ask them to access the Digital
            PDP using their own credentials
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-stepper
      v-model="currentStep"
      vertical
      flat
    >
      <template v-for="item in formSteps">
        <v-stepper-step
          :key="`stepper-step${item.step}`"
          :color="currentStep > item.step ? 'success-green' : 'primary'"
          :complete="item.isDone"
          :editable="currentStep > item.step"
          :rules="[() => item.isValid !== false]"
          :step="item.step"
          complete-icon="$complete"
          edit-icon="$complete"
          error-icon="mdi-alert-circle-outline"
        >
          <div class="d-flex justify-space-between align-center">
            <span>{{ item.title }}</span>
            <AdsButton
              v-if="
                (currentStep === 2 && item.step === 2) ||
                (currentStep === 3 && item.step === 3)
              "
              buttonText="Skip this step"
              tertiary
              :disabled="disableSkipButton"
              icon="mdi-fast-forward-outline"
              @click="nextStep(item)"
            ></AdsButton>
          </div>
        </v-stepper-step>
        <v-stepper-content
          :key="`step-${item.step}-content`"
          :step="item.step"
        >
          <v-form
            :ref="'stepForm'"
            v-model="item.isValid"
            lazy-validation
          >
            <NTSPdpSupervisor
              v-if="item.step === 1"
              @proceed="nextStep(item)"
            />
            <NTSGoals
              v-else-if="item.step === 2"
              :section="planSection"
              :editable="true"
              :type="GOAL_TYPE.work"
              class="mx-0 py-0 mt-4 mt-sm-0"
              @updateStepValidity="updateStepValidity"
              @validated="validateGoals"
              @proceed="nextStep(item)"
              @toggle-skip-button="toggleSkipButton"
              @goalsAltered="handleAddNewGoal"
            />
            <NTSGoals
              v-else-if="item.step === 3"
              :section="planSection"
              :type="GOAL_TYPE.career"
              class="mx-0 py-0 mt-4 mt-sm-0"
              @proceed="nextStep(item)"
              @validated="validateGoals"
              @toggle-skip-button="toggleSkipButton"
              @goalsAltered="handleAddNewGoal"
            />
            <v-card
              v-else-if="item.step === 4"
              class="my-1 ml-1 mr-16 py-8 pl-6 pr-16"
            >
              <p class="body-1 ads-grey-01">
                Confirm you are ready to make your PDP available to:
              </p>
              <div
                v-for="pdpUser in pdpUsers"
                :key="pdpUser.id"
                class="d-flex mb-7"
              >
                <InitialsIcon
                  class="mr-md-4"
                  :givenName="pdpUser.displayedName"
                  size="42px"
                ></InitialsIcon>
                <div class="ads-grey-01">
                  <div>
                    <strong>{{ pdpUser.displayedName }}</strong>
                  </div>
                  <div>{{ pdpUser.pdpRole }}</div>
                </div>
              </div>
              <p class="mb-8 body-1 ads-grey-01">
                You will still be able to make changes to your plan after it has
                been submitted. Once your PDP has been signed off by both you
                and your PDP supervisor, you can proceed to the
                <strong>Implement</strong>
                phase.
              </p>
              <AdsButton
                buttonText="Notify supervisor"
                icon="mdi-arrow-right"
                type="submit"
                @click.prevent="nextStep(item)"
              />
            </v-card>
          </v-form>
        </v-stepper-content>
      </template>
    </v-stepper>
  </Wrapper>
</template>

<script>
import NTSPdpSupervisor from '@/components/NTSPdpSupervisor.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import NTSGoals from '@/components/NTSGoals.vue';
import { InitialsIcon, AdsButton } from '@nswdoe/doe-ui-core';
import Wrapper from '@/components/Wrapper.vue';
import { getDisplayUsers } from '@/utils';
import { GOAL_TYPE, SECTION_TYPE, USER_TYPE } from '@/data/constants';
import { findPdpUserByType, setNTSNotifySupervisor } from '@/lib/pdp';

export default {
  name: 'NTSStepper',
  components: {
    NTSPdpSupervisor,
    NTSGoals,
    InitialsIcon,
    AdsButton,
    Wrapper,
  },
  props: {
    showStepper: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      GOAL_TYPE,
      currentStep: 1,
      disableSkipButton: true,
      formSteps: [
        {
          step: 1,
          component: 'NTSPdpSupervisor',
          title: 'Your PDP details',
          isValid: true,
          isDone: false,
          isLastStep: false,
        },
        {
          step: 2,
          component: 'NTSGoals',
          title: 'Create work goals',
          isValid: true,
          isDone: false,
          isLastStep: false,
        },
        {
          step: 3,
          component: 'Career Goals',
          title: 'Career goals (optional)',
          isValid: true,
          isDone: false,
          isLastStep: false,
        },
        {
          step: 4,
          component: 'Complete',
          title: 'Notify supervisor',
          isValid: true,
          isDone: false,
          isLastStep: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp', 'isPdpLoaded']),
    ...mapGetters('formValidation', ['allGoalsValid', 'validationGoals']),
    ...mapGetters('pdp', { planSection: 'formPdpPlanSection' }),
    pdpSupervisor: {
      get() {
        return {
          supervisor: this.formPdp?.supervisor,
          supervisorLocationName: this.formPdp?.supervisorLocationName,
        };
      },
      set(newValue) {
        this.inputSupervisor = newValue;
      },
    },
    currentSupervisor() {
      return findPdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.supervisor
      );
    },
    currentAdditionalSupervisor() {
      return findPdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.additionalSupervisor
      );
    },
    pdpUsers() {
      return getDisplayUsers(this.pdp?.pdp_user_associations);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    showStepper: {
      handler() {
        if (this.showStepper && this.formPdp && this.isPdpLoaded) {
          this.setCurrentStep();
        }
      },
      immediate: true,
    },
    allGoalsValid: {
      handler() {
        this.formSteps[1].isValid = this.allGoalsValid;
      },
    },
  },
  methods: {
    ...mapActions('pdp', ['savePdp']),
    ...mapMutations('formValidation', ['resetGoalsValidity']),
    updateStepValidity(payload) {
      this.formSteps[payload.step - 1].isValid = payload.value;
    },
    validateGoals(val) {
      this.formSteps[1].isValid = val;
    },
    setCurrentStep() {
      let currentStep = 1;
      if (
        this.currentSupervisor?.school_id &&
        this.currentSupervisor?.position &&
        this.formPdp.owner_school_id &&
        this.formPdp.userPosition &&
        (!this.currentAdditionalSupervisor ||
          (this.currentAdditionalSupervisor?.id &&
            this.currentAdditionalSupervisor?.position &&
            this.currentAdditionalSupervisor?.school_id))
      ) {
        this.formSteps[0].isDone = true;
        currentStep = 2;
      }
      this.currentStep = currentStep;
    },
    validateStep(step) {
      const validated = this.$refs.stepForm[step - 1].validate();
      this.formSteps[step - 1].isValid = validated;
      return validated;
    },
    async nextStep(item) {
      const validated = this.validateStep(item.step);
      if (validated) {
        if (item.step === 1) {
          item.isDone = true;
        }
        if (item.step === 2) {
          item.isDone = true;
        }
        if (item.step === 3) {
          item.isDone = true;
        }
        if (item.step === 4) {
          this.handleNotifySupervisor();
          this.resetGoalsValidity();
        }
        await this.savePdp(this.formPdp);
        this.currentStep = item.step < 4 ? item.step + 1 : item.step;
      }
    },
    handleNotifySupervisor() {
      this.formPdp.sections = setNTSNotifySupervisor(
        SECTION_TYPE.plan,
        this.formPdp
      );
    },
    async handleAddNewGoal() {
      await this.savePdp(this.formPdp);
    },
    toggleSkipButton(value) {
      this.disableSkipButton = value;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .v-stepper.v-stepper--vertical {
  .v-stepper__step {
    .v-stepper__label {
      color: $ads-grey-01;
      font-family: Public Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
    }
    &--active .v-stepper__label {
      font-family: Arial;
      font-size: 19px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .v-stepper__step__step {
    width: 32px;
    height: 32px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    .v-icon {
      font-size: 32px;

      &::before {
        font-size: 24px;
      }

      &.mdi-alert-circle-outline::before {
        font-size: 32px !important;
      }
    }
  }
  .v-stepper__content {
    margin-left: 40px;
  }
  // v-form cuts off input field's top label directly below it
  .v-form > div:first-child {
    padding-top: 3px;
  }
}

.custom-h1 {
  font-size: 1.25rem !important;
  line-height: 1.46875rem !important;
}

.rounded-12 {
  border-radius: 12px;
}

@media screen and (max-width: 601px) {
  :deep .v-stepper {
    &__step {
      padding: 14px 0 12px;
      margin-top: 5px;

      &--active + .v-stepper__content {
        border-left: 0 !important;
        padding-top: 0 !important;
      }

      .v-stepper__step__step {
        width: 32px;
        height: 32px;
      }
    }

    .v-stepper__content {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;

      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .v-expansion-panel-content {
      .v-divider {
        max-width: none;
        margin-left: -20px;
        margin-right: -20px;
      }

      strong.title {
        font-size: 16px;
      }
    }
  }
  :deep :not(.v-stepper__step--active) + .v-stepper__content {
    margin-left: 36px;
    padding-top: 16px;
    padding-bottom: 8px;
  }
}
</style>
