export const sipOptions = [
  {
    id: 0,
    text: 'Strategic Direction - Student growth and attainment',
  },
  {
    id: 1,
    text: 'Strategic Direction - Other',
  },
  {
    id: 2,
    text: 'Not applicable',
  },
];
