import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import MyPdp from '../views/MyPdp/index.vue';
import PdpGuide from '../views/PdpGuide/index.vue';
import Management from '@/views/Management/index.vue';
import NotFound from '@/views/NotFound/index.vue';
import NotAuthorized from '@/views/NotAuthorized/index.vue';
import SupervisorAction from '@/views/SupervisorAction/index.vue';
import Help from '@/views/Help/index.vue';
import UsefulLinks from '@/views/UsefulLinks/index.vue';
import store from '@/store/index';
import {
  PDP_ROUTES,
  QPLUS_ROUTES,
  TAB_TYPES,
  PDP_TYPE,
} from '@/data/constants';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: PDP_ROUTES.myPdp.base.path,
    name: PDP_ROUTES.myPdp.base.name,
    // When application is down display Outage view
    // component: Outage,
    component: MyPdp,
    meta: { protected: true },
    props: false,
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }

      if (!store.state?.lookups?.learningThemes) {
        await store.dispatch('lookups/setLookups');
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.myPdp.pdpId.path,
    name: PDP_ROUTES.myPdp.pdpId.name,
    component: MyPdp,
    props: true,
    meta: { protected: true },
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      if (!store.state?.lookups?.learningThemes) {
        await store.dispatch('lookups/setLookups');
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.myPdp.selectedTab.path,
    name: PDP_ROUTES.myPdp.selectedTab.name,
    component: MyPdp,
    props: true,
    meta: { protected: true },
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      if (!store.state?.lookups?.learningThemes) {
        await store.dispatch('lookups/setLookups');
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.management.base.path,
    name: PDP_ROUTES.management.base.name,
    // component: Management,
    meta: { protected: true },
    redirect: {
      path: PDP_ROUTES.management.selectedTab.path,
      name: PDP_ROUTES.management.selectedTab.name,
      params: { selectedTab: TAB_TYPES.tasks.id },
    },
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.management.selectedTab.path,
    name: PDP_ROUTES.management.selectedTab.name,
    // When application is down display Outage view
    // component: Outage,
    component: Management,
    props: true,
    meta: { protected: true },
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.management.pdpId.path,
    name: PDP_ROUTES.management.pdpId.name,
    component: SupervisorAction,
    props: true,
    meta: { protected: true },
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      if (!store.state?.lookups?.learningThemes) {
        await store.dispatch('lookups/setLookups');
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.management.actionTab.path,
    name: PDP_ROUTES.management.actionTab.name,
    component: SupervisorAction,
    props: true,
    meta: { protected: true },
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      if (!store.state?.lookups?.learningThemes) {
        await store.dispatch('lookups/setLookups');
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.pdpGuide.base.path,
    name: PDP_ROUTES.pdpGuide.base.name,
    component: PdpGuide,
    meta: { protected: true },
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.login.base.path,
    name: PDP_ROUTES.login.base.name,
    meta: { protected: true },
    async beforeEnter(to, from, next) {
      if (
        from.path === PDP_ROUTES.login.base.path &&
        to.path === PDP_ROUTES.login.base.path
      ) {
        //this logic had to be moved from App.vue to enable the use of guards that allow navigation from '/' to routes other than /management and /my-pdp
        if (!store.state.loggedInUser) {
          await store.dispatch('setLoggedInUser');
        }
        if (
          store.state.loggedInUser?.isSupervisor ||
          store.state.loggedInUser?.isPrincipal
        ) {
          router.push('/management');
        } else {
          router.push('/my-pdp');
        }
      }
      next();
    },
  },
  {
    path: PDP_ROUTES.notFound.base.path,
    name: PDP_ROUTES.notFound.base.name,
    component: NotFound,
    meta: { protected: true },
  },
  {
    path: PDP_ROUTES.notAuthorized.base.path,
    name: PDP_ROUTES.notAuthorized.base.name,
    component: NotAuthorized,
    meta: { protected: true },
  },
  {
    path: PDP_ROUTES.usefulLinks.base.path,
    name: PDP_ROUTES.usefulLinks.base.name,
    meta: { protected: true },
    component: UsefulLinks,
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      if (
        store.state.loggedInUser?.isNonTeachingStaff ||
        store.state.loggedInUser?.supervised_pdp_types?.includes(PDP_TYPE.nts)
      ) {
        next();
      } else {
        next('/404');
      }
    },
  },
  {
    path: PDP_ROUTES.supportFeedback.base.path,
    meta: { protected: true },
    component: Help,
    async beforeEnter(to, from, next) {
      if (!store.state.loggedInUser) {
        await store.dispatch('setLoggedInUser');
      }
      if (store.state.loggedInUser?.isTeachingStaff) {
        window.location.href = `${process.env.VUE_APP_QPLUS_URL}${PDP_ROUTES.supportFeedback.base.path}`;
      } else {
        next();
      }
    },
  },
  {
    path: QPLUS_ROUTES.status.base.path,
    meta: { protected: true },
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_QPLUS_URL}`;
    },
  },
  {
    path: QPLUS_ROUTES.fullDetails.base.path,
    meta: { protected: true },
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_QPLUS_URL}${QPLUS_ROUTES.fullDetails.base.path}`;
    },
  },
  {
    path: QPLUS_ROUTES.tasks.base.path,
    meta: { protected: true },
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_QPLUS_URL}${QPLUS_ROUTES.tasks.base.path}`;
    },
  },
  {
    path: QPLUS_ROUTES.resources.base.path,
    meta: { protected: true },
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_QPLUS_URL}${QPLUS_ROUTES.resources.base.path}`;
    },
  },
  {
    path: QPLUS_ROUTES.documentsAndLinks.base.path,
    meta: { protected: true },
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_QPLUS_URL}${QPLUS_ROUTES.documentsAndLinks.base.path}`;
    },
  },
  {
    path: QPLUS_ROUTES.stayConnected.base.path,
    meta: { protected: true },
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_QPLUS_URL}${QPLUS_ROUTES.stayConnected.base.path}`;
    },
  },
];

export const router = new VueRouter({
  mode: 'history',
  routes,
});
