<template>
  <v-card outlined>
    <v-card-text class="pa-10">
      <p
        class="mb-0 body-1"
        color="#000"
      >
        This section is not visible with current permissions
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NoPermissionCard',
};
</script>

<style scoped lang="scss">
.v-card {
  border-color: $ads-grey-03;
}
</style>
