import { Employment } from "./schema";

export const isNumberInStringArray = (
  numberString: string | null,
  id: number
) => {
  try {
    let pastSupervisorsIntArray: number[] = [];
    if (numberString) {
      try {
        pastSupervisorsIntArray = JSON.parse(numberString).map((item: string) =>
          parseInt(item.trim())
        );
      } catch (err) {
        pastSupervisorsIntArray = numberString
          .split(",")
          .map((x: string) => parseInt(x.trim()));
      }
    }
    return pastSupervisorsIntArray.some((item) => item === id);
  } catch (err) {
    throw new Error("Wrong string format");
  }
};

export const isPrincipalInSameSchool = (
  employment: Employment[],
  location: string,
  userIsPrincipal: boolean
) => {
  return (
    employment.some((item) => item.staffSchoolCode === location) &&
    userIsPrincipal
  );
};

export const extractStringFromHtml = (html: string | null) => {
  return trimHtml(html)?.replace(/<[^>]+>/g, "");
};

export const trimHtml = (html: string | null) => {
  return html?.replace(/(<p>(\s*|<br>)<\/p>)*$/g, "");
};

export const isBoolInSet = (items: boolean[], predicate: boolean) => {
  const boolSet = new Set(items);
  return boolSet.has(predicate);
};
