<template>
  <TSActionPdp
    v-if="isTS"
    :selectedTab="selectedTab"
    :actionTab="actionTab"
    :showExpiredPdpDialog="showExpiredPdpDialog"
    @handleRefresh="handleRefresh"
    @selected="pdpSelected"
    @notesOpen="setNotesOpen"
  />
  <NTSActionPdp
    v-else-if="isNTS"
    :selectedTab="selectedTab"
    :actionTab="actionTab"
    :showExpiredPdpDialog="showExpiredPdpDialog"
    @handleRefresh="handleRefresh"
    @selected="pdpSelected"
    @notesOpen="setNotesOpen"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TSActionPdp from '@/views/SupervisorAction/TSActionPdp.vue';
import NTSActionPdp from '@/views/SupervisorAction/NTSActionPdp.vue';
import { PDP_TYPE, PDP_ROUTES, USER_TYPE } from '@/data/constants';
import { findPdpUserByType } from '@/lib/pdp';

export default {
  name: 'SupervisorAction',
  components: {
    TSActionPdp,
    NTSActionPdp,
  },
  data() {
    return {
      refresh: false,
      showExpiredPdpDialog: false,
      isActive: true,
      ownedPdpLoaded: false,
      notesOpen: false,
    };
  },
  props: {
    pdpId: {
      type: String,
      default: '',
    },
    selectedTab: {
      type: String,
      default: '',
    },
    actionTab: {
      type: String,
      default: '',
    },
    inFocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'actionPdp',
      'isPdpUpdated',
      'isPdpLoaded',
      'isLoading',
      'config',
    ]),
    ...mapGetters('pdp', [
      'intervalId',
      'actionPdpPlanSection',
      'actionPdpMidYearSection',
      'actionPdpFinalSection',
    ]),
    isTS() {
      return this.actionPdp?.type === PDP_TYPE.ts;
    },
    isNTS() {
      return this.actionPdp?.type === PDP_TYPE.nts;
    },
    paramSelectedTab() {
      return this.$route.params.selectedTab;
    },
    isAdditionalSupervisor() {
      return (
        findPdpUserByType(
          this.actionPdp?.pdp_user_associations,
          USER_TYPE.additionalSupervisor
        )?.user_id === this.loggedInUser?.id
      );
    },
    isSupervisor() {
      return (
        findPdpUserByType(
          this.actionPdp?.pdp_user_associations,
          USER_TYPE.supervisor
        )?.user_id === this.loggedInUser?.id
      );
    },
    isPdpPrincipal() {
      return (
        findPdpUserByType(
          this.actionPdp?.pdp_user_associations,
          USER_TYPE.pdpPrincipal
        )?.user_id === this.loggedInUser?.id
      );
    },
    tsNotifyState() {
      switch (true) {
        case this.isSupervisor:
          return this.actionPdp?.sections.some(
            (section) =>
              section.notifySupervisor &&
              !section.supervisorSignature &&
              !section.complete
          );
        default:
          return false;
      }
    },
    ntsNotifyState() {
      switch (true) {
        case (!this.isSupervisor && !this.isPdpPrincipal) ||
          this.isAdditionalSupervisor:
          return false;
        case this.isSupervisor &&
          this.actionPdpFinalSection?.notifySupervisor &&
          !this.actionPdpFinalSection?.supervisorSignature:
          return true;
        case this.isSupervisor &&
          this.actionPdpMidYearSection?.notifySupervisor &&
          !this.actionPdpMidYearSection?.supervisorSignature:
          return true;
        case this.isSupervisor &&
          this.actionPdpPlanSection?.notifySupervisor &&
          !this.actionPdpPlanSection?.supervisorSignature:
          return true;
        case this.isPdpPrincipal &&
          this.actionPdpFinalSection?.notifySupervisor &&
          this.actionPdpFinalSection?.supervisorSignature &&
          this.actionPdpFinalSection?.userSignature &&
          !this.actionPdpFinalSection?.principalSignature:
          return true;
        case this.isPdpPrincipal &&
          this.actionPdpMidYearSection?.notifySupervisor &&
          this.actionPdpMidYearSection?.supervisorSignature &&
          this.actionPdpMidYearSection?.userSignature &&
          !this.actionPdpMidYearSection?.principalSignature:
          return true;
        case this.isPdpPrincipal &&
          this.actionPdpPlanSection?.notifySupervisor &&
          this.actionPdpPlanSection?.supervisorSignature &&
          this.actionPdpPlanSection?.userSignature &&
          !this.actionPdpPlanSection?.principalSignature:
          return true;
        default:
          return false;
      }
    },
    notifyState() {
      return (
        (this.isTS && this.tsNotifyState) || (this.isNTS && this.ntsNotifyState)
      );
    },
  },
  watch: {
    pdpId: {
      async handler() {
        if (this.loggedInUser && this.pdpId) {
          await this.getActionPdp(parseInt(this.pdpId));
          if (this.loggedInUser.id === this.actionPdp?.owner?.id) {
            await this.$router.push(`/my-pdp/${this.pdpId}`);
          } else if (this.isPdpLoaded && !this.actionPdp) {
            await this.$router.push('/not-authorized');
          } else if (this.actionPdp?.user_id && !this.ownedPdpLoaded) {
            await this.getOwnedPdp(this.actionPdp.user_id);
            this.ownedPdpLoaded = true;
          }
        }
      },
      immediate: true,
    },
    refresh: {
      async handler() {
        if (this.refresh && this.loggedInUser && this.pdpId) {
          this.stopInterval();
          await this.getActionPdp(parseInt(this.pdpId));
          this.showExpiredPdpDialog = false;
          this.refresh = false;
        }
        if (this.actionPdp?.user_id) {
          this.ownedPdpLoaded = false;
          await this.getOwnedPdp(this.actionPdp.user_id);
          this.ownedPdpLoaded = true;
        }
      },
      immediate: true,
    },
    isPdpUpdated: {
      handler() {
        if (this.isPdpUpdated && this.actionPdp && !this.isLoading) {
          this.showExpiredPdpDialog = true;
          this.stopInterval();
        }
      },
      immediate: true,
    },
    isPdpLoaded: {
      handler() {
        if (this.isPdpLoaded && this.inFocus && !this.intervalId) {
          // console.log(
          //   new Date().toLocaleTimeString(),
          //   'Supervisee Pdp first loaded and in focus'
          // );
          this.updateIntervalState();
        }
      },
    },
    inFocus: {
      handler() {
        if (this.isPdpUpdated) {
          this.showExpiredPdpDialog = true;
          this.stopInterval();
        }
        if (this.isPdpLoaded && !this.inFocus && this.intervalId) {
          // console.log(
          //   new Date().toLocaleTimeString(),
          //   'Supervisee Pdp loaded and not in focus'
          // );
          this.updateIntervalState();
        } else if (this.isPdpLoaded && this.inFocus && !this.intervalId) {
          // console.log(
          //   new Date().toLocaleTimeString(),
          //   'Supervisee Pdp back in focus'
          // );
          this.updateIntervalState();
        }
      },
      immediate: true,
    },
    notifyState: {
      handler() {
        if (this.notifyState) {
          // console.log(
          //   new Date().toLocaleTimeString(),
          //   'Supervisee Pdp loaded and in notify state'
          // );
        }
        this.updateIntervalState();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pdp', [
      'getActionPdp',
      'getOwnedPdp',
      'resetOwnedPdp',
      'saveActionPdp',
      'startInterval',
      'stopInterval',
    ]),
    handleRefresh() {
      this.refresh = true;
    },
    async pdpSelected(id) {
      if (id && parseInt(this.pdpId) !== id) {
        await this.$router.push({
          name: PDP_ROUTES.management.pdpId.name,
          params: {
            selectedTab: this.selectedTab,
            pdpId: `${id}`,
          },
        });
      }
    },
    setNotesOpen(value) {
      this.notesOpen = value;
      // console.log(
      //   new Date().toLocaleTimeString(),
      //   this.notesOpen ? 'Personal notes open' : 'Personal notes closed'
      // );
      this.updateIntervalState();
    },
    updateIntervalState() {
      const correctRoute =
        this.$route.name === PDP_ROUTES.management.pdpId.name ||
        this.$route.name === PDP_ROUTES.management.actionTab.name;
      if (
        this.inFocus &&
        !this.intervalId &&
        correctRoute &&
        this.actionPdp?.id &&
        this.actionPdp?.active &&
        this.notifyState &&
        !this.notesOpen
      ) {
        // console.log(
        //   new Date().toLocaleTimeString(),
        //   'Supervisee PDP is active - starting interval check'
        // );
        this.startInterval({
          pdpId: this.actionPdp.id,
          updated_at: this.actionPdp.updated_at,
        });
      } else if (!this.inFocus && this.intervalId && correctRoute) {
        // console.log(
        //   new Date().toLocaleTimeString(),
        //   'Supervisee PDP is inactive - stopping interval check'
        // );
        this.stopInterval();
      } else if (
        this.intervalId &&
        (!this.actionPdp?.id ||
          !this.actionPdp?.active ||
          !this.notifyState ||
          this.notesOpen)
      ) {
        // console.log(
        //   new Date().toLocaleTimeString(),
        //   'Supervisee PDP is not loaded, or not in notify state, or archived - stopping interval check'
        // );
        this.stopInterval();
      }
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      this.stopInterval();
    }
    this.resetOwnedPdp();
    this.ownedPdpLoaded = false;
  },
};
</script>
