/**
 * This is the entry point for all API requests.
 * import API modules and expose them to components via Vue.prototype.$api
 */
import user from './modules/user';
import school from './modules/school';
import pdp from './modules/pdp';
import lookups from '@/api/modules/lookups';
import refreshRate from './modules/refreshRate';

export default {
  school,
  user,
  pdp,
  lookups,
  refreshRate,
};
