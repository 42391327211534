export const maxAccountabilities = 10;
export const areasOfPractice = [
  {
    id: 0,
    index: 1,
    text: 'Leading teaching and learning',
    accountabilities: [
      {
        id: 0,
        index: 1,
        text: 'Have high expectations and a sustained focus on improving student development, progress and achievement by seeking, reflecting and acting on evidence-informed leadership and pedagogical practices to meet the diverse learning needs of all students.',
      },
      {
        id: 1,
        index: 2,
        text: 'Are responsible for developing a culture of effective teaching and ongoing professional growth as the leading learner in the school.',
      },
      {
        id: 2,
        index: 3,
        text: 'Lead the implementation of curriculum and assessment through collaborative planning, monitoring and review of the effectiveness of teaching and learning programs and the assessment and reporting of student achievement.',
      },
      {
        id: 3,
        index: 4,
        text: 'Encourage active student engagement by sustaining high standards and expectations of behaviour and attendance.',
      },
    ],
  },
  {
    id: 1,
    index: 2,
    text: 'Developing self and others',
    accountabilities: [
      {
        id: 0,
        index: 1,
        text: 'Demonstrate personal and interpersonal qualities that influence and impact relationships.',
      },
      {
        id: 1,
        index: 2,
        text: 'Are committed to their own professional development and wellbeing in order to enhance their resilience and manage the complexity of the role.',
      },
      {
        id: 2,
        index: 3,
        text: 'Demonstrate high standards for staff performance and respond to behaviours and performance that may require change. They effectively and respectfully manage staff performance, improvement and conduct.',
      },
      {
        id: 3,
        index: 4,
        text: 'Lead whole-school evidence-informed professional learning that has a positive impact on staff performance and development, and therefore student progress and achievement.',
      },
      {
        id: 4,
        index: 5,
        text: 'Foster a culture of effective feedback by leading colleagues to plan, implement and review their practice, guided by the Australian Professional Standards for Teachers and Role Descriptions.',
      },
      {
        id: 5,
        index: 6,
        text: 'Develop leadership capacity by identifying, inspiring and enabling potential leaders.',
      },
    ],
  },
  {
    id: 2,
    index: 3,
    text: 'Leading improvement, innovation and change',
    accountabilities: [
      {
        id: 0,
        index: 1,
        text: 'Embed a collaborative, consultative culture of continuous improvement which is research-based and evidence-informed, and promote evaluative thinking.',
      },
      {
        id: 1,
        index: 2,
        text: 'Lead and manage improvement, innovation and change strategically through planning, implementation and evaluation processes.',
      },
      {
        id: 2,
        index: 3,
        text: 'Consult widely with their school community to cultivate a shared vision.',
      },
      {
        id: 3,
        index: 4,
        text: 'Have high level written and oral communication skills.',
      },
    ],
  },
  {
    id: 3,
    index: 1,
    text: 'Leading the management of the school',
    accountabilities: [
      {
        id: 0,
        index: 1,
        text: 'Uphold policy and processes as they relate to the protection, safety and wellbeing of children in our schools.',
      },
      {
        id: 1,
        index: 2,
        text: 'Optimise resource allocation, including targeted budgeting and expenditure management to achieve equity.',
      },
      {
        id: 2,
        index: 3,
        text: 'Delegate tasks to other staff and monitor accountabilities.',
      },
      {
        id: 3,
        index: 4,
        text: 'Establish a positive, collaborative workplace culture in order to maintain an inclusive, effective and safe working and learning environment.',
      },
      {
        id: 4,
        index: 5,
        text: 'Are the senior decision-makers in the school, utilising consultative practices including with students, staff, parents and the wider community.',
      },
    ],
  },
  {
    id: 4,
    index: 1,
    text: 'Engaging and working with the community',
    accountabilities: [
      {
        id: 0,
        index: 1,
        text: 'Value and develop effective partnerships that enable a positive environment where all students thrive.',
      },
      {
        id: 1,
        index: 2,
        text: 'Foster an understanding of and respect for Aboriginal peoples, histories, culture and languages.',
      },
      {
        id: 2,
        index: 3,
        text: 'Lead the coordinated support and implementation of strategies for students and their families as appropriate.',
      },
      {
        id: 3,
        index: 4,
        text: '	Develop and maintain effective communication strategies to engage parents and the wider community and to promote the NSW public education system.',
      },
      {
        id: 4,
        index: 5,
        text: 'Develop and maintain positive partnerships with parents, other schools and the wider community to uphold the values of NSW public education.',
      },
    ],
  },
];
