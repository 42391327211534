var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',_vm._b({class:'banner ml-0 pb-6 ' + _vm.bannerClass,style:({
    backgroundImage: _vm.overrideBackgroundImage
      ? 'url(' +
        _vm.backgroundImageUrl +
        ')' /* image will be served from the 'public' folder */
      : _vm.overrideBackgroundColour
      ? 'none'
      : '',
    backgroundColor: _vm.overrideBackgroundColour ? _vm.backgroundColour : '',
  })},'v-row',{ 'justify': _vm.centered ? 'center' : 'start' },false),[_c('v-col',{staticClass:"px-0"},[_vm._t("content",function(){return [_c('v-container',{staticClass:"px-0 d-flex justify-space-between",class:_vm.contentClass,attrs:{"fluid":_vm.$slots.rightPannel ? true : false}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"pt-1"},[_vm._t("icon",function(){return [(_vm.icon)?_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"alt":"","size":_vm.iconSize}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()]})],2),_c('div',{staticClass:"d-flex flex-column"},[_vm._t("chip",function(){return [(_vm.hasChip)?_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('Chip',{staticClass:"ml-3 appChip",attrs:{"text":_vm.chipText,"color":_vm.chipColor,"textColor":_vm.chipTextColor,"small":""}}),_c('div',{staticClass:"ml-3 flex-shrink-1 chip__side-text"},[_vm._v(" "+_vm._s(_vm.chipSideText)+" ")])],1):_vm._e()]}),_vm._t("title",function(){return [_c('h1',{staticClass:"appTitle"},[_vm._v(" "+_vm._s(_vm.heading)+" ")])]}),_vm._t("subtitle",function(){return [(_vm.subtitle)?_c('p',{staticClass:"appSubtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()]})],2),_c('div',{staticClass:"align-self-center info-icon"},[_vm._t("info",function(){return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.info)?_c('v-icon',_vm._g(_vm._b({staticClass:"material-icons-outlined",attrs:{"alt":"","size":_vm.infoSize,"color":"blue"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.info)+" ")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.infoMessage))])])]})],2)]),_c('div',[_vm._t("rightPannel")],2)])]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }