<template>
  <tbody>
    <v-container class="px-10 nts-user-banner--print">
      <UserBanner
        :user="actionPdp?.owner"
        :pdp="actionPdp"
        isPrint
        backgroundColour="white"
      />
    </v-container>
    <v-container class="px-0 px-md-10">
      <!-- PLAN SECTION -->
      <h1 class="mb-10 display-1 black--text">Plan</h1>
      <div class="page-break">
        <div v-if="isCurrentRole || isPlanRole || userIsSchoolPrincipal">
          <NTSSectionReadOnlyPrint
            :pdp="actionPdp"
            :section="planSection"
          />
        </div>
        <div
          v-else
          class="my-10"
        >
          <NoPermissionCard />
        </div>
      </div>
      <!-- IMPLEMENT SECTION -->
      <h1 class="my-10 display-1 black--text">Implement</h1>
      <div class="page-break">
        <div v-if="isCurrentRole || isMidYearRole || userIsSchoolPrincipal">
          <NTSSectionReadOnlyPrint
            :pdp="actionPdp"
            :section="midYearSection"
            class="my-5"
          />
        </div>
        <div
          v-else
          class="my-10"
        >
          <NoPermissionCard />
        </div>
      </div>
      <!-- REVIEW SECTION -->
      <h1 class="my-10 display-1 black--text">Review</h1>
      <div
        v-if="isCurrentRole || userIsSchoolPrincipal"
        class="page-break"
      >
        <NTSReviewReadOnly
          :pdp="actionPdp"
          :section="finalSection"
          class="my-10"
        />
        <NTSSupervisorAnnualReviewComment
          v-if="
            midYearSection?.complete &&
            !finalSection?.complete &&
            isCurrentSupervisor &&
            finalSection.notifySupervisor
          "
          class="my-10"
          :isPanelOn="readyToSignAnnualReview"
          readOnly
        />
        <EventLog
          :pdp="actionPdp"
          :section="finalSection"
          :owner="actionPdp?.owner"
          :supervisor="supervisor"
          :principal="principal"
          class="my-10 px-0 px-md-10 py-8"
          v-if="finalSection?.notifySupervisor || isActionPdpArchived"
        />
      </div>
      <div
        v-else
        class="my-10 page-break"
      >
        <NoPermissionCard />
      </div>
      <!-- ARCHIVE REASON CARD -->
      <IncompleteArchiveCard
        v-if="isActionPdpIncompleteArchived"
        :pdp="actionPdp"
        class="my-5 page-break"
      />

      <!-- PERSONAL NOTES -->
      <PersonalNotesCard
        class="pa-0"
        :comments="pdpUserNotes"
      />
    </v-container>
  </tbody>
</template>

<script>
import NTSSectionReadOnlyPrint from '@/components/print/NTSSectionReadOnlyPrint';
import NTSReviewReadOnly from '@/components/NTSReviewReadOnly.vue';
import NTSSupervisorAnnualReviewComment from '@/components/NTSSupervisorAnnualReviewComment.vue';
import UserBanner from '@/components/UserBanner';
import NoPermissionCard from '@/components/print/NoPermissionCard.vue';
import PersonalNotesCard from '@/components/print/PersonalNotesCard';
import { mapGetters } from 'vuex';
import EventLog from '@/components/EventLog.vue';
import IncompleteArchiveCard from '@/components/IncompleteArchiveCard.vue';
export default {
  name: 'NTSSupervisorArchivedPrintContent',
  components: {
    NTSSectionReadOnlyPrint,
    UserBanner,
    NTSReviewReadOnly,
    NTSSupervisorAnnualReviewComment,
    NoPermissionCard,
    PersonalNotesCard,
    EventLog,
    IncompleteArchiveCard,
  },
  props: {
    actionPdp: {
      type: Object,
      default: () => ({}),
    },
    planSection: {
      type: Object,
      default: () => ({}),
    },
    isCurrentPdpPrincipal: {
      type: Boolean,
      required: true,
    },
    isCurrentSupervisor: {
      type: Boolean,
      required: true,
    },
    principal: {
      type: Object,
      default: () => ({}),
    },
    supervisor: {
      type: Object,
      default: () => ({}),
    },
    readyToSignAnnualReview: {
      type: Boolean,
      required: true,
    },
    isCurrentRole: {
      type: Boolean,
      required: true,
    },
    isPlanRole: {
      type: Boolean,
      required: true,
    },
    isMidYearRole: {
      type: Boolean,
      required: true,
    },
    userIsSchoolPrincipal: {
      type: Boolean,
      required: true,
    },
    pdpUserNotes: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  computed: {
    ...mapGetters('pdp', {
      midYearSection: 'actionPdpMidYearSection',
      finalSection: 'actionPdpFinalSection',
    }),
    ...mapGetters([
      'loggedInUser',
      'isActionPdpArchived',
      'isActionPdpIncompleteArchived',
    ]),
  },
};
</script>

<style scoped lang="scss">
:deep .v-banner__wrapper {
  border-bottom: none !important;
}

:deep .v-alert__content button {
  pointer-events: none;
}
:deep .v-dialog.v-dialog__content--active {
  .v-btn:not(header .v-btn) {
    pointer-events: none;
  }
}
:deep button.v-expansion-panel-header {
  pointer-events: none;
}
.page-break {
  break-after: page;
}
.v-tabs {
  pointer-events: none;
}
:deep .nts-user-banner--print {
  &.banner1 {
    color: $ads-grey-01;
    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
  button {
    display: none;
  }
  .v-avatar {
    background-color: $ads-white;
    margin-left: 0 !important;
  }
  .initials-icon,
  .appTitle,
  .user-role__text {
    color: $ads-grey-01;
  }
  .supervisors-box {
    &__card {
      background-color: $ads-white;
      margin-right: 0 !important;
    }
    &__text {
      color: $ads-grey-01;
    }
  }
  .user-banner.container {
    padding: 0;
  }
}
table {
  width: 100%;
  border-collapse: collapse;
}
.nts-print-content {
  :deep button {
    pointer-events: none;
  }
}
:deep .goal-buttons,
:deep .nts-add-goal-button,
:deep .save-learning-and-development-button,
:deep .save-annual-review-btn,
thead {
  display: none;
}
:deep .ql-editor {
  min-height: auto;
}
:deep .goal-heading {
  background-color: $ads-secondary-grey;
}
:deep .v-icon {
  color: $ads-grey-01 !important;
}
@media print {
  thead {
    display: table-header-group;
  }
  .container.nts-user-banner--print {
    padding: 0 !important;
  }
}
</style>
