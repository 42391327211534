import { get } from '../http';
import { School } from '../../../../lib/schema';

const basePath = process.env.VUE_APP_IS_LAMBDA ? '/v2' : '';

const getAllSchools = async () => {
  const response = await get<School[]>('/schools');
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getSchoolsByFullName = async (
  schoolFullName: string
): Promise<School[] | undefined> => {
  const response = await get<School[]>(
    `${basePath}/schools/search?name=${schoolFullName}`
  );
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getStats = (userId: number) => {
  return get(`/school/${userId}/stats`);
};

const getStaff = (userId: number) => {
  return get(`/school/${userId}/staff`);
};

const getStaffPdp = (userId: number, staffId: number) => {
  return get(`/school/${userId}/${staffId}/pdp`);
};

export default {
  getStaff,
  getStats,
  getStaffPdp,
  getAllSchools,
  getSchoolsByFullName,
};
