<template>
  <Banner
    :heading="formatName(user)"
    class="banner1 pa-0 ma-0"
    contentClass="pa-0"
    v-bind="$attrs"
  >
    <slot name="content">
      <slot slot="icon">
        <v-icon
          class="pt-2"
          x-large
        >
          mdi-file-document-outline
        </v-icon>
      </slot>
      <slot slot="subtitle">
        <div class="ml-3 mt-0 mt-md-2">
          <p class="my-0 text-smAndDown">
            <strong>Position:</strong>
            {{ userPosition }}
          </p>
          <p class="my-0 text-smAndDown">
            <strong>School:</strong>
            {{ schoolLabel }}
          </p>
          <v-row
            v-if="user?.isDel === false"
            align="center"
            no-gutters
          >
            <v-col
              class="pt-0 pr-1"
              cols="auto"
            >
              <span class="text-smAndDown">
                <strong>PDP Supervisor:</strong>
              </span>
            </v-col>
            <v-col
              class="pt-0 pr-2"
              cols="auto"
            >
              <span>
                {{ supervisorLabel }}
              </span>
            </v-col>
          </v-row>
        </div>
      </slot>
    </slot>
  </Banner>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { formatName, getPositions } from '@/utils';
import { SECTION_TYPE, USER_TYPE } from '@/data/constants';
import { findPdpUserByType } from '@/lib/pdp';

export default {
  name: 'TSUserBannerPrint',
  props: {
    user: {
      type: Object,
      required: true,
    },
    pdp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ADS_Colors,
    };
  },
  components: {
    Banner,
  },
  computed: {
    finalSectionComplete() {
      return this.pdp?.sections.find(
        (s) => s.sectionType === SECTION_TYPE.final
      ).complete;
    },
    supervisorLabel() {
      if (this.pdp?.pdp_user_associations) {
        const user = findPdpUserByType(
          this.pdp?.pdp_user_associations,
          USER_TYPE.supervisor
        )?.user;
        if (!user) return 'Not assigned';
        return formatName(user);
      } else return 'Not assigned';
    },
    userPosition() {
      return getPositions(this.user?.staffEmployment);
    },
    schoolLabel() {
      return this.pdp?.owner_school?.schoolFullName ?? 'Not assigned';
    },
  },
  methods: {
    formatName,
  },
};
</script>

<style lang="scss" scoped>
.banner1 {
  background-image: url(@/assets/pdp-banner.png);
  background-position: right;
}

@media screen and (max-width: 959px) {
  :deep .appTitle {
    font-size: 20px;
    font-weight: 700;
  }
  .text-smAndDown {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  // makes edit button height same as adjacent text
  :deep .col .v-btn.v-btn--round {
    margin-top: -14px;
    margin-bottom: -14px;
  }
}
</style>
