<template>
  <v-card
    :outlined="outlined"
    :flat="flat"
    :class="`dark-border ${
      !flat ? 'px-5 px-md-10 py-md-5' : ''
    } no-break-inside`"
    v-if="show"
  >
    <v-row
      class="ma-0 flex-column flex-md-row"
      no-gutters
    >
      <v-col class="px-0 px-md-3">
        <v-card-title class="ma-0 pa-0">
          <p
            class="subtitle-1"
            v-dompurify-html:plaintext="accountability?.areaOfPractice"
          />
        </v-card-title>
        <v-card-text class="ma-0 pa-0">
          <p
            class="mb-0 mb-md-4 goal-text"
            v-dompurify-html:plaintext="accountability?.accountability"
          />
        </v-card-text>
      </v-col>
      <v-col
        v-if="canDelete"
        class="pt-0 px-0 pb-6 pt-md-3 px-md-3 pb-md-3"
        md="2"
      >
        <v-row
          justify="end"
          class="mx-0"
          no-gutters
        >
          <AdsButton
            :ripple="false"
            buttonText="Delete"
            plain
            @click="$emit('deleteAccountability')"
            class="pa-0"
            icon="mdi-delete-outline"
            color="error"
          ></AdsButton>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'KeyAccountability',
  components: {
    AdsButton,
  },
  props: {
    accountability: {
      type: Object,
      default: () => ({}),
    },
    canDelete: Boolean,
    outlined: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.delete-button {
  color: $ads-red;
}

.dark-border {
  border-color: $ads-dark-80;
}

.no-break-inside {
  break-inside: avoid;
}
</style>
