<template>
  <tbody>
    <v-container class="pa-0 nts-user-banner--print">
      <UserBanner
        :user="actionPdp?.owner"
        :pdp="actionPdp"
        isPrint
        backgroundColour="white"
      />
    </v-container>
    <v-container class="pa-0">
      <!-- PLAN SECTION -->
      <v-container class="mb-10 pa-0">
        <PrintTabs
          :tabs="tabs"
          :selectedTab="getTabIndexById(TAB_TYPES.plan.id)"
        />
      </v-container>
      <div class="page-break">
        <div v-if="isCurrentRole || isPlanRole || userIsSchoolPrincipal">
          <SignaturePanel
            class="my-10 mb-7"
            :owner="actionPdp?.owner"
            :users="actionPdp?.pdp_user_associations"
            :section="planSection"
            :disableSign="!isSectionValid"
            v-if="
              planSection?.notifySupervisor &&
              !planSection?.complete &&
              (isCurrentSupervisor || isCurrentPdpPrincipal)
            "
          />
          <NTSSectionReadOnlyPrint
            :pdp="actionPdp"
            :section="planSection"
          />
        </div>
        <div
          v-else
          class="my-10"
        >
          <NoPermissionCard />
        </div>
      </div>
      <!-- IMPLEMENT SECTION -->
      <v-container class="my-10 pa-0">
        <PrintTabs
          :tabs="tabs"
          :selectedTab="getTabIndexById(TAB_TYPES.implement.id)"
        />
      </v-container>
      <div class="page-break">
        <div v-if="isCurrentRole || isMidYearRole || userIsSchoolPrincipal">
          <SignaturePanel
            v-if="
              midYearSection?.notifySupervisor &&
              ((!midYearSection?.complete && isCurrentSupervisor) ||
                (isCurrentPdpPrincipal &&
                  !hasPdpPrincipalSignedMidYear &&
                  !actionPdp?.finalCommentLocked))
            "
            :owner="actionPdp?.owner"
            :users="actionPdp?.pdp_user_associations"
            :section="midYearSection"
            :disableSign="!isSectionValid && !midYearSection?.complete"
            class="pa-0 my-10"
          />
          <NTSSectionReadOnlyPrint
            :pdp="actionPdp"
            :section="midYearSection"
          />
        </div>
        <div
          v-else
          class="my-10"
        >
          <NoPermissionCard />
        </div>
      </div>
      <!-- REVIEW SECTION -->
      <v-container class="my-10 pa-0">
        <PrintTabs
          :tabs="tabs"
          :selectedTab="getTabIndexById(TAB_TYPES.review.id)"
        />
      </v-container>
      <div
        v-if="isCurrentRole || userIsSchoolPrincipal"
        class="page-break"
      >
        <SignaturePanel
          v-if="showAnnualReviewSupervisorSignaturePanel"
          :owner="actionPdp?.owner"
          :users="actionPdp?.pdp_user_associations"
          :section="finalSection"
          class="pa-0 my-10"
          :disableSign="!isSectionValid || !isFormValid"
        />
        <NTSReviewReadOnly
          :pdp="actionPdp"
          :section="finalSection"
          class="my-5"
        />
        <NTSSupervisorAnnualReviewComment
          v-if="
            midYearSection?.complete &&
            !finalSection?.complete &&
            isCurrentSupervisor &&
            finalSection.notifySupervisor
          "
          class="my-10"
          :isPanelOn="readyToSignAnnualReview"
          readOnly
        />
        <EventLog
          :section="finalSection"
          :owner="actionPdp?.owner"
          :supervisor="supervisor"
          :principal="principal"
          class="my-10 px-0 px-md-10 py-8"
          v-if="finalSection?.notifySupervisor"
        />
      </div>
      <div
        v-else
        class="my-10 page-break"
      >
        <NoPermissionCard />
      </div>
      <!-- PERSONAL NOTES -->
      <PersonalNotesCard
        v-if="!isCurrentAdditionalSupervisor"
        class="my-10 py-0"
        :comments="pdpUserNotes"
      />
    </v-container>
  </tbody>
</template>

<script>
import NTSSectionReadOnlyPrint from '@/components/print/NTSSectionReadOnlyPrint';
import NTSReviewReadOnly from '@/components/NTSReviewReadOnly.vue';
import NTSSupervisorAnnualReviewComment from '@/components/NTSSupervisorAnnualReviewComment.vue';
import UserBanner from '@/components/UserBanner';
import SignaturePanel from '@/components/SignaturePanel.vue';
import PrintTabs from '@/components/print/PrintTabs.vue';
import NoPermissionCard from '@/components/print/NoPermissionCard.vue';
import PersonalNotesCard from '@/components/print/PersonalNotesCard';
import { TAB_TYPES, USER_TYPE } from '@/data/constants';
import { mapGetters } from 'vuex';
import EventLog from '@/components/EventLog.vue';
import { findPdpUserByType } from '@/lib/pdp';
export default {
  name: 'NTSSupervisorPrintContent',
  components: {
    NTSSectionReadOnlyPrint,
    UserBanner,
    NTSReviewReadOnly,
    NTSSupervisorAnnualReviewComment,
    SignaturePanel,
    PrintTabs,
    NoPermissionCard,
    PersonalNotesCard,
    EventLog,
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
    actionPdp: {
      type: Object,
      default: () => ({}),
    },
    planSection: {
      type: Object,
      default: () => ({}),
    },
    isFormValid: {
      type: Boolean,
      required: true,
    },
    isCurrentPdpPrincipal: {
      type: Boolean,
      required: true,
    },
    isCurrentSupervisor: {
      type: Boolean,
      required: true,
    },
    isSectionValid: {
      type: Boolean,
      required: true,
    },
    showAnnualReviewSupervisorSignaturePanel: {
      type: Boolean,
      required: true,
    },
    principal: {
      type: Object,
      default: () => ({}),
    },
    supervisor: {
      type: Object,
      default: () => ({}),
    },
    hasPdpPrincipalSignedPlan: {
      type: Boolean,
      required: true,
    },
    hasPdpPrincipalSignedMidYear: {
      type: Boolean,
      required: true,
    },
    readyToSignAnnualReview: {
      type: Boolean,
      required: true,
    },
    isCurrentRole: {
      type: Boolean,
      required: true,
    },
    isPlanRole: {
      type: Boolean,
      required: true,
    },
    isMidYearRole: {
      type: Boolean,
      required: true,
    },
    userIsSchoolPrincipal: {
      type: Boolean,
      required: true,
    },
    pdpUserNotes: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      TAB_TYPES,
      tabs: [TAB_TYPES.plan, TAB_TYPES.implement, TAB_TYPES.review],
    };
  },
  computed: {
    ...mapGetters('pdp', {
      midYearSection: 'actionPdpMidYearSection',
      finalSection: 'actionPdpFinalSection',
    }),
    ...mapGetters(['loggedInUser']),
    isCurrentAdditionalSupervisor() {
      return this.isLoggedInUserOfType(USER_TYPE.additionalSupervisor);
    },
  },
  methods: {
    getTabIndexById(tabId) {
      return this.tabs.findIndex((tab) => tab.id === tabId);
    },
    isLoggedInUserOfType(userType) {
      const user = findPdpUserByType(
        this.actionPdp?.pdp_user_associations,
        userType
      );
      return user?.user_id === this.loggedInUser?.id;
    },
  },
};
</script>

<style scoped lang="scss">
:deep .v-banner__wrapper {
  border-bottom: none !important;
}

:deep .v-alert__content button {
  pointer-events: none;
}
:deep .v-dialog.v-dialog__content--active {
  .v-btn:not(header .v-btn) {
    pointer-events: none;
  }
}
:deep button.v-expansion-panel-header {
  pointer-events: none;
}
.page-break {
  break-after: page;
}
.v-tabs {
  pointer-events: none;
}
:deep .nts-user-banner--print {
  &.banner1 {
    color: $ads-grey-01;
    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
  button {
    display: none;
  }
  .v-avatar {
    background-color: $ads-white;
    margin-left: 0 !important;
  }
  .initials-icon,
  .appTitle,
  .user-role__text {
    color: $ads-grey-01;
  }
  .supervisors-box {
    &__card {
      background-color: $ads-white;
      margin-right: 0 !important;
    }
    &__text {
      color: $ads-grey-01;
    }
  }
  .user-banner.container {
    padding: 0;
  }
}
table {
  width: 100%;
  border-collapse: collapse;
}
.nts-print-content {
  :deep button {
    pointer-events: none;
  }
}
:deep .goal-buttons,
:deep .nts-add-goal-button,
:deep .save-learning-and-development-button,
:deep .save-annual-review-btn,
thead {
  display: none;
}
:deep .ql-editor {
  min-height: auto;
}

@media print {
  thead {
    display: table-header-group;
  }
  .container.nts-user-banner--print {
    padding: 0 !important;
  }
}
</style>
