var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('ErrorPage',{staticClass:"container",attrs:{"title":"The page you are looking for does not exist","is404Page":true,"actions":[
        {
          text: 'go back to home.',
          attrs: {
            href: '/',
            rel: 'noopener',
          },
        },
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }