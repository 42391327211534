<template>
  <div>
    <v-tabs
      v-model="tab"
      class="ma-0 pa-0"
      slider-color="red"
    >
      <v-tab
        v-for="(item, index) in availableTabs"
        :key="`tab${index + 1}`"
      >
        {{ item.tab }}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item key="tabItem1">
          <TSPlanReadOnly
            :pdp="formPdp"
            :section="planSection"
          />
        </v-tab-item>
        <v-tab-item key="tabItem2">
          <v-container class="px-0 px-md-3">
            <OrientationBanner
              v-if="showOrientationBannerOnGoalsAndEvidence"
              :isSupervisor="false"
              :pdp="formPdp"
              :section="activeSection"
              :supervisorName="supervisorName"
              class="pa-0 mt-3"
              @signed="handleSignedForm"
            />
            <IncompleteFieldCard
              class="mt-8 py-5 px-0"
              v-if="isPdpIncompleteArchived && !midYearSection?.goals?.length"
            />
            <Wrapper
              :isMobile="isMobile"
              cardClass="py-5 px-10"
              class="mt-8 py-5 px-0"
              v-else
            >
              <TSPdpGoals
                v-if="midYearSection?.goals?.length"
                :editable="!isPdpArchived"
                :owner="loggedInUser"
                :section="midYearSection"
                :userIsPrincipal="loggedInUser.isPrincipal"
              />
            </Wrapper>
          </v-container>
        </v-tab-item>
        <v-tab-item key="tabItem3">
          <v-container class="px-0 px-md-3">
            <IncompleteFieldCard
              class="mt-8 py-5 px-0"
              v-if="isPdpIncompleteArchived && !formPdp?.observations?.length"
            />
            <Wrapper
              :isMobile="isMobile"
              cardClass="py-5 px-10"
              class="py-5 px-2"
              v-else
            >
              <TSPdpObservations
                :edit-mode="!isPdpArchived"
                :pdp="formPdp"
              />
            </Wrapper>
          </v-container>
        </v-tab-item>
        <v-tab-item key="tabItem4">
          <v-container class="px-0 px-md-8">
            <OrientationBanner
              v-if="!showOrientationBannerOnGoalsAndEvidence"
              :isSupervisor="false"
              :pdp="formPdp"
              :section="activeSection"
              :supervisorName="supervisorName"
              class="pa-0 mt-3"
              @signed="handleSignedForm"
            />
            <Wrapper
              :isMobile="isMobile"
              cardClass="py-5 px-10"
              class="mt-8 py-5 px-0"
            >
              <TSReviews />
            </Wrapper>
            <IncompleteArchiveCard
              v-if="isPdpIncompleteArchived"
              :pdp="pdp"
            />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import TSReviews from '@/components/TSReviews.vue';
import TSPdpGoals from '@/components/TSPdpGoals.vue';
import TSPdpObservations from '@/components/TSPdpObservations.vue';
import OrientationBanner from '@/components/OrientationBanner.vue';
import TSPlanReadOnly from '@/components/TSPlanReadOnly.vue';
import Wrapper from '@/components/Wrapper.vue';
import { mapGetters } from 'vuex';
import { TAB_TYPES, USER_TYPE } from '@/data/constants';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard.vue';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { formatDateTime, formatName } from '@/utils';
import IncompleteArchiveCard from '@/components/IncompleteArchiveCard.vue';
import { findPdpUserByType, getPdpSectionOnArchival } from '@/lib/pdp';

export default {
  name: 'TSMyPdpTabs',
  components: {
    IncompleteArchiveCard,
    IncompleteFieldCard,
    TSPlanReadOnly,
    OrientationBanner,
    TSPdpObservations,
    TSPdpGoals,
    TSReviews,
    Wrapper,
  },
  data() {
    return {
      ADS_Colors,
      tab: 0,
      availableTabs: [
        TAB_TYPES.plan,
        TAB_TYPES.goalsAndEvidence,
        TAB_TYPES.observations,
        TAB_TYPES.reviews,
      ],
    };
  },
  props: {
    selectedTab: {
      type: String,
      default: '',
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'formPdp',
      'pdp',
      'isLoading',
      'isPdpArchived',
      'isPdpIncompleteArchived',
    ]),
    ...mapGetters('pdp', {
      planSection: 'formPdpPlanSection',
      midYearSection: 'formPdpMidYearSection',
      finalSection: 'formPdpFinalSection',
    }),
    showOrientationBannerOnGoalsAndEvidence() {
      return !this.planSection.complete && !this.isPdpArchived;
    },
    activeSection() {
      if (this.planSection?.complete && this.midYearSection?.complete) {
        return this.finalSection;
      }
      if (this.planSection?.complete) {
        return this.midYearSection;
      }
      return this.planSection;
    },
    supervisor() {
      return findPdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.supervisor
      );
    },
    supervisorName() {
      return formatName(this.supervisor?.user);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    paramPdpId() {
      return parseInt(this.$route.params.pdpId);
    },
    pdpSectionOnArchival() {
      return getPdpSectionOnArchival(this.pdp);
    },
  },
  watch: {
    selectedTab: {
      async handler() {
        if (this.selectedTab) {
          await this.pushToCorrectRoute();
        }
      },
      immediate: true,
    },
    tab: {
      async handler() {
        if (this.availableTabs && this.selectedTab && this.showTabs) {
          await this.syncRouteToTab();
        }
      },
      immediate: true,
    },
    paramPdpId: {
      async handler() {
        if (this.paramPdpId && this.showTabs) {
          await this.pushToCorrectRoute();
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatDateTime,
    formatName,
    async syncTabToRoute() {
      this.tab = this.availableTabs.findIndex(
        (item) => item.id === this.selectedTab
      );
    },
    async syncRouteToTab() {
      if (this.showTabs && this.selectedTab && !this.isLoading) {
        const tab = this.availableTabs.findIndex(
          (item) => item.id === this.selectedTab
        );
        if (this.tab !== tab) {
          const pdpId = parseInt(this.$route.params.pdpId);
          await this.$router.push(
            `/my-pdp/${pdpId}/${this.availableTabs[this.tab].id}`
          );
        }
      }
    },
    async pushToCorrectRoute() {
      if (this.showTabs && !this.selectedTab && this.paramPdpId) {
        if (
          this.planSection?.complete &&
          (this.midYearSection?.notifySupervisor ||
            this.midYearSection?.supervisorSignature) &&
          this.$route.path !==
            `/my-pdp/${this.paramPdpId}/${this.availableTabs[3].id}`
        ) {
          await this.$router.push(
            `/my-pdp/${this.paramPdpId}/${this.availableTabs[3].id}`
          );
        } else if (
          this.planSection?.complete &&
          !this.midYearSection?.notifySupervisor &&
          !this.midYearSection?.supervisorSignature &&
          this.$route.path !==
            `/my-pdp/${this.paramPdpId}/${this.availableTabs[1].id}` &&
          this.paramPdpId
        ) {
          await this.$router.push(
            `/my-pdp/${this.paramPdpId}/${this.availableTabs[1].id}`
          );
        }
      } else if (
        this.availableTabs &&
        this.selectedTab &&
        this.showTabs &&
        this.paramPdpId
      ) {
        await this.syncTabToRoute();
      }
    },
    async handleSignedForm(sectionToBeUpdated, comment) {
      this.$emit('tabSigned', sectionToBeUpdated, comment);
    },
    gtmTrackSign(sectionType) {
      this.$gtm.trackEvent({
        event: null,
        category: 'MyPdp',
        action: 'Supervisee Sign Pdp',
        label: 'OrientationBanner',
        value: `${sectionType}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .v-tabs .v-tab {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.02em;

  &.v-tab--active {
    font-weight: bold;
    color: $ads-grey-01;
  }

  &.v-tab:not(.v-tab--active) {
    color: $ads-grey-01;
  }

  .v-expansion-panel-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 959px) {
  :deep .v-slide-group .v-slide-group__prev {
    &--disabled {
      display: none !important;
    }
  }
}
</style>
