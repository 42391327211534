<template>
  <Banner
    :heading="formatName(user)"
    :user="user"
    :hasChip="displayArchiveChip"
    :chipSideText="archiveDate"
    contentClass=""
    class="banner1 pa-0 ma-0"
    v-bind="$attrs"
  >
    <template #icon>
      <InitialsIcon
        class="ml-md-6"
        :givenName="user?.staffGivenName"
        :surname="user?.staffFamilyName"
        :size="isMobile ? '60px' : '90px'"
      />
    </template>

    <template #subtitle>
      <div class="pl-3 user-role__text">{{ userPosition }}</div>
      <div class="pl-3 user-role__text">
        <strong>School:</strong>
        {{ schoolLabel }}
      </div>
      <div
        v-if="isPdpTs && loggedInUser?.isDel === false"
        class="pl-3 user-role__text"
      >
        <strong>PDP Supervisor:</strong>
        {{ supervisorLabel }}
      </div>
      <div
        v-if="isOnBoardingComplete && isMobile"
        class="mt-2 pa-0 d-flex flex-column justify-center align-center"
      >
        <AdsButton
          v-if="isOwner && !pdp.finalCommentLocked"
          class="ads-tertiary-blue-background ads-grey-01 height-36"
          icon="mdi-account-edit-outline"
          buttonText="Edit details"
          @click="editPdpUsers"
        ></AdsButton>
      </div>
    </template>
    <template
      v-if="
        ((!isMobile || isPrint) && isPdpSupervised) || isPdpIncompleteArchived
      "
      #rightPannel
    >
      <v-card class="mr-15 d-flex flex-column right-panel">
        <div class="py-3 px-5">
          <v-row
            class="flex-nowrap"
            no-gutters
          >
            <v-col
              class="d-flex flex-column mr-3"
              cols="auto"
            >
              <span
                v-for="item in cardContent"
                :key="item.label"
                class="font-weight-bold"
              >
                {{ item.label }}:
              </span>
            </v-col>
            <v-col class="d-flex flex-column">
              <span
                v-for="item in cardContent"
                :key="item.label"
                class="overflow-visible"
              >
                {{ item.value }}
              </span>
            </v-col>
          </v-row>
        </div>
        <div
          v-if="isOnBoardingComplete"
          class="mb-3 pa-0 d-flex flex-column justify-center align-center"
        >
          <AdsButton
            v-if="isOwner && !pdp?.finalCommentLocked && pdp.active"
            class="ads-tertiary-blue-background ads-grey-01 height-36"
            icon="mdi-account-edit-outline"
            buttonText="Edit details"
            @click="editPdpUsers"
          ></AdsButton>
        </div>
      </v-card>
    </template>
  </Banner>
</template>

<script>
import { InitialsIcon, AdsButton } from '@nswdoe/doe-ui-core';
import Banner from '@/components/ADS-customised/Banner/Banner.vue';
import { formatName, getPositions, formatDate } from '@/utils';
import { mapGetters } from 'vuex';
import { ROLES, USER_TYPE, PDP_TYPE } from '@/data/constants';
import { findPdpUserByType, getIsPdpIncompleteArchived } from '@/lib/pdp';

export default {
  name: 'UserBanner',
  components: { Banner, InitialsIcon, AdsButton },
  props: {
    user: {
      type: Object,
      required: true,
    },
    pdp: {
      type: Object,
      default: undefined,
    },
    isPrint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    cardContent() {
      if (this.isPdpTs) {
        return [
          {
            label: ROLES.pdpSupervisor,
            value: this.supervisorLabel,
          },
        ];
      } else
        return [
          {
            label: ROLES.pdpSupervisor,
            value: this.supervisorLabel,
          },
          { label: ROLES.pdpAdditionalSupervisor, value: this.managerLabel },
          { label: ROLES.pdpPrincipal, value: this.pdpPrincipalLabel },
        ];
    },
    isPdpTs() {
      return this.pdp?.type === PDP_TYPE.ts;
    },
    isOwner() {
      return this.loggedInUser?.id === this.pdp?.owner?.id;
    },
    userPosition() {
      return getPositions(this.user?.staffEmployment);
    },
    schoolLabel() {
      return this.pdp?.owner_school?.schoolFullName ?? 'Not assigned';
    },
    supervisorLabel() {
      return this.getPdPUserLabel(USER_TYPE.supervisor);
    },
    managerLabel() {
      return this.getPdPUserLabel(USER_TYPE.additionalSupervisor);
    },
    pdpPrincipalLabel() {
      return this.getPdPUserLabel(USER_TYPE.pdpPrincipal);
    },
    isOnBoardingComplete() {
      return this.pdp?.onboardingComplete;
    },
    displayArchiveChip() {
      if (this.pdp === undefined) return false;
      return !this.pdp?.active;
    },
    archiveDate() {
      if (this.pdp?.active || !this.pdp?.archived_at) {
        return null;
      }
      return `Archived ${formatDate(this.pdp?.archived_at, 'DD/MM/YYYY')}`;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isPdpIncompleteArchived() {
      return getIsPdpIncompleteArchived(this.pdp);
    },
    isPdpSupervised() {
      return this.pdp?.pdp_user_associations.length;
    },
  },
  methods: {
    formatName,
    getPdPUserLabel(pdpUserType) {
      const user = findPdpUserByType(
        this.pdp?.pdp_user_associations,
        pdpUserType
      )?.user;
      if (user) {
        return formatName(user);
      }
      return 'Not assigned';
    },
    editPdpUsers() {
      this.$emit('editPdpUsers');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.v-btn {
  &.ads-tertiary-blue-background {
    background-color: $ads-tertiary-blue !important;
    border-color: $ads-tertiary-blue !important;
  }
  &.height-36 {
    height: 36px !important;
  }
}

.v-card {
  background-color: rgba(64, 126, 201, 0.3);
  span {
    white-space: nowrap;
  }
}
:deep .initials-icon {
  font-size: 24px !important;
}
.right-panel {
  color: $ads-white;
}
</style>
