import { AxiosError } from 'axios';
import {
  Pdp,
  Observation,
  Evidence,
  PdpManagement,
} from '../../../../lib/schema';
import { get, post, put } from '../http';
import { MANAGEMENT_QUERY_TYPE } from '@/data/constants';

const basePath = process.env.VUE_APP_IS_LAMBDA ? '/v2' : '';

const getPdpManagement = async (
  query_type: string,
  page_size: number | null = null,
  page: number | null = null
): Promise<PdpManagement | undefined> => {
  const page_size_query = page_size ? `&page_size=${page_size}` : undefined;
  const page_query = page ? `&page=${page}` : undefined;
  const page_query_full =
    page_size_query && page_query ? `${page_size_query}${page_query}` : '';

  const url = `${basePath}/pdps/management?query_type=${query_type}${page_query_full}`;
  const response = await get<PdpManagement>(url);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getPdpStats = async (): Promise<unknown | undefined> => {
  const url = `${basePath}/pdps/${MANAGEMENT_QUERY_TYPE.stats}`;
  const response = await get<unknown>(url);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getEvidenceWithFile = async (
  evidenceId: number
): Promise<Evidence | undefined> => {
  const response = await get<Evidence>(`${basePath}/evidence/${evidenceId}`);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getObservationWithFile = async (
  observationId: number
): Promise<Observation | undefined> => {
  const response = await get<Observation>(
    `${basePath}/observations/${observationId}`
  );
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getUserPdp = async (user_id: number): Promise<Pdp[] | undefined> => {
  const url = `${basePath}/pdps/?user_id=${user_id}`;
  const response = await get<Pdp[]>(url);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getPdpById = async (pdpId: number): Promise<Pdp[] | undefined> => {
  try {
    const response = await get<Pdp[]>(`${basePath}/pdps/item/${pdpId}`);
    return response.data;
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 500
    ) {
      return undefined;
    }
  }
};

// Type guard to check if the error is an AxiosError
const isAxiosError = (error: unknown): error is AxiosError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'isAxiosError' in error &&
    error.isAxiosError === true
  );
};

const getPdpStatus = async (
  pdpId: number,
  updated_at: string
): Promise<
  | {
      is_expired: boolean;
    }
  | undefined
> => {
  const response = await get<{
    is_expired: boolean;
  }>(`${basePath}/pdps/item/${pdpId}?updated_at=${updated_at}`);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const savePdp = async (pdp: Partial<Pdp>): Promise<Pdp | undefined> => {
  const response = await put(`${basePath}/pdps/`, pdp);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const createPdp = async (year: number): Promise<Pdp | undefined> => {
  const url = `${basePath}/pdps/`;
  const response = await post(url, { year: year });
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getPersonalNotes = async (pdpId: number): Promise<Pdp[] | undefined> => {
  try {
    const response = await get<Pdp[]>(`${basePath}/pdps/notes/${pdpId}`);
    return response.data;
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 500
    ) {
      return undefined;
    }
  }
};

const updatePersonalNotes = async (
  comment: Partial<Comment>
): Promise<Pdp | undefined> => {
  const response = await put(`${basePath}/pdps/notes/`, comment);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

export default {
  getPdpManagement,
  getPdpStats,
  getUserPdp,
  savePdp,
  createPdp,
  getPdpById,
  getObservationWithFile,
  getEvidenceWithFile,
  getPdpStatus,
  getPersonalNotes,
  updatePersonalNotes,
};
