<template>
  <TSMyPdpView
    v-if="isTS"
    :selectedTab="selectedTab"
    @archive="archive"
    :showExpiredPdpDialog="showExpiredPdpDialog"
    @handleRefresh="handleRefresh"
    @selected="pdpSelected"
    @notesOpen="setNotesOpen"
  />
  <NTSMyPdpView
    v-else-if="isNTS"
    :selectedTab="selectedTab"
    @archive="archive"
    :showExpiredPdpDialog="showExpiredPdpDialog"
    @handleRefresh="handleRefresh"
    @createNtsPdp="createPdp"
    @selected="pdpSelected"
    @notesOpen="setNotesOpen"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TSMyPdpView from '@/views/MyPdp/TSMyPdpView.vue';
import NTSMyPdpView from '@/views/MyPdp/NTSMyPdpView.vue';
import { PDP_ROUTES, PDP_TYPE, USER_TYPE } from '@/data/constants';
import { findPdpUserByType } from '@/lib/pdp';

export default {
  name: 'MyPdp',
  data() {
    return {
      refresh: false,
      // intervalId: null,
      showExpiredPdpDialog: false,
      isActive: true,
      ownedPdpLoaded: false,
      notesOpen: false,
    };
  },
  components: {
    NTSMyPdpView,
    TSMyPdpView,
  },
  props: {
    pdpId: {
      type: String,
      default: null,
    },
    selectedTab: {
      type: String,
      default: '',
    },
    inFocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'pdp',
      'ownedPdp',
      'isPdpUpdated',
      'isPdpLoaded',
      'isLoading',
      'config',
    ]),
    ...mapGetters('pdp', [
      'intervalId',
      'pdpPlanSection',
      'pdpMidYearSection',
      'pdpFinalSection',
    ]),
    isTS() {
      return (
        this.pdp?.type === PDP_TYPE.ts || this.loggedInUser.isTeachingStaff
      );
    },
    isNTS() {
      return (
        (this.pdp && this.pdp?.type === PDP_TYPE.nts) ||
        this.loggedInUser.isNonTeachingStaff
      );
    },
    hasActivePdp() {
      return this.ownedPdp?.filter((pdp) => pdp.active)?.length > 0;
    },
    pdpPrincipal() {
      return findPdpUserByType(
        this.pdp?.pdp_user_associations,
        USER_TYPE.pdpPrincipal
      );
    },
    tsNotifyState() {
      return this.pdp?.sections.some((section) => {
        return section.notifySupervisor && !section.supervisorSignature;
      });
    },
    ntsNotifyState() {
      switch (true) {
        case this.pdpFinalSection?.notifySupervisor &&
          !this.pdpFinalSection?.supervisorSignature:
          return true;
        case this.pdpMidYearSection?.notifySupervisor &&
          !this.pdpMidYearSection?.supervisorSignature:
          return true;
        case this.pdpPlanSection?.notifySupervisor &&
          !this.pdpPlanSection?.supervisorSignature:
          return true;
        case this.pdpPrincipal &&
          this.pdpFinalSection?.notifySupervisor &&
          this.pdpFinalSection?.supervisorSignature &&
          this.pdpFinalSection?.userSignature &&
          !this.pdpFinalSection?.principalSignature:
          return true;
        case this.pdpPrincipal &&
          this.pdpMidYearSection?.notifySupervisor &&
          this.pdpMidYearSection?.supervisorSignature &&
          this.pdpMidYearSection?.userSignature &&
          !this.pdpMidYearSection?.principalSignature:
          return true;
        case this.pdpPrincipal &&
          this.pdpPlanSection?.notifySupervisor &&
          this.pdpPlanSection?.supervisorSignature &&
          this.pdpPlanSection?.userSignature &&
          !this.pdpPlanSection?.principalSignature:
          return true;
        default:
          return false;
      }
    },
    notifyState() {
      return (
        (this.isTS && this.tsNotifyState) || (this.isNTS && this.ntsNotifyState)
      );
    },
  },
  watch: {
    loggedInUser: {
      async handler() {
        if (this.loggedInUser) {
          if (!this.pdpId && this.ownedPdpLoaded && !this.hasActivePdp) {
            if (this.loggedInUser.isTeachingStaff) {
              await this.createPdp();
            }
          }
        }
      },
      immediate: true,
    },
    pdpId: {
      async handler() {
        if (this.pdpId && this.loggedInUser) {
          this.resetUserPdp();
          if (!this.ownedPdp) {
            await this.getOwnedPdp(this.loggedInUser.id);
            this.ownedPdpLoaded = true;
          }
          await this.loadUserPdp(parseInt(this.pdpId));
        } else if (!this.pdpId && this.loggedInUser && this.hasActivePdp) {
          await this.$router.push({
            name: PDP_ROUTES.myPdp.pdpId.name,
            params: { pdpId: `${this.ownedPdp[0]?.id}` },
          });
        } else if (!this.pdpId && this.loggedInUser && !this.ownedPdpLoaded) {
          this.ownedPdpLoaded = false;
          this.resetUserPdp();
          await this.getOwnedPdp(this.loggedInUser.id);
          this.ownedPdpLoaded = true;
          if (!this.pdpId && this.ownedPdpLoaded && !this.hasActivePdp) {
            if (this.loggedInUser.isTeachingStaff) {
              await this.createPdp();
            }
          }
        } else if (
          !this.pdpId &&
          this.loggedInUser &&
          this.ownedPdpLoaded &&
          !this.hasActivePdp &&
          this.isNTS
        ) {
          this.resetUserPdp();
        }
      },
      immediate: true,
    },
    refresh: {
      async handler() {
        if (this.refresh && this.loggedInUser) {
          this.stopInterval();
          await this.loadUserPdp(parseInt(this.pdpId));
          this.showExpiredPdpDialog = false;
          this.refresh = false;
        }
      },
    },
    isPdpUpdated: {
      handler() {
        if (this.isPdpUpdated && this.isPdpLoaded && !this.isLoading) {
          this.showExpiredPdpDialog = true;
          this.stopInterval();
        }
      },
    },
    isPdpLoaded: {
      handler() {
        if (this.isPdpLoaded && this.inFocus && !this.intervalId) {
          // console.log(
          //   new Date().toLocaleTimeString(),
          //   'MyPdp first loaded and in focus'
          // );
          this.updateIntervalState();
        }
      },
    },
    inFocus: {
      handler() {
        if (this.isPdpUpdated) {
          this.showExpiredPdpDialog = true;
          this.stopInterval();
        }
        if (this.isPdpLoaded && !this.inFocus && this.intervalId) {
          // console.log(
          //   new Date().toLocaleTimeString(),
          //   'MyPdp loaded and not in focus'
          // );
          this.updateIntervalState();
        } else if (this.isPdpLoaded && this.inFocus && !this.intervalId) {
          // console.log(new Date().toLocaleTimeString(), 'MyPdp back in focus');
          this.updateIntervalState();
        }
      },
      immediate: true,
    },
    notifyState: {
      handler() {
        if (this.notifyState) {
          // console.log(
          //   new Date().toLocaleTimeString(),
          //   'MyPdp loaded and in notify state'
          // );
        }
        this.updateIntervalState();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pdp', [
      'getOwnedPdp',
      'resetOwnedPdp',
      'getUserPdp',
      'createUserPdp',
      'resetUserPdp',
      'resetPdpData',
      'startInterval',
      'stopInterval',
    ]),
    handleRefresh() {
      this.refresh = true;
    },
    setNotesOpen(value) {
      this.notesOpen = value;
      // console.log(
      //   new Date().toLocaleTimeString(),
      //   this.notesOpen ? 'Personal notes open' : 'Personal notes closed'
      // );
      this.updateIntervalState();
    },
    async pdpSelected(id) {
      if (id && parseInt(this.pdpId) !== id) {
        await this.$router.push(`/my-pdp/${id}/`);
      }
    },
    async createPdp() {
      this.resetUserPdp();
      await this.createUserPdp();
      this.ownedPdpLoaded = false;
      await this.getOwnedPdp(this.loggedInUser?.id);
      this.ownedPdpLoaded = true;
    },
    async loadUserPdp(id) {
      if (id) {
        await this.getUserPdp(id);
        if (this.isPdpLoaded && !this.pdp) {
          await this.resetPdpData();
          await this.$router.push('/not-authorized');
        } else if (this.pdp?.owner?.id !== this.loggedInUser?.id) {
          await this.resetPdpData();
          await this.$router.push('/management');
        }
      }
    },
    async archive() {
      this.stopInterval();
      this.resetUserPdp();
      if (this.loggedInUser.isTeachingStaff) {
        await this.createUserPdp();
        this.ownedPdpLoaded = false;
        await this.getOwnedPdp(this.loggedInUser?.id);
        this.ownedPdpLoaded = true;
        if (this.hasActivePdp) {
          await this.$router.push({
            name: PDP_ROUTES.myPdp.pdpId.name,
            params: { pdpId: `${this.ownedPdp[0]?.id}` },
          });
        }
      } else if (this.loggedInUser.isNonTeachingStaff) {
        this.ownedPdpLoaded = false;
        await this.getOwnedPdp(this.loggedInUser?.id);
        this.ownedPdpLoaded = true;
        await this.$router.push({ name: PDP_ROUTES.myPdp.base.name });
      }
    },
    updateIntervalState() {
      const correctRoute =
        this.$route.name === PDP_ROUTES.myPdp.base.name ||
        this.$route.name === PDP_ROUTES.myPdp.pdpId.name ||
        this.$route.name === PDP_ROUTES.myPdp.selectedTab.name;
      if (
        this.inFocus &&
        !this.intervalId &&
        correctRoute &&
        this.pdp?.id &&
        this.pdp?.active &&
        this.notifyState &&
        !this.notesOpen
      ) {
        // console.log(
        //   new Date().toLocaleTimeString(),
        //   'MyPDP is active - starting interval check'
        // );
        this.startInterval({
          pdpId: this.pdp.id,
          updated_at: this.pdp.updated_at,
        });
      } else if (!this.inFocus && this.intervalId && correctRoute) {
        // console.log(
        //   new Date().toLocaleTimeString(),
        //   'MyPDP is inactive - stopping interval check'
        // );
        this.stopInterval();
      } else if (
        this.intervalId &&
        (!this.pdp?.id ||
          !this.pdp?.active ||
          !this.notifyState ||
          this.notesOpen)
      ) {
        // console.log(
        //   new Date().toLocaleTimeString(),
        //   'MyPDP is not loaded, or not in notify state, or archived - stopping interval check'
        // );
        this.stopInterval();
      }
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      this.stopInterval();
    }
    this.resetUserPdp();
    this.resetOwnedPdp();
    this.ownedPdpLoaded = false;
  },
};
</script>
