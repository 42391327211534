<template>
  <div>
    <v-row class="ma-0 pa-0 mr-sm-0">
      <v-col
        class="mx-sm-8 mt-4 mt-sm-8 px-5 px-sm-0 pa-md-0"
        sm="8"
      >
        <h1
          :class="{ 'custom-h1': isMobile }"
          class="display-1 font-weight-bold pr-10 pr-sm-0"
        >
          Welcome to your Performance and Development Plan (PDP)
        </h1>
        <p class="body-1 mt-4">
          Once your goals are drafted and you’re ready to share them, you can
          notify your PDP supervisor.
          <br />
          Head to the
          <a
            href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/performance-tools/digital-pdp"
          >
            Digital PDP page
          </a>
          on the intranet to find key resources to help you complete your PDP.
        </p>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0 mr-sm-0">
      <v-col class="ml-0 ml-sm-2 pa-0">
        <v-stepper
          v-model="currentStep"
          flat
          vertical
        >
          <template v-for="item in formSteps">
            <v-stepper-step
              :key="`stepper-step${item.step}`"
              :color="
                currentStep > item.step ? ADS_Colors.Success_Green : 'primary'
              "
              :complete="item.isDone"
              :editable="currentStep > item.step || item.isDone"
              :rules="[() => item.isValid !== false]"
              :step="item.step"
              class="px-5"
              complete-icon="$complete"
              edit-icon="$complete"
              error-icon="mdi-alert-circle-outline"
            >
              <span
                :class="{ 'custom-h1': isMobile }"
                class="font-weight-bold pl-1"
              >
                {{ item.title }}
              </span>
            </v-stepper-step>
            <v-stepper-content
              :key="`step-${item.step}-content`"
              :class="`${item.step === 3 && !isMobile ? 'ma-0 pa-0' : ''}`"
              :step="item.step"
            >
              <v-form
                :ref="'stepForm'"
                v-model="item.isValid"
                lazy-validation
              >
                <TSPdpSupervisor
                  v-if="item.step === 1"
                  :section="planSection"
                  class="py-0 px-5 px-sm-0 ml-sm-2 mt-4 mt-sm-0"
                  :save="saveSupervisor"
                  @saved="toggleSaveSupervisor"
                  @input="setSupervisor"
                />
                <TSPdpGoals
                  v-else-if="item.step === 2"
                  :section="planSection"
                  :userIsPrincipal="loggedInUser?.isPrincipal"
                  class="mx-0 py-0 pl-sm-0 pr-sm-1 ml-sm-2 mt-4 mt-sm-0"
                  @validated="validateGoals"
                />
                <AdsButton
                  v-if="item.step < 3"
                  :buttonText="
                    item.isLastStep ? 'Save and Finish' : 'Save and continue'
                  "
                  :disabled="disableSaveButton(item)"
                  :icon="item.isLastStep ? '' : 'mdi-arrow-right'"
                  class="ml-5 ml-sm-2 mb-5 mb-sm-0"
                  type="submit"
                  @click.prevent="nextStep(item)"
                />
              </v-form>
              <Wrapper
                v-if="item.step === 3"
                :isMobile="isMobile"
                cardClass="ml-11 mr-7 my-2 pa-5"
              >
                <MarkComplete
                  :noTitle="true"
                  :section="planSection"
                  :showBanner="true"
                  :showEditGoalsInfo="true"
                  class="py-0 px-4 px-sm-0 ml-sm-2 mt-4 mt-sm-0"
                  @onNotifyUser="handleNotifyUser"
                />
              </Wrapper>
            </v-stepper-content>
          </template>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TSPdpSupervisor from '@/components/TSPdpSupervisor.vue';
import AdsButton from '@nswdoe/doe-ui-core/src/AdsButton/AdsButton.vue';
import MarkComplete from '@/components/MarkComplete.vue';
import TSPdpGoals from '@/components/TSPdpGoals.vue';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { mapActions, mapGetters } from 'vuex';
import { clone } from 'ramda';
import Wrapper from './Wrapper.vue';
import { planIncompleteMessage } from '@/views/MyPdp/index.lib';
import { SECTION_TYPE, USER_TYPE } from '@/data/constants';
import { findPdpUserByType, findSectionByType } from '@/lib/pdp';
import { isTsGoalsValid } from '@/lib/dataValidation';

export default {
  name: 'TSStepper',
  components: {
    TSPdpGoals,
    MarkComplete,
    AdsButton,
    TSPdpSupervisor,
    Wrapper,
  },
  props: {
    showStepper: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      ADS_Colors,
      currentStep: 1,
      formSteps: [
        {
          step: 1,
          component: 'Supervisor',
          title: 'Add PDP supervisor',
          isValid: true,
          isDone: false,
          isLastStep: false,
        },
        {
          step: 2,
          component: 'Goals',
          title: 'Create professional goals',
          isValid: true,
          isDone: false,
          isLastStep: false,
        },
        {
          step: 3,
          component: 'Complete',
          title: 'Notify your PDP supervisor',
          isValid: true,
          isDone: false,
          isLastStep: true,
        },
      ],
      inputSupervisor: null,
      saveSupervisor: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp', 'isPdpLoaded']),
    planSection() {
      return findSectionByType(this.formPdp, SECTION_TYPE.plan);
    },
    planComplete() {
      return this.planSection?.complete;
    },
    isPlanSectionValid() {
      return isTsGoalsValid(this.planSection);
    },
    planIncompleteMessage() {
      if (this.loggedInUser?.isPrincipal) {
        return planIncompleteMessage(this.planSection);
      } else {
        return '';
      }
    },
    latestSupervisor() {
      return findPdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.supervisor
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    isPdpLoaded: {
      handler() {
        if (this.showStepper && this.formPdp && this.isPdpLoaded) {
          this.setCurrentStep();
        }
      },
      immediate: true,
    },
    isPlanSectionValid: {
      handler() {
        this.formSteps[1].isValid = this.isPlanSectionValid;
      },
    },
  },
  methods: {
    ...mapActions('pdp', ['savePdp']),
    setSupervisor(value) {
      this.inputSupervisor = value;
    },
    toggleSaveSupervisor() {
      this.savePdp(this.formPdp);
      this.saveSupervisor = false;
    },
    validateGoals() {
      this.formSteps[1].isValid = this.isPlanSectionValid;
    },
    setCurrentStep() {
      let currentStep = 1;
      if (
        this.latestSupervisor &&
        this.latestSupervisor.school_id &&
        this.formPdp.owner_school_id
      ) {
        this.formSteps[0].isDone = true;
        currentStep = 2;
      }
      if (this.planSection?.notifySupervisor) {
        this.formSteps[1].isDone = true;
        this.formSteps[2].isDone = true;
        currentStep = 3;
      }
      this.currentStep = currentStep;
    },
    disableSaveButton(step) {
      if (step.step === 1) {
        return !(
          this.inputSupervisor?.supervisor &&
          this.inputSupervisor?.supervisorSchool?.id &&
          this.inputSupervisor?.ownerSchool?.id
        );
      } else if (step.step === 2) {
        return !this.isPlanSectionValid;
      } else {
        return false;
      }
    },
    validateStep(step) {
      const validated = this.$refs.stepForm[step - 1].validate();
      this.formSteps[step - 1].isValid = validated;
      return validated;
    },
    async nextStep(item) {
      const validated = this.validateStep(item.step);
      if (validated) {
        switch (item.step) {
          case 1:
            this.saveSupervisor = true;
            break;
          case 2:
            await this.$store.dispatch('pdp/savePdp', clone(this.formPdp));
            break;
          case 3:
            this.$store.commit('formValidation/resetGoalsValidity');
            break;
        }
        item.isDone = true;
        this.currentStep = item.step + 1;
      }
    },
    async handleNotifyUser() {
      if (this.planSection) {
        this.planSection.notifySupervisor = true;
        this.planSection.notifySupervisorTime = Date.now();
        this.formPdp.sections = this.formPdp.sections.map((s) => {
          if (s.id === this.planSection?.id) {
            return this.planSection;
          }
          return s;
        });
        await this.$store.dispatch('pdp/savePdp', this.formPdp);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-h1 {
  font-size: 1.25rem !important;
  line-height: 1.46875rem !important;
}

:deep .v-stepper {
  .v-stepper__content {
    padding-right: 20px;
    margin-right: 0;
    margin-left: 36px;
  }

  &__step {
    padding-left: 22px;
    padding-right: 22px;

    .v-stepper__step__step {
      width: 32px;
      height: 32px;
    }
  }
}

@media screen and (max-width: 601px) {
  :deep .v-stepper {
    &__step {
      padding: 14px 0 12px;
      margin-top: 5px;

      &--active + .v-stepper__content {
        border-left: 0 !important;
        padding-top: 0 !important;
      }

      .v-stepper__step__step {
        width: 32px;
        height: 32px;
      }
    }

    .v-stepper__content {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;

      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .v-expansion-panel-content {
      .v-divider {
        max-width: none;
        margin-left: -20px;
        margin-right: -20px;
      }

      strong.title {
        font-size: 16px;
      }
    }
  }
  :deep :not(.v-stepper__step--active) + .v-stepper__content {
    margin-left: 36px;
    padding-top: 16px;
    padding-bottom: 8px;
  }
}
</style>
