import { render, staticRenderFns } from "./EventLog.vue?vue&type=template&id=522485bc&scoped=true"
import script from "./EventLog.vue?vue&type=script&lang=js"
export * from "./EventLog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522485bc",
  null
  
)

export default component.exports