import { formatDateTime, formatName } from '@/utils';
import { Pdp, Section, User } from '../../../lib/schema';
import dayjs from 'dayjs';

export const getComments = (
  section: Section,
  owner: User,
  supervisor: User | undefined = undefined,
  principal: User | undefined = undefined,
  manager: User | undefined = undefined,
  pdp: Pdp
) => {
  const comments: {
    role: string;
    name: string;
    action: string;
    time: number | string;
  }[] = [];
  if (section?.notifySupervisor && section?.notifySupervisorTime) {
    comments.push({
      role: 'User',
      name: formatName(owner),
      action: 'submitted',
      time: section.notifySupervisorTime,
    });
  }
  if (
    supervisor &&
    section?.supervisorSignature &&
    section?.supervisorSignTime
  ) {
    comments.push({
      role: 'PDP Supervisor',
      name: formatName(supervisor),
      action: 'signed',
      time: section?.supervisorSignTime,
    });
  }
  if (manager && section?.managerSignature && section?.managerSignTime) {
    comments.push({
      role: 'PDP Additional Supervisor',
      name: formatName(manager),
      action: 'signed',
      time: section?.managerSignTime,
    });
  }
  if (principal && section?.principalSignature && section?.principalSignTime) {
    comments.push({
      role: 'PDP Principal',
      name: formatName(principal),
      action: 'signed',
      time: section?.principalSignTime,
    });
  }
  if (section?.userSignature && section?.userSignTime) {
    comments.push({
      role: 'User',
      name: formatName(owner),
      action: 'signed',
      time: section?.userSignTime,
    });
  }
  if (!pdp?.active && pdp?.archived_at) {
    comments.push({
      role: 'User',
      name: formatName(owner),
      action: 'archived',
      time: pdp.archived_at,
    });
  }
  return comments
    .sort((a, b) => {
      return dayjs
        .utc(b.time)
        .tz('Australia/Sydney')
        .isAfter(dayjs.utc(a.time).tz('Australia/Sydney'))
        ? -1
        : 1;
    })
    .map((comment) => ({
      ...comment,
      time: formatDateTime(comment.time) ?? '',
    }));
};
