export const overview = [
  {
    title: 'PDP Guide',
    subtitle: 'Phases for the PDP process.',
    cards: [
      {
        number: '1',
        header: 'Plan',
        subheaders: [
          'The focus of this phase is to create an individual Performance & Development Plan (PDP).',
        ],
        keyActivities: {
          title: 'Key activities during this phase',
          list: [
            'Select your PDP supervisor',
            'Write your goals',
            'Share your goals with your PDP supervisor',
          ],
        },
        phaseCheckpoint: {
          title: 'Phase checkpoint',
          list: [
            'Have a 1:1 with your PDP supervisor to talk through your goals together',
          ],
        },
      },
      {
        number: '2',
        header: 'Implement',
        subheaders: [
          'The focus of this phase is to implement the planned strategies, support and professional learning that will lead to the achievement of your goals.',
        ],
        keyActivities: {
          title: 'Key activities during this phase',
          list: [
            'Do the work to implement your goals',
            'Review and edit your goals if needed',
            'Complete your self-assessment',
            'Continue to work on implementing your goals throughout this phase',
          ],
        },
        phaseCheckpoint: {
          title: 'Phase checkpoint',
          list: [
            'Have a 1:1 with your PDP supervisor to share your self-assessment, talk through your progess and next steps',
          ],
        },
      },
      {
        number: '3',
        header: 'Review',
        subheaders: [
          'The focus of this phase is to formally review your performance and development progress and achievement, providing tangable and constructive feedback to inform the next planning cycle.',
        ],
        keyActivities: {
          title: 'Key activities during this phase',
          list: [
            'Continue to work on implementing your goals until you do your end-of-year review',
            'Complete your end-of-year review',
          ],
        },
        phaseCheckpoint: {
          title: 'Phase checkpoint',
          list: [
            'Have a 1:1 with your PDP supervisor to share your end-of-year review and talk through next steps',
          ],
        },
      },
    ],
  },
];

export const NTSoverview = [
  {
    title: 'PDP Guide',
    subtitle: 'Phases for the PDP process.',
    cards: [
      {
        number: '1',
        header:
          'Plan - Commencing beginning of Term 1 and completed by mid-term 1.',
        subheaders: [
          'The focus of this phase is to create an individual Performance & Development Plan (PDP).',
          'Plan for the year ahead. A goal-setting conversation supports you and your PDP supervisor to set and clarify expectations, identify work and career goals and prepare your PDP.',
        ],
        keyActivities: {
          title: 'Key activities during this phase',
          list: [
            'Select your PDP supervisor',
            'Create your work goals',
            'Create your career goals (optional)',
            'Share your goals with your PDP supervisor',
          ],
        },
        phaseCheckpoint: {
          title: 'Phase checkpoint',
          list: [
            'Have a meeting with your PDP supervisor to talk through your goals together',
          ],
        },
      },
      {
        number: '2',
        header:
          'Implement - Commence end of term 2 and complete by beginning of term 3.',
        subheaders: [
          'The focus of this phase is to implement the actions and development needed to achieve goals identified in your PDP. A mid-year self-assessment conversation ensures goals are on track and together with your PDP supervisor you consider whether changes to your PDP are needed.',
        ],
        keyActivities: {
          title: 'Key activities during this phase',
          list: [
            'Implement the strategies on your PDP',
            'Reflect on your progress against your goals',
            'Review and edit your goals if needed',
            'Complete your mid-year self-assessment',
            'Share your mid-year self-assessment with your PDP supervisor',
            'Continue to work on implementing your goals throughout this phase',
          ],
        },
        phaseCheckpoint: {
          title: 'Phase checkpoint',
          list: [
            'Have a meeting with your PDP supervisor to share your mid-year self-assessment, talk through your progress and next steps.',
          ],
        },
      },
      {
        number: '3',
        header: 'Review - Complete by end of term 4.',
        subheaders: [
          'The focus of this phase is to review work performance and development against the goals identified in your PDP. An annual review conversation focuses on the achievements, challenges, learning and opportunities over the year.',
        ],
        keyActivities: {
          title: 'Key activities during this phase',
          list: [
            'Continue to work on implementing your goals',
            'Reflect on your progress throughout the PDP cycle',
            'Complete your annual review',
            'Share your annual review with your PDP supervisor',
          ],
        },
        phaseCheckpoint: {
          title: 'Phase checkpoint',
          list: [
            'Have a meeting with your PDP supervisor to share your annual review, and talk through next steps.',
          ],
        },
      },
    ],
  },
];
