<template>
  <v-col class="pa-0">
    <Dialog
      :openDialog="dialog"
      title="Work goals guide"
      icon="mdi-bookshelf"
      displayCloseBtn
      @close="dialog = false"
    >
      <template #text>
        <Alert
          elevation="0"
          alertClass="info-background-banner subtitle-1"
          class="flex-grow-1 my-4 px-md-6"
          inPage
          showAlert
          text="Your privacy is important"
          type="info"
        >
          <template #optional>
            <p class="body-1 ads-grey-01 mb-0">
              Only your selected PDP supervisor, additional supervisor and
              principal can see your PDP. Nobody else can search or access your
              PDP.
            </p>
            <p class="body-1 ads-grey-01 mb-0">
              A
              <a
                href="https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/Digtial_PDP_SDD_pack-data_security_fact_sheet.pdf"
                target="_blank"
              >
                fact sheet
              </a>
              is available for more information about the security, privacy and
              confidentiality of your data in the Digital PDP.
            </p>
          </template>
        </Alert>
        <v-row
          class="px-md-6"
          no-gutters
        >
          <v-col>
            <h1>
              <v-icon
                class="mr-3"
                color="success"
              >
                mdi-lightbulb-outline
              </v-icon>
              <span class="text-h6 font-weight-bold grey-01--text">
                Use the SMART structure
              </span>
            </h1>
            <p class="mt-md-3 body-1">
              Use the SMART structure to write more effective goals:
            </p>
            <ul
              class="pa-0 body-1 mb-4"
              style="list-style-type: none"
            >
              <li>
                <b>S</b>
                — Specific
              </li>
              <li>
                <b>M</b>
                — Measurable
              </li>
              <li>
                <b>A</b>
                — Achievable
              </li>
              <li>
                <b>R</b>
                — Relevant
              </li>
              <li>
                <b>T</b>
                — Time-bound
              </li>
            </ul>
          </v-col>
          <v-col
            class="d-flex justify-center align-start image-col"
            cols="auto"
          >
            <v-img
              class="fill-height"
              src="../../public/gender-neutral-clipboard.svg"
              contain
            ></v-img>
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-2"></v-divider>
        <v-row
          class="px-md-6"
          no-gutters
        >
          <v-col>
            <h1>
              <v-icon
                class="mr-3"
                color="success"
              >
                mdi-link
              </v-icon>
              <span class="text-h6 font-weight-bold grey-01--text">
                Helpful Links
              </span>
            </h1>
            <p class="mt-md-3 body-1">
              Here are some helpful links with more information
            </p>
            <v-list-item
              v-for="item in helpfulLinks"
              :key="item.description"
              class="helpful-links"
            >
              <v-list-item-content class="py-md-0">
                <v-list-item-title>
                  <a
                    class="font-weight-bold primary-blue--text"
                    :href="item.link"
                    target="_blank"
                  >
                    {{ item.description }}
                    <v-icon class="ml-2">mdi-exit-to-app</v-icon>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <p class="mt-3 mb-1 body-1 ads-grey-01">
              It may be useful to refer to your school's Strategic Improvement
              Plan (SIP) when creating your goals.
            </p>
          </v-col>
          <v-col
            class="d-flex justify-center align-start image-col"
            cols="auto"
          >
            <v-img
              class="fill-height"
              src="../../public/puzzle.svg"
              contain
            ></v-img>
          </v-col>
        </v-row>
      </template>
    </Dialog>
    <v-card
      class="px-6 pt-5 pb-md-0 no-break-inside"
      color="primary-teal"
      elevation="0"
    >
      <v-row
        class="d-flex"
        no-gutters
      >
        <v-col
          class="flex-grow-1 pb-7 pb-md-8"
          cols="9"
        >
          <v-card-title class="pa-0 pb-3 font-weight-bold primary-blue--text">
            How to write work goals
          </v-card-title>
          <v-card-text class="px-0 grey-01--text">
            View our guide, tips and helpful links for writing your work goals
          </v-card-text>
          <v-card-actions class="py-0 px-0">
            <AdsButton
              buttonText="View guide"
              icon="mdi-tooltip-question-outline"
              secondary
              @click="dialog = !dialog"
            />
          </v-card-actions>
        </v-col>
        <v-col
          v-if="!isMobile"
          class="d-flex justify-center align-start image-col"
          cols="3"
        >
          <v-img
            class="fill-height image-content"
            src="../../public/gender-neutral-clipboard.svg"
            max-width="116px"
            max-height="115px"
          ></v-img>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import { AdsButton, Dialog, Alert } from '@nswdoe/doe-ui-core';

export default {
  name: 'NTSGuidesCard',
  components: { AdsButton, Dialog, Alert },
  data() {
    return {
      dialog: false,
      // Please don't remove the commented out links below for now as they'll be shifted to another view according to PDP-791
      helpfulLinks: [
        {
          description: 'Goal setting',
          link: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/goal-setting-for-non-teaching-staff',
        },
        // {
        //   description: 'Meaningful conversations to help us be at our best',
        //   link: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/meaningful-conversations',
        // },
        {
          description: 'Planning your PDP playbook',
          link: 'https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/non-teaching-staff-performance-and-development/NTS-Planning_your-PDP-Playbook.pdf',
        },
        // {
        //   description: 'SAS staff - Statements of duties',
        //   link: 'https://education.nsw.gov.au/industrial-relations/a-z-of-industrial-relations-topics/SASS-statements-of-duties',
        // },
        // {
        //   description: 'School-based role description library',
        //   link: 'https://education.nsw.gov.au/inside-the-department/human-resources/recruitment/public-service-employees/pse-prepare/school-based-role-description-library',
        // },
        // {
        //   description: 'Strategic Plan',
        //   link: 'https://education.nsw.gov.au/about-us/strategies-and-reports/strategic-plan.html',
        // },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .info-background-banner {
  background-color: $ads-primary-teal !important;
  border: none !important;
  // colour for info banner text
  &.theme--light.v-sheet {
    color: $ads-grey-01;
  }
}
.fill-height {
  height: 100%;
}
.image-col {
  position: relative; /* Enable positioning of absolutely positioned image */
}
.image-content {
  width: auto; /* Adjust width as necessary */
  position: absolute;
}
:deep .v-card {
  &__title {
    font-size: 24px;
    line-height: 30px;
    word-break: break-word;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
  }
  .v-image {
    bottom: -14px;
  }
  border-radius: 12px;
}

:deep .v-alert__border {
  border-color: $ads-info-blue !important;
}

:deep .v-alert__icon {
  color: $ads-info-blue !important;
}

.helpful-links.v-list-item {
  min-height: 32px !important;
  padding-left: 0;
}

@media print {
  button {
    pointer-events: none;
  }
}
</style>
