import { ConfigState, RootState } from '../types';
import { ActionContext } from 'vuex';
import api from '@/api';

export default {
  namespaced: true,
  state: {
    pdpRefreshRate: '30000',
  } as ConfigState,
  mutations: {
    SET_CONFIG(state: ConfigState, newPdpRefreshRate: string) {
      state.pdpRefreshRate = newPdpRefreshRate;
    },
  },
  actions: {
    async setConfig({ commit }: ActionContext<RootState, RootState>) {
      commit('SET_IS_LOADING', true, { root: true });
      const config = await api.refreshRate.getConfig();
      if (config) {
        commit('SET_CONFIG', config.pdpRefreshRate);
      }
      commit('SET_IS_LOADING', false, { root: true });
    },
  },
  getters: {
    config: (state: ConfigState) => {
      return { ...state };
    },
  },
};
