<template>
  <v-container class="pa-0">
    <h1 class="mb-5 title grey-01--text">Personal notes</h1>
    <v-card
      class="grey-03-border"
      outlined
    >
      <v-card-text class="pa-10">
        <div
          v-dompurify-html="displayedComments"
          class="mb-0 body-1 black--text"
        ></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'PersonalNotesCard',
  props: {
    comments: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    displayedComments() {
      return this.comments ?? '';
    },
  },
};
</script>

<style scoped lang="scss">
.grey-03-border {
  border-color: $ads-grey-03;
}
:deep p {
  margin-bottom: 0;
}
</style>
